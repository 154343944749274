// Included by sanjana SOP-34 to create ticket list page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import AllocateTicketPage from './AllocateTicket';
import ViewTicketPage from './ViewTicket';
import EditTicketPage from './EditTicket';

//SOP-76 feedback points L2 (4) Included by PCS [combining ticket & ticketing services]
import AddTicketingServicesPage from '../TicketingServices/AddTicketingServices';
import ViewTicketingServicePage from '../TicketingServices/ViewTicketingServics';
import EditTicketingServicePage from '../TicketingServices/EditTicketingServices';
import { reverse } from 'dns';
//EOF SOP-76 feedback points L2 (4) Included by PCS [combining ticket & ticketing services]

const TicketListPage = () =>{

    // useSate
    let  [PendingTicketsorder, setPendingTicketsOrder] = React.useState<"desc" | "asc">("desc");
    let  [PendingTicketsorderBy, setPendingTicketsOrderBy] = React.useState("");
    let  [PendingTicketstableData, setPendingTicketsTableData] = React.useState([]);
    const [PendingTicketspage, setPendingTicketsPage] = React.useState<number>(0);
    const [PendingTicketscount, setPendingTicketsCount] = useState<number>(0);
    const [PendingTicketsrowsPerPage, setPendingTicketssetRowsPerPage] = React.useState<number>(10);
    let  [AllocatedTicketsorder, setAllocatedTicketsOrder] = React.useState<"desc" | "asc">("desc");
    let  [AllocatedTicketsorderBy, setAllocatedTicketsOrderBy] = React.useState("");
    let  [AllocatedTicketstableData, setAllocatedTicketsTableData] = React.useState([]);
    const [AllocatedTicketspage, setAllocatedTicketsPage] = React.useState<number>(0);
    const [AllocatedTicketscount, setAllocatedTicketsCount] = useState<number>(0);
    const [AllocatedTicketsrowsPerPage, setAllocatedTicketssetRowsPerPage] = React.useState<number>(10);
    const [AllocateOpen, setAllocateOpen] = useState(false);
    //const [ViewOpen, setViewOpen] = useState(false); //SOP-76 feedback points L2 (4) commented by PCS[removed allocated list grid]
    //const [EditOpen, setEditOpen] = useState(false); //SOP-76 feedback points L2 (4)  commented by PCS[removed allocated list grid]
    const [TicketId, setTicketId] = useState()


     //  To open add dialouge box
     const handleAddOpen = (id:any) => {
        setAllocateOpen(true);
        setTicketId(id)
                                       };

    // To close add dialouge box
    const handleAddClose = () => {
    setAllocateOpen(false);
                              };

   //SOP-76 feedback points L2 (4)Included by PCS [combining ticket & ticketing services] starts here ...
    
        // useSate
        let  [PendingTicketingServicesorder, setPendingTicketingServicesOrder] = React.useState<"desc" | "asc">("desc");
        let  [PendingTicketingServicesorderBy, setPendingTicketingServicesOrderBy] = React.useState("");
        let  [PendingTicketingServicestableData, setPendingTicketingServicesTableData] = React.useState([]);
        const [PendingTicketingServicespage, setPendingTicketingServicesPage] = React.useState<number>(0);
        const [PendingTicketingServicescount, setPendingTicketingServicesCount] = useState<number>(0);
        const [PendingTicketingServicesrowsPerPage, setPendingTicketingServicessetRowsPerPage] = React.useState<number>(10);
        let  [AllocatedTicketingServicesorder, setAllocatedTicketingServicesOrder] = React.useState<"desc" | "asc">("desc");
        let  [AllocatedTicketingServicesorderBy, setAllocatedTicketingServicesOrderBy] = React.useState("");
        let  [AllocatedTicketingServicestableData, setAllocatedTicketingServicesTableData] = React.useState([]);
        const [AllocatedTicketingServicespage, setAllocatedTicketingServicesPage] = React.useState<number>(0);
        const [AllocatedTicketingServicescount, setAllocatedTicketingServicesCount] = useState<number>(0);
        const [AllocatedTicketingServicesrowsPerPage, setAllocatedTicketingServicessetRowsPerPage] = React.useState<number>(10);
        const [AllocateOpenTicketingServices, setAllocateOpenTicketingServices] = useState(false);
        const [ViewOpen, setViewOpen] = useState(false);
        const [EditOpen, setEditOpen] = useState(false);
         const [TicketIdTicketingServices, setTicketIdTicketingServices] = useState()

        //  To open add dialouge box [ticketing services]
                const handleAddOpenTicketingService = (id:any) => {
                    setAllocateOpenTicketingServices(true);
                    setTicketIdTicketingServices(id)
                };

        // To close add dialouge box [ticketing services]
                const handleAddCloseTicketingService = () => {
                    setAllocateOpenTicketingServices(false);
                };

        //  To open View dialouge box [ticketing services]
                const handleViewOpen = (id:any) => {
                    setViewOpen(true);
                    setTicketIdTicketingServices(id)
                };

        // To close View dialouge box [ticketing services]
                const handleViewClose = () => {
                    setViewOpen(false);
                };

        //  To open Edit dialouge box [ticketing services]
                const handleEditOpen = (id:any) => {
                    setEditOpen(true);
                    setTicketIdTicketingServices(id)
                };

        // To close Edit dialouge box
                const handleEditClose = () => {
                setEditOpen(false);
                };


    //EOF SOP-76 feedback points L2 (4) Included By PCS [combining ticket & ticketing services] ends here



    //SOP-76 feedback points L2 (4) commented by PCS [removed allocated list grid]
            //  To open View dialouge box
            // const handleViewOpen = (id:any) => {
            //     setViewOpen(true);
            //     setTicketId(id)
            // };

            // // To close View dialouge box
            // const handleViewClose = () => {
            // setViewOpen(false);
            // };
            //  To open Edit dialouge box
            // const handleEditOpen = (id:any) => {
            //     setEditOpen(true);
            //     setTicketId(id)
            // };

            // // To close Edit dialouge box
            // const handleEditClose = () => {
            // setEditOpen(false);
            // };
    //EOF SOP-76 feedback points L2 (4) commented by PCS [removed allocated list grid]

        
    // Head cell of My Pending Allocation Request
    const PendingTicketsHeadCell = [
        {
            id: "ticket_number",
            numeric: false,
            disablePadding: true,
            label: "Ticket #",
            isSort: true,
            isBold: true,
        },
        {
            id: "ticket_date",
            numeric: false,
            disablePadding: false,
            label: "Ticket Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "ticket_issuedescription",
            numeric: false,
            disablePadding: false,
            label: "Issue Description",
            isSort: true,
            isBold: true,
            },
        {
        id: "ticketraisedbyname",
        numeric: false,
        disablePadding: false,
        label: "Employee Name",
        isSort: true,
        isBold: true,
        },
        {
   
        id: "Allocate",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
        },
       
        ];

    // SOP-76 feedback points L2 (4)  commenetd by PCS  [removing Alllcoated Tickets list grid]    
            // const AllocatedTicketsHeadCell = 
            //[
            //     {
            //         id: "ticket_number",
            //         numeric: false,
            //         disablePadding: true,
            //         label: "Ticket #",
            //         isSort: true,
            //         isBold: true,
            //     },
            //     {
            //         id: "ticket_date",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Ticket Date",
            //         isSort: true,
            //         isBold: true,
            //         dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            //     },
            //     {
            //         id: "ticket_issuedescription",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Issue Description",
            //         isSort: true,
            //         isBold: true,
            //         },
            //         {
            //         id: "ticket_allocatetoname",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Allocated Name",
            //         isSort: true,
            //         isBold: true,
            //         },
            //         {
            //             id: "ticket_allocatedate",
            //             numeric: false,
            //             disablePadding: false,
            //             label: "Ticket Allocated Date",
            //             isSort: true,
            //             isBold: true,
            //             dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            //         },
            //         {
            //         id: "ticket_allocateremarks",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Allocation Remarks",
            //         isSort: true,
            //         isBold: true,
            //         },
            //         {
            //         id: "action",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Actions",
            //         isSort: false,
            //         },
            //     ];
    //EOF SOP-76 feedback points L2 (4)  commenetd By PCS [removing Alllcoated Ticket list grid]   
 
 
    // SOP-76 feedback points L2 (4)  [combining ticketing & ticketing services - Including ticketing services grids]    
         // Head cells for Open tickets and completed tickets
       const PendingTicketingServicesHeadCell = [
            {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
               
            },
            {
                id: "ticket_date",
                numeric: false,
                disablePadding: false,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
               
            },
            {
                id: "ticket_issuedescription",
                numeric: false,
                disablePadding: false,
                label: "Issue Description",
                isSort: true,
                isBold: true,
               
                },
            {
            id: "ticketraisedbyname",
            numeric: false,
            disablePadding: false,
            label: "Employee Name",
            isSort: true,
            isBold: true,
           
            },
            {
                id: "ticket_allocatedate",
                numeric: false,
                disablePadding: false,
                label: "Ticket Allocated Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
               
            },
            {
            id: "ticket_allocatetoname",
            numeric: false,
            disablePadding: false,
            label: "Allocated to",
            isSort: true,
            isBold: true,
            
            },
            {
            id: "ticket_allocateremarks",
            numeric: false,
            disablePadding: false,
            label: "Allocation Remarks",
            isSort: true,
            isBold: true,
            
            },
            //SOP-99 Included by PCS [Included Columns]
            {
                id: "service_completedate",
                numeric: false,
                disablePadding: false,
                label: "Service Completed Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
                id: "service_completionstatus_name",
                numeric: false,
                disablePadding: false,
                label: "Service Completion Status",
                isSort: true,
                isBold: true,
            },
            {
                id: "service_remarks",
                numeric: false,
                disablePadding: false,
                label: "Services Remarks",
                isSort: true,
                isBold: true,
            },
            //EOF SOP-99 Included By PCS [Included Columns]
            {
            id: "Open",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
            }
        ];

        const AllocatedTicketingServicesHeadCell = [
                {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
                
                },
                {
                id: "ticket_date",
                numeric: false,
                disablePadding: false,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
                },
               {
                id: "ticket_issuedescription",
                numeric: false,
                disablePadding: false,
                label: "Issue Description",
                isSort: true,
                isBold: true,
                },
                {
                id: "ticket_allocatetoname",
                numeric: false,
                disablePadding: false,
                label: "Allocated Name",
                isSort: true,
                isBold: true,
                },
                {
                id: "ticket_allocatedate",
                numeric: false,
                disablePadding: false,
                label: "Ticket Allocated Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
                },
                {
                id: "ticket_allocateremarks",
                numeric: false,
                disablePadding: false,
                label: "Allocation Remarks",
                isSort: true,
                isBold: true,
                },
                {
                id: "service_completedate",
                numeric: false,
                disablePadding: false,
                label: "Service Completed Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
                },
                {
                id: "service_completionstatus_name",
                numeric: false,
                disablePadding: false,
                label: "Service Completion Status",
                isSort: true,
                isBold: true,
                },
                {
                id: "service_remarks",
                numeric: false,
                disablePadding: false,
                label: "Services Remarks",
                isSort: true,
                isBold: true,
                },
                {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: "Actions",
                isSort: false,
                },
            
            ];
    // EOF SOP-76 feedback points L2 (4)  [combining ticketing & ticketing services ]   

     //SOP-80 Included By PCS [including search functionality for Completed Tickets Grid]
     const [cells, setCells] = useState<any>(AllocatedTicketingServicesHeadCell);
    //EOF SOP-80 Included By PCS [including search functionality for Completed Tickets Grid]
    
     //SOP-99 Included By PCS [including search functionality for open Tickets Grid]
     const [cellsOpenTicket, setCellsOpenTicket] = useState<any>(PendingTicketingServicesHeadCell);
    //EOF SOP-99 Included By PCS [including search functionality for open Tickets Grid]

    //  Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId
        const UserId = GlobalData.GlobalUserId;
        let EmployeeId = GlobalData.GlobalEmployeeId //SOP-76 feedback points L2 (4)  [combining ticketing & ticketing services ]


    // To get pending ticket list
    const getPendingTicketsList = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_pending_Ticket_list`, {
                    SortColumnName: PendingTicketsorderBy,
                    SortColumnDirection: PendingTicketsorder,
                    StartIndex: PendingTicketspage + 1,
                    PageSize: PendingTicketsrowsPerPage,
                    CompanyId:CompanyId,
                })
                .then((res: any) => 
                {
                    // console.log("res Pending list",res.data.data)
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.ticketid,
                    ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                    ticket_allocatedate: item.request_approve_date ? dayjs(item.request_approve_date).format("DD-MM-YYYY") : "",
                }
                }) 
                //console.log("formattedTableData",formattedTableData)
                
                setPendingTicketsTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setPendingTicketsCount(0);
                }
                else
                {
                    setPendingTicketsCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setPendingTicketsTableData([]);
                }
                Failed(error.response.message);
                });
    };

    //SOP-76 feedback points L2 (4) included By PCS [removing allocated list grid]
    // To get Allocated ticket list
    const getAllocatedTicketsList = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_allocated_Ticket_list`, {
                    SortColumnName: AllocatedTicketsorderBy,
                    SortColumnDirection: AllocatedTicketsorder,
                    StartIndex: AllocatedTicketspage + 1,
                    PageSize: AllocatedTicketsrowsPerPage,
                    CompanyId:CompanyId,
                })
                .then((res: any) => 
                {
                    //console.log("res A",res)
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.ticketid,
                    ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                    ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
                }
                }) 
                //console.log("formattedTableData",formattedTableData)
                
                setAllocatedTicketsTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setAllocatedTicketsCount(0);
                }
                else
                {
                    setAllocatedTicketsCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setAllocatedTicketsTableData([]);
                }
                Failed(error.response.message);
                });
    };
    //EOF SOP-76 feedback points L2 (4) included By PCS [removing allocated list grid]

    // Sort Handler 
    const handlePendingTicketsIncidentRequestSort = (event: any, property: any) =>
                {
                const isAsc = PendingTicketsorderBy === property && PendingTicketsorder === "asc";
                PendingTicketsorder = isAsc ? "desc" : "asc";
                setPendingTicketsOrder(PendingTicketsorder);
                PendingTicketsorderBy = property;
                setPendingTicketsOrderBy(PendingTicketsorderBy);
    };        
        
    // Action function of table icons
    const PendingTicketsactionFunction = (id: string, actionText: string) => 
            {
                    if (actionText === "Allocate") {
                    handleAddOpen(id)
                };
                // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
    };

    // Pagination Handler of table
    const PendingTicketshandleChangePage = (event: unknown, newPage: number) => {
            setPendingTicketsPage(newPage);
        };
    
    // Rows Per page Handler
    const PendingTicketshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setPendingTicketssetRowsPerPage(+event.target.value);
                setPendingTicketsPage(0);
        };

    //SOP-76 feedback points L2 (4) commented By PCS  [removing allocated list grid related functions]
        // Sort Handler
        // const handleAllocatedTicketsIncidentRequestSort = (event: any, property: any) =>
        //     {
        //     const isAsc = AllocatedTicketsorderBy === property && AllocatedTicketsorder === "asc";
        //     AllocatedTicketsorder = isAsc ? "desc" : "asc";
        //     setAllocatedTicketsOrder(AllocatedTicketsorder);
        //     AllocatedTicketsorderBy = property;
        //     setAllocatedTicketsOrderBy(AllocatedTicketsorderBy);
        //     };
        
        
        // Action function of table icons
        // const AllocatedTicketsactionFunction = (id: string, actionText: string) => 
        // {
        //     if (actionText === "view") {
        //         handleViewOpen(id)
        //     };
        // };
    
    
        // Pagination Handler of table
        // const AllocatedTicketshandleChangePage = (event: unknown, newPage: number) => {
        //     setAllocatedTicketsPage(newPage);
        // };
        
        // Rows Per page Handler
        // const AllocatedTicketshandleChangeRowsPerPage = (
        // event: React.ChangeEvent<HTMLInputElement>
        //     ) => {
        //         setAllocatedTicketssetRowsPerPage(+event.target.value);
        //         setAllocatedTicketsPage(0);
        // };
    //EOF SOP-76 L2(4) [removing allocated list related functions]

    //SOP-76 feedback points L2 (4) [combining ticketing & ticketing services]

        // To get pending ticket list
    const getPendingTicketingServicesList = () => 
    {    
    //SOP-99 Included by PCS [search functionality for open tickets grid]
                 // Initializing Variable 
                 let TicketNumber = null;
                 let TicketDate = null; 
                 let IssueDescription = null;
                 let EmployeeName = null;
                 let TicketAllocatedDate = null; 
                 let AllocateName = null;
                 let AllocateRemarks = null;
                 let ServiceCompletedDate = null; 
                 let ServiceCompletionStatus = null;
                 let ServiceRemarks = null;

                 for (const item of cellsOpenTicket)
                    {
                       if (item.id === "ticket_number") {
                         TicketNumber = item.value;
                      }

                      if (item.id === "ticket_date") { 
                        TicketDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
                        TicketDate =TicketDate? TicketDate.add(5, 'hour').add(30, 'minute'):null;
                        }
     
                      if (item.id === "ticket_issuedescription") {
                         IssueDescription = item.value;
                       }
     
                       if (item.id === "ticketraisedbyname") {
                        EmployeeName = item.value;
                      }
    
                      if (item.id === "ticket_allocatedate") { 
                        TicketAllocatedDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
                        TicketAllocatedDate =TicketAllocatedDate? TicketAllocatedDate.add(5, 'hour').add(30, 'minute'):null;
                       }

                       if (item.id === "ticket_allocatetoname") {
                         AllocateName = item.value;
                       }
     
                       if (item.id === "ticket_allocateremarks") {
                         AllocateRemarks = item.value;
                       }
     
                       if (item.id === "service_completedate") { 
                        ServiceCompletedDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
                        ServiceCompletedDate =ServiceCompletedDate? ServiceCompletedDate.add(5, 'hour').add(30, 'minute'):null;
                       }

                       if (item.id === "service_completionstatus_name") {
                        ServiceCompletionStatus = item.value;
                      }
     
                      if (item.id === "service_remarks") {
                        ServiceRemarks = item.value;
                      }                                       
                    }   
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_pending_ticket_service_list`, {
            //SOP-99 Included By PCS [for search functionality]
                SearchTicketNumber:TicketNumber,
                SearchTicketDate:TicketDate,
                SearchIssueDescription:IssueDescription,
                SearchEmployeeName:EmployeeName,
                SearchAllocatedDate:TicketAllocatedDate,
                SearchAllocateName:AllocateName,
                SearchAllocateRemarks:AllocateRemarks,
                SearchServiceCompletedDate:ServiceCompletedDate,
                SearchServiceCompletionStatus:ServiceCompletionStatus,
                SearchServiceRemarks:ServiceRemarks,
            //EOF SOP-99 Included By PCS [for search functionality]
                            
                SortColumnName: PendingTicketingServicesorderBy,
                SortColumnDirection: PendingTicketingServicesorder,
                StartIndex: PendingTicketingServicespage + 1,
                PageSize: PendingTicketingServicesrowsPerPage,
                CompanyId:CompanyId,
                // Modified by sanjana SOP-40
                // UserId:UserId
                UserId:EmployeeId,
                // End of SOP-40
            })
            .then((res: any) => 
            {
               
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
            id:item.ticketid,
            ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
            ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
            //SOP-99 Included By PCS
            service_completedate: item.service_completedate ? dayjs(item.service_completedate).format("DD-MM-YYYY") : "",
            //EOF SOP-99  Included By PCS                     
            }
            }) 
      
        setPendingTicketingServicesTableData(formattedTableData);
        if(res.data.data[0]===undefined)
        {
            setPendingTicketingServicesCount(0);
        }
        else
        {
            setPendingTicketingServicesCount(res.data.data[0].total_count);
        }
        })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setPendingTicketingServicesTableData([]);
            }
            Failed(error.response.message);
            });
        };

    // To get Allocated ticket list
        const getAllocatedTicketingServicesList = () => 
            { 
                
//SOP-80 Included by PCS [search functionality for completed tickets grid]
            // Initializing Variable 
            let TicketNumber = null;
            let IssueDescription = null;
            let ServiceCompletionStatus = null;
            let AllocateName = null;
            let AllocateRemarks = null;
            let ServiceRemarks = null;
            let TicketDate = null; 
            let TicketAllocatedDate = null; 
            let ServiceCompletedDate = null; 
        
        for (const item of cells)
        {
            if (item.id === "ticket_number") {
            TicketNumber = item.value;
            }

            if (item.id === "ticket_issuedescription") {
            IssueDescription = item.value;
            }

            if (item.id === "service_completionstatus_name") {
            ServiceCompletionStatus = item.value;
            }

            if (item.id === "ticket_allocatetoname") {
            AllocateName = item.value;
            }

            if (item.id === "ticket_allocateremarks") {
            AllocateRemarks = item.value;
            }

            if (item.id === "service_remarks") {
            ServiceRemarks = item.value;
            }

            if (item.id === "ticket_date") { 
            TicketDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
            TicketDate =TicketDate? TicketDate.add(5, 'hour').add(30, 'minute'):null;
            }

            if (item.id === "ticket_allocatedate") { 
            TicketAllocatedDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
            TicketAllocatedDate =TicketAllocatedDate? TicketAllocatedDate.add(5, 'hour').add(30, 'minute'):null;
            }

            if (item.id === "service_completedate") { 
            ServiceCompletedDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
            ServiceCompletedDate =ServiceCompletedDate? ServiceCompletedDate.add(5, 'hour').add(30, 'minute'):null;
            }
        
        }
//EOF SOP-80 Included by PCS [search functionality for completed tickets grid]

                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_allocated_ticket_service_list`, {
                    //SOP-80 Included By PCS [search function]
                    SearchTicketNumber: TicketNumber,
                    SearchIssueDescription:IssueDescription,
                    SearchServiceCompletionStatus:ServiceCompletionStatus,
                    SearchAllocateName:AllocateName,
                    SearchAllocateRemarks:AllocateRemarks,
                    SearchServiceRemarks: ServiceRemarks,
                    SearchTicketDate: TicketDate,
                    SearchAllocatedDate:TicketAllocatedDate,
                    SearchServiceCompletedDate:ServiceCompletedDate, 
                    //EOF SOP-80 Included By PCS [search function]

                    SortColumnName: AllocatedTicketingServicesorderBy,
                    SortColumnDirection: AllocatedTicketingServicesorder,
                    StartIndex: AllocatedTicketingServicespage + 1,
                    PageSize: AllocatedTicketingServicesrowsPerPage,
                    CompanyId:CompanyId,
                    // Modified by sanjana SOP-40
                    // UserId:UserId
                    UserId:EmployeeId,
                    // End of SOP-40
                })

            
                .then((res: any) => 
                {
                    // console.log(" A",res)
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.ticketid,
                    ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                    ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
                    service_completedate: item.service_completedate ? dayjs(item.service_completedate).format("DD-MM-YYYY") : "",
                }
                }) 
                //console.log("formattedTableData",formattedTableData)
                
                setAllocatedTicketingServicesTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setAllocatedTicketingServicesCount(0);
                }
                else
                {
                    setAllocatedTicketingServicesCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setAllocatedTicketingServicesTableData([]);
                }
                Failed(error.response.message);
                });
            };

    // Sort Handler
        const handlePendingTicketingServicesIncidentRequestSort = (event: any, property: any) =>
            {
            const isAsc = PendingTicketingServicesorderBy === property && PendingTicketingServicesorder === "asc";
            PendingTicketingServicesorder = isAsc ? "desc" : "asc";
            setPendingTicketingServicesOrder(PendingTicketingServicesorder);
            PendingTicketingServicesorderBy = property;
            setPendingTicketingServicesOrderBy(PendingTicketingServicesorderBy);
            };

    // Action function of table icons
        const PendingTicketingServicesactionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "Open") {
                handleAddOpenTicketingService(id)
                
            };
        
        };

    // Pagination Handler of table
        const PendingTicketingServiceshandleChangePage = (event: unknown, newPage: number) => {
            setPendingTicketingServicesPage(newPage);
        };

    //SOP-99 Included by PCS [search function in Open Tickets Grid]
        // Search Handler of table
        const handleSearchOpenTickets = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cellsOpenTicket];
            const index = cellsOpenTicket.findIndex((c: any) => c.id === e.target.name);
            if (index !== -1) {
            // console.log("e.target.name",e.target.name)
            // console.log('e.target.value',e.target.value)
            // Update the value in the search array based on input
                    search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
            }
            setCellsOpenTicket(search);
             PendingTicketingServiceshandleChangePage(setPendingTicketingServicesPage,0);
            getPendingTicketingServicesList();
           };
    //EOF SOP-99 Included by PCS [search function in Completed Tickets Grid]

        // Rows Per page Handler
        const PendingTicketingServiceshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setPendingTicketingServicessetRowsPerPage(+event.target.value);
                setPendingTicketingServicesPage(0);
        };
    
       // Sort Handler
        const handleAllocatedTicketingServicesIncidentRequestSort = (event: any, property: any) =>
                {
                const isAsc = AllocatedTicketingServicesorderBy === property && AllocatedTicketingServicesorder === "asc";
                AllocatedTicketingServicesorder = isAsc ? "desc" : "asc";
                setAllocatedTicketingServicesOrder(AllocatedTicketingServicesorder);
                AllocatedTicketingServicesorderBy = property;
                setAllocatedTicketingServicesOrderBy(AllocatedTicketingServicesorderBy);
            };
      
        // Action function of table icons
        const AllocatedTicketingServicesactionFunction = (id: string, actionText: string) => 
            {
                if (actionText === "view") {
                    handleViewOpen(id)
                };
        };

        // Pagination Handler of table
        const AllocatedTicketingServiceshandleChangePage = (event: unknown, newPage: number) => {
                setAllocatedTicketingServicesPage(newPage);
        };

     //SOP-80 Included by PCS [search function in Completed Tickets Grid]
        // Search Handler of table
        const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells];
            const index = cells.findIndex((c: any) => c.id === e.target.name);
            // console.log("e.target.name",e.target.name)
            // console.log('e.target.value',e.target.value)
            if (index !== -1) {
            // Update the value in the search array based on input
                    search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
            }
            setCells(search);
            AllocatedTicketingServiceshandleChangePage(setAllocatedTicketingServicesPage,0);
            getAllocatedTicketingServicesList();
           };
    //EOF SOP-80 Included by PCS [search function in Completed Tickets Grid]
       
         // Rows Per page Handler
        const AllocatedTicketingServiceshandleChangeRowsPerPage = (
            event: React.ChangeEvent<HTMLInputElement>
                ) => {
                    setAllocatedTicketingServicessetRowsPerPage(+event.target.value);
                    setAllocatedTicketingServicesPage(0);
            };

    //EOF SOP-76 feedback points L2 (4)[combining ticketing & ticketing services]

    useEffect(() => {
        getPendingTicketsList();
        getAllocatedTicketsList(); 
        getPendingTicketingServicesList();//SOP-76 feedback points L2 [combining ticketing & ticketing services ]
        getAllocatedTicketingServicesList();//SOP-76 feedback points L2 [combining ticketing & ticketing services ]
    }, [PendingTicketsrowsPerPage,PendingTicketspage,PendingTicketsorderBy,PendingTicketsorder,
        //SOP-76 feedback points L2 commented by PCS [removing allcoated grid list]
        // AllocatedTicketsrowsPerPage,AllocatedTicketspage,AllocatedTicketsorderBy,AllocatedTicketsorder, 
        //SOP-76 feedback points L2 included by PCS [combining ticketing & ticketing services  ]
        PendingTicketingServicesrowsPerPage,PendingTicketingServicespage,PendingTicketingServicesorderBy,PendingTicketingServicesorder,
        AllocatedTicketingServicesrowsPerPage,AllocatedTicketingServicespage,AllocatedTicketingServicesorderBy,AllocatedTicketingServicesorder,
  
    ]); 
     
    return(
    <>
    <MainContainerWrapper headingText='Ticket List'>
    <Box>
    <Box>
    <EnhancedTable
    headCells={PendingTicketsHeadCell}
    rows={PendingTicketstableData}
    rowsPerPage={PendingTicketsrowsPerPage}
    page={PendingTicketspage}
    handleChangePage={PendingTicketshandleChangePage}
    handleChangeRowsPerPage={PendingTicketshandleChangeRowsPerPage}
    handleRequestSort={handlePendingTicketsIncidentRequestSort}
    order={PendingTicketsorder}
    orderBy={PendingTicketsorderBy}
    actionFunction={PendingTicketsactionFunction}
    isView={false}
    isDelete={false}
    isEdit={false}
    total={PendingTicketscount}
    isSearch={false}
    HoverColor={'#E7F1FB'}
    />
    </Box>
    {/* //SOP-76 feedback points L2 (4) commenetd by PCS [removing allocated ticket list]*/}
    {/* <Box mt={5}>
    <Typography variant='h1'>
        Allocated Ticket List
    </Typography>
    <Box mt={3}>
    <EnhancedTable
    headCells={AllocatedTicketsHeadCell}
    rows={AllocatedTicketstableData}
    rowsPerPage={AllocatedTicketsrowsPerPage}
    page={AllocatedTicketspage}
    handleChangePage={AllocatedTicketshandleChangePage}
    handleChangeRowsPerPage={AllocatedTicketshandleChangeRowsPerPage}
    handleRequestSort={handleAllocatedTicketsIncidentRequestSort}
    order={AllocatedTicketsorder}
    orderBy={AllocatedTicketsorderBy}
    actionFunction={AllocatedTicketsactionFunction}
    isView={true}
    isDelete={false}
    isEdit={false}
    total={AllocatedTicketscount}
    isSearch={false}
    HoverColor={'#E7F1FB'}
    />   
    </Box>
</Box>  */}
{/* //EOF SOP-76 feedback points L2 (4) commented by PCS [removing allocated ticket list]*/}

{/* //SOP-76 feedback points L2 (4)  included by PCS [combining ticket and ticketing service screen] */}
    <Box  mt={5}>
    <Typography variant='h1'>
    Open  Tickets
    </Typography>
    <Box mt={3}>
    <EnhancedTable
    headCells={PendingTicketingServicesHeadCell}
    rows={PendingTicketingServicestableData}
    rowsPerPage={PendingTicketingServicesrowsPerPage}
    page={PendingTicketingServicespage}
    handleSearch={handleSearchOpenTickets}//SOP-99 Included By PCS
    handleChangePage={PendingTicketingServiceshandleChangePage}
    handleChangeRowsPerPage={PendingTicketingServiceshandleChangeRowsPerPage}
    handleRequestSort={handlePendingTicketingServicesIncidentRequestSort}
    order={PendingTicketingServicesorder}
    orderBy={PendingTicketingServicesorderBy}
    actionFunction={PendingTicketingServicesactionFunction}
    isView={false}
    isDelete={false}
    isEdit={false}
    total={PendingTicketingServicescount}
    // SOP-99 Modified By PCS
    // isSearch={false} 
    isSearch={true}
    //EOF SOP-99 Modified By PCS
    HoverColor={'#E7F1FB'}
    />
</Box>
</Box>
<Box mt={5}>
<Typography variant='h1'>
    Completed Tickets 
</Typography>
<Box mt={3}>
<EnhancedTable
headCells={AllocatedTicketingServicesHeadCell}
rows={AllocatedTicketingServicestableData}
rowsPerPage={AllocatedTicketingServicesrowsPerPage}
page={AllocatedTicketingServicespage}
handleSearch={handleSearch}//SOP-80 Included By PCS
handleChangePage={AllocatedTicketingServiceshandleChangePage}
handleChangeRowsPerPage={AllocatedTicketingServiceshandleChangeRowsPerPage}
handleRequestSort={handleAllocatedTicketingServicesIncidentRequestSort}
order={AllocatedTicketingServicesorder}
orderBy={AllocatedTicketingServicesorderBy}
actionFunction={AllocatedTicketingServicesactionFunction}
isView={true}
isDelete={false}
isEdit={false}
total={AllocatedTicketingServicescount}
//SOP-80 Modified By PCS
// isSearch={false} 
isSearch={true}
//EOF SOP-80 Modified By PCS
HoverColor={'#E7F1FB'}
/>   
</Box>
</Box>
{/* //EOF SOP-76 feedback points L2 (4)  included by PCS [combining ticket and ticketing service screen] */}
<Box>
<Box>
<Dialog  open={AllocateOpen}>
   <AllocateTicketPage 
    handleAddClose={handleAddClose}
    getPendingTicketsList={getPendingTicketsList}
    TicketId={TicketId}
    //SOP-80 Included By PCS [to refresh open ticket grid]
    // getAllocatedTicketsList={getAllocatedTicketsList}
    getPendingTicketingServicesList={getPendingTicketingServicesList}
    //EOF SOP-80 Included By PCS [to refresh open ticket grid]
    />
</Dialog>
</Box>
{/* //SOP-76 L2 (4) commented by PCS as allocated list is removed */}
{/* <Box>
    <Dialog  open={ViewOpen}  >
        <ViewTicketPage 
        handleViewClose={handleViewClose}
        handleEditOpen={handleEditOpen}
        TicketId={TicketId}
        />
    </Dialog>
</Box> */}

{/* <Box>
    <Dialog  open={EditOpen}  >
        <EditTicketPage 
        handleEditClose={handleEditClose}
        getPendingTicketsList={getPendingTicketsList}
        TicketId={TicketId}
        getAllocatedTicketsList={getAllocatedTicketsList}
        />
    </Dialog>
</Box> */}
{/* //EOF SOP-76 L2 (4) commented by PCS as allocated list is removed */}
{/* //SOP-76 L2 (4) Included By PCS [combining ticketing & ticketing services] */}
<Box>
<Dialog  open={AllocateOpenTicketingServices}  >
    <AddTicketingServicesPage 
    handleAddClose={handleAddCloseTicketingService}
    getPendingTicketingServicesList={getPendingTicketingServicesList}
    TicketId={TicketIdTicketingServices}
    getAllocatedTicketingServicesList={getAllocatedTicketingServicesList}
    />
</Dialog>
</Box>
<Box>
<Dialog   open={ViewOpen} 
//SOP-99 Included By PCS [to increase dialog box width]
fullWidth
maxWidth="xl" 
sx={{
    '@media (max-width:600px)': {
        maxWidth: '100%',
        height: '1000px', 
    },
    
    height:'99vh', 
//EOF SOP-99 Included By PCS [to increase dialog box width]
    }}
>
<ViewTicketingServicePage
handleViewClose={handleViewClose}
handleEditOpen={handleEditOpen}
TicketId={TicketIdTicketingServices}
/>
</Dialog>
</Box>
<Box>
<Dialog  open={EditOpen}  
//SOP-99 Included By PCS [to increase dialog box width]
fullWidth
maxWidth="xl" 
sx={{
    '@media (max-width:600px)': {
        maxWidth: '100%',
        height: '1000px', 
    },
   height: '99vh', 
    //EOF SOP-99 Included By PCS [to increase dialog box width]
    }}
    >
    <EditTicketingServicePage 
    handleEditClose={handleEditClose}
    getPendingTicketingServicesList={getPendingTicketingServicesList}
    TicketId={TicketIdTicketingServices}
    getAllocatedTicketingServicesList={getAllocatedTicketingServicesList}
    />
</Dialog>
</Box>
{/* //EOF SOP-76 L2 (4) Included By PCS [combining ticketing & ticketing services] */}
</Box>
</Box>
</MainContainerWrapper>
</>
 )
}

export default TicketListPage;

// End of SOP-34