// SOP-86 Included By PCS [To View 'Hr Hire request' by id]
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,   
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { PiBriefcaseBold } from "react-icons/pi";
import { PiUserListFill } from "react-icons/pi";

const ViewHRNewHireRequest =()=>{

    const navigate = useNavigate();
    const {id} = useParams();
    const HireRequestId = id
    
    // Manager
        const [ManagerOptions, setManagerOptions] = useState<OptionType[]>([]);
        const [selectedManagerId, setselectedManagerId] = useState<string | null>('');
    // Supervisor
        const [SupervisorOptions, setSupervisorOptions] = useState<OptionType[]>([]);
        const [selectedSupervisorId, setselectedSupervisorId] = useState<string | null>('');
    // EmploymentTypeList usestate   
        const [EmploymentTypeListOptions, setEmploymentTypeListOptions] = useState<OptionType[]>([]);
        const [selectedEmploymentId, setselectedEmploymentId] = useState<string | null>('');
    // ContractTypeList usestate
        const [ContractTypeListOptions, setContractTypeListOptions] = useState<OptionType[]>([]);
        const [selectedContractTypeId, setselectedContractTypeId] = useState<string | null>('');
    // Department usestate
        const [DepartmentOptions, setDepartmentOptions] = useState<OptionType[]>([]);
        const [selectedDepartmentId, setselectedDepartmentId] = useState<string | null>('');
    // RequestStatus usestate
        const [RequestStatusOptions, setRequestStatusOptions] = useState<OptionType[]>([]);
        const [selectedRequestStatusId, setselectedRequestStatusId] = useState<string | null>('');
    // DateOfHireReques usestate
       const [DateOfHireRequestValue, setDateOfHireRequestValue] = useState<dayjs.Dayjs | null>(null);
    // WithInHowManyDaysRequestToBeFilled usestate
       const [WithInHowManyDaysRequestToBeFilledValue, setWithInHowManyDaysRequestToBeFilledValue]=useState('')
    // HowManyPositionsAreRequested usestate
       const [HowManyPositionsAreRequestedValue, setHowManyPositionsAreRequestedValue]=useState('')
    // DivisionAndFunction usestate
       const [DivisionAndFunctionNameValue, setDivisionAndFunctionNameValue]=useState('')
    // Location usestate
       const [LocationValue, setLocationValue]=useState('')
    // JobCodeAndTitle usestate
       const [JobCodeAndTitleValue, setJobCodeAndTitleValue]=useState('')
    // Justification usestate
       const [JustificationValue, setJustificationValue]=useState('')
    // OverviewAndRequestedJobProfile usestate
       const [OverviewAndRequestedJobProfileValue, setOverviewAndRequestedJobProfileValue]=useState('')
    // MinimumQualification usestate
       const [MinimumqualificationNeedValue, setMinimumqualificationNeedValue]=useState('')
    // PreferredQualification usestate
       const [PreferredQualificationNeedValue, setPreferredQualificationNeedValue]=useState('')
    // Keywords usestate
       const [KeywordsValue, setKeywordsValue]=useState('')
    // OtherRemarks usestate
       const [OtherRemarksValue, setOtherRemarksValue]=useState('')
    

        type OptionType = {
            name: string;
            value: string;
        };

        // drop down filter option
        const filterOptions = createFilterOptions<OptionType>({
            matchFrom: 'start',
            stringify: (option) => option.name,
        });


        // Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        let CompanyId = GlobalData.GlobalCompanyId
        let CreatedBy = GlobalData.GlobalUserId 



        // To get Hire request  details as per the id
            const GetHireRequestById =()=>
                {
                // console.log("HirerequestID",HireRequestId)
                        commonService
                        .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/get_hr_hire_request_details_by_id`, {
                                HRHireRequestId:HireRequestId,
                                CompanyId:CompanyId,
                                CreatedBy:CreatedBy
                            })
                            .then((res: any) => 
                            { 
                                    const HireRequestData = res.data.data[0]
                                    // console.log("HireRequestData ..",HireRequestData)


                                    setWithInHowManyDaysRequestToBeFilledValue(HireRequestData.hirerequest_duration)
                                    setKeywordsValue(HireRequestData.hirerequest_hiring_keywords)
                                    setOtherRemarksValue(HireRequestData.hirerequest_hiring_others)
                                    setDateOfHireRequestValue(HireRequestData.hirerequest_start_date)
                                    setDivisionAndFunctionNameValue(HireRequestData.hirerequest_hiring_division_function)
                                    setJobCodeAndTitleValue(HireRequestData.hirerequest_hiring_jobcode_title)
                                    setLocationValue(HireRequestData.hirerequest_hiring_location)
                                    setHowManyPositionsAreRequestedValue(HireRequestData.hirerequest_total_open_position)
                                    setOverviewAndRequestedJobProfileValue(HireRequestData.hirerequest_hiring_joboverview)
                                    setJustificationValue(HireRequestData.hirerequest_hiring_justification)
                                    setPreferredQualificationNeedValue(HireRequestData.hirerequest_hiring_preferredqualification)
                                    setMinimumqualificationNeedValue(HireRequestData.hirerequest_hiring_minimumqualification)

                                    //EmployementType 
                                    let formattedEmployementType = res.data.data.map((item:any) => {
                                                    const { hirerequest_employment_type_id } = item;
                                                    return {
                                                        value: hirerequest_employment_type_id,
                                                        };
                                                    });
                                                    setselectedEmploymentId(formattedEmployementType[0].value)

                                    //Hiring Manager 
                                    let formattedHiringManager = res.data.data.map((item:any) => {
                                                    const { hirerequest_hiring_manager_id } = item;
                                                    return {
                                                            value: hirerequest_hiring_manager_id,
                                                            };
                                                    });
                                                    setselectedManagerId(formattedHiringManager[0].value)

                                    //Hiring Supervisor 
                                    let formattedHiringSupervisor = res.data.data.map((item:any) => {
                                                    const { hirerequest_hiring_supervisor_id } = item;
                                                    return {
                                                            value: hirerequest_hiring_supervisor_id,
                                                        };
                                                    });
                                                    setselectedSupervisorId(formattedHiringSupervisor[0].value)
                                        
                                    //Department
                                    let formattedDepartment = res.data.data.map((item:any) => {
                                                    const { hirerequest_hiring_department_id } = item;
                                                    return {
                                                            value: hirerequest_hiring_department_id,
                                                    };
                                                    });
                                                    setselectedDepartmentId(formattedDepartment[0].value)

                                    //SOP-95 Commented By PCS [contract type is removed]            
                                    //Contract Type  
                                    // let formattedContractType = res.data.data.map((item:any) => {
                                    //                 const { hirerequest_hiring_jobstatus } = item;
                                    //                 return {
                                    //                         value: hirerequest_hiring_jobstatus,
                                    //                     };
                                    //                 });
                                    //                 setselectedContractTypeId(formattedContractType[0].value)
                                    //EOF SOP-95 Commented By PCS [contract type is removed] 

                                    //Current Status   
                                    let formattedCurrentStatus = res.data.data.map((item:any) => {
                                                    const { hirerequest_status_id } = item;
                                                    return {
                                                    value: hirerequest_status_id,
                                                    };
                                                    });
                                                    setselectedRequestStatusId(formattedCurrentStatus[0].value)
                    
                
                            })
                 }

//API for drop down values
    
            // Employee List 
                    const GetEmployeeList = async () => 
                        {
                            const EmployeeListPayload = {
                                CompanyId: CompanyId,
                                active: "Y"
                                                        }
                            try{
                                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getEmployeeList`, EmployeeListPayload);
                                let formattedEmployeeList = res.data.data.map((item: any) => {
                                const { employee_id, employee_name } = item;
                                    return {
                                        name: employee_name,
                                        value: employee_id,
                                    };
                                });
                                setManagerOptions(formattedEmployeeList);
                                setSupervisorOptions(formattedEmployeeList);
                            
                            } catch (err) {
                                console.error(err);
                            }
                        };

            // Hire request Status 
                    const GetHRHireRequestStatusList = async () => {
                        try {
                            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestStatusList`);
                        
                            let formattedPriorityType = res.data.data.map((item: any) => {
                                const { sys_hr_hirerequest_status_id, sys_hr_hirerequest_status_name } = item;
                                return {
                                    name: sys_hr_hirerequest_status_name,
                                    value: sys_hr_hirerequest_status_id,
                                };
                            });
                            setRequestStatusOptions(formattedPriorityType);

                            //  Opened as the default value
                            const openedStatus = formattedPriorityType.find((option: OptionType) => option.name.toLowerCase() === 'Opened ');
                                if (openedStatus) {
                                    setselectedRequestStatusId(openedStatus.value);
                                }

                        } catch (err) {
                            //console.log( "co",err);
                        }
                    }; 
            
            // HireRequest EmploymentTypeList 
                    const GetHRHireRequestEmploymentTypeList = async () => { 
                        try {
                            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestEmploymentTypeList`);

                            let formattedPriorityType = res.data.data.map((item:any) => {
                            const { sys_hr_employment_type_id, sys_hr_employment_type_name } = item;
                            return {
                                name: sys_hr_employment_type_name,
                                value: sys_hr_employment_type_id,
                            };
                            });
                            setEmploymentTypeListOptions(formattedPriorityType)
                        } catch (err) {
                            console.log( "co",err);
                        }
                    };
                
            // ContractType List 
                    const GetHRHireRequestContractTypeList = async () => { 
                        try {
                            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestContractTypeList`);

                            let formattedContractType = res.data.data.map((item:any) => {
                            const { sys_hr_contract_type_id, sys_hr_contract_type_name } = item;
                            return {
                                name: sys_hr_contract_type_name,
                                value: sys_hr_contract_type_id,
                            };
                            });
                            setContractTypeListOptions(formattedContractType)
                        } catch (err) {
                            console.log( "co",err);
                        }
                        };
                
            // DepartmentList 
                    const GetDepartmentList = async () => { 
                        const EmployeeListPayload = {
                            CompanyId: CompanyId
                        }
                        try {
                            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getDepartmentList`, EmployeeListPayload);
                        
                            let formattedEmployeeList = res.data.data.map((item: any) => {
                                const { departmentid, departmentname } = item;
                                return {
                                    name: departmentname,
                                    value: departmentid,
                                };
                            });
                            setDepartmentOptions(formattedEmployeeList);
                        } catch (err) {
                            console.error(err);
                        }
                    };

//EOF API for drop down values

        // UseEffect 
                useEffect(() => {
                    GetEmployeeList();
                    GetHRHireRequestStatusList();
                    GetHRHireRequestEmploymentTypeList();
                    GetDepartmentList();
                    GetHRHireRequestContractTypeList();
                    GetHireRequestById();
                }, []);

    return(
        <>
        <MainContainerWrapper
          headingText='View HR Hire Request'>
            <Box>
                <form noValidate>      
                {/* Request Information Accordian - start*/}
                        <Box>
                            <Accordion defaultExpanded sx={{
                                    borderRadius:'30px',
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
                                        <Typography fontSize={'1rem'} ml={2}>Request Information</Typography> 
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                            <Box  sx={{
                                                ml:{
                                                    xl:12,
                                                    lg:5,
                                                    md:0,
                                                    xs:0
                                                },
                                                mr:{
                                                    xl:12,
                                                    lg:5,
                                                    md:0,
                                                    xs:0
                                                }
                                            }}  >
                                                <Grid container  mb={5} spacing={5} display={'flex'} >
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            disabled
                                                            fullWidth
                                                            options={EmploymentTypeListOptions as OptionType[]}
                                                            filterOptions={filterOptions}
                                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                            value={EmploymentTypeListOptions.find((option: OptionType) => option.value === selectedEmploymentId) || null}
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    setselectedEmploymentId(newValue.value);
                                                                
                                                                } else {
                                                                    setselectedEmploymentId('');
                                                                }
                                                            }}
                                                            renderInput={(params) => 
                                                            <TextField {...params}
                                                            
                                                                required label="Type of Employment Required" 
                                                                variant="standard" 
                                                                
                                                                // InputLabelProps={{
                                                                //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                                // }}
                                                                // InputProps={{
                                                                //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                                // }}
                                                                
                                                                
                                                                />
                                                             
                                                            }
                                                            />
                                                            
                                                    </Grid>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <FormControl fullWidth>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    disabled
                                                                    label="Date of Hire Request"
                                                                    value={DateOfHireRequestValue ? dayjs(DateOfHireRequestValue) : null}
                                                                
                                                                    format="DD-MM-YYYY"
                                                                    slotProps={{
                                                                        textField: {
                                                                            required: true,
                                                                            // InputLabelProps: {
                                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }, // Label color
                                                                            // },
                                                                            InputProps: {
                                                                                style: { color: 'red' }, // Input text color
                                                                            },
                                                                        }
                                                                    }}
                                                                />                                                        
                                                                    
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'}>                                   
                                                    <Grid item lg={6} md={6} xs={12} >
                                                    {/* //SOP-95 Modified By PCS [modified from 'text' field to 'date'] */}
                                                        {/* <TextField
                                                           disabled
                                                            //readOnly
                                                            type='text'
                                                            
                                                            variant="standard"
                                                            required 
                                                                label='With in How many days, request to be filled?'
                                                            name='With in How many days, request to be filled?'
                                                            fullWidth
                                                            value={WithInHowManyDaysRequestToBeFilledValue}

                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            
                                                        />  */}
                                                         <FormControl fullWidth>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    disabled
                                                                    label="Expected Date"
                                                                    value={WithInHowManyDaysRequestToBeFilledValue ? dayjs(WithInHowManyDaysRequestToBeFilledValue) : null}
                                                                     format="DD-MM-YYYY"
                                                                    slotProps={{
                                                                        textField: {
                                                                            required: true,
                                                                            InputProps: {
                                                                                style: { color: 'red' }, // Input text color
                                                                            },
                                                                        }
                                                                    }}
                                                                />                                                        
                                                             </LocalizationProvider>
                                                        </FormControl>
                                                    </Grid>
                                                    {/* //EOF SOP-95 Modified By PCS [modified from 'text' field to 'date'] */}
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <TextField
                                                            disabled
                                                           
                                                            type='text'
                                                            variant="standard"
                                                            required label='How many positions are required?'
                                                            name='How many positions are required?'
                                                            fullWidth
                                                             value={HowManyPositionsAreRequestedValue}
                                                            //  InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }}
                                                            // sx={{
                                                            //     '& input': {
                                                            //       color: "rgba(0, 0, 0, 0.7)", // Ensures input text color is applied
                                                            //     },
                                                            //     '& .MuiInputLabel-root': {
                                                            //       color: "rgba(0, 0, 0, 0.6)", // Ensures label color is applied
                                                            //     },
                                                            //   }}
                                                        
                                                        /> 
                                                    </Grid>
                                                </Grid>                             
                                            </Box>
                                        </AccordionDetails>
                            </Accordion>
                        </Box>
                {/* End of   {/* Request Information Accordian */} 

                {/* Hiring Department Information Accordian */}
                        <Box mt={1} >
                        <Accordion defaultExpanded sx={{
                                    borderRadius:'30px',
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <PiUserListFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                            <Typography fontSize={'1rem'} ml={2}>Hiring Department Information</Typography> 
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                            <Box sx={{
                                                ml:{
                                                    xl:12,
                                                    lg:5,
                                                    md:0,
                                                    xs:0
                                                },
                                                mr:{
                                                    xl:12,
                                                    lg:5,
                                                    md:0,
                                                    xs:0
                                                }
                                            }} >
                                            <Grid container mb={5} spacing={5} display={'flex'}>
                                                <Grid item lg={6} md={6} xs={12} >
                                                    <Autocomplete
                                                        disabled
                                                        id="combo-box-demo"
                                                        fullWidth
                                                        options={ManagerOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={ManagerOptions.find((option: OptionType) => option.value === selectedManagerId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setselectedManagerId(newValue.value);
                                                                
                                                            } else {
                                                                setselectedManagerId('');
                                                            
                                                            }
                                                        }}
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                            
                                                            required label="Name of the Hiring Manager" 
                                                            variant="standard" 
                                                            
                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }}
                                                            
                                                            />}
                                                    />
                                                </Grid>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <Autocomplete
                                                            disabled
                                                            id="combo-box-demo"
                                                            fullWidth
                                                            options={SupervisorOptions as OptionType[]}
                                                            filterOptions={filterOptions}
                                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                            value={SupervisorOptions.find((option: OptionType) => option.value === selectedSupervisorId) || null}
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    setselectedSupervisorId(newValue.value);
                                                                    
                                                                } else {
                                                                    setselectedSupervisorId('');
                                                                    
                                                                }
                                                            }}
                                                            renderInput={(params) => 
                                                            <TextField {...params}
                                                                
                                                                required label="Name of the Hiring Supervisor" 
                                                                variant="standard" 
                                                                // InputLabelProps={{
                                                                //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                                // }}
                                                                // InputProps={{
                                                                //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                                // }}
                                                                />}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                <Grid container mb={5} spacing={5} display={'flex'}>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <Autocomplete
                                                            disabled
                                                            id="combo-box-demo"
                                                            fullWidth
                                                            options={DepartmentOptions as OptionType[]}
                                                            filterOptions={filterOptions}
                                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                            value={DepartmentOptions.find((option: OptionType) => option.value === selectedDepartmentId) || null}
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    setselectedDepartmentId(newValue.value);
                                                                    
                                                                } else {
                                                                    setselectedDepartmentId('');
                                                                
                                                                }
                                                            }}
                                                            // sx={{ width: 235, ml: 1 }}
                                                            renderInput={(params) => 
                                                            <TextField {...params}
                                                            
                                                            required label="Which Department is requested for Hire? " 
                                                            variant="standard"
                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }} 
                                                            
                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <TextField
                                                            disabled
                                                            type='text'
                                                            variant="standard"
                                                            label='Division/Function'
                                                            name='Price'
                                                            fullWidth
                                                           value={DivisionAndFunctionNameValue || ''}
                                                        //    InputLabelProps={{
                                                        //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                        // }}
                                                        // InputProps={{
                                                        //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                        // }}
                                                          
                                                        /> 
                                                    </Grid>
                                                </Grid>

                                                <Grid container mb={5} spacing={5} display={'flex'}>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <TextField
                                                            disabled
                                                            type='text'
                                                            variant="standard"
                                                            label='Which Location is needed for hire?'
                                                            name='Which Location is needed for hire?'
                                                            fullWidth
                                                            value={LocationValue || ''}
                                                            InputLabelProps={{
                                                                style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            }}
                                                            InputProps={{
                                                                style: {  color: 'red', } // Add background color and padding
                                                            }}
                                                        /> 
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                            </Accordion>
                        </Box>
                {/* End of Hiring Department Information Accordian */}

                {/* Job Information Accordian */}
                        <Box mt={1}>
                        <Accordion defaultExpanded sx={{
                                    borderRadius:'30px',
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <PiBriefcaseBold  height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                            <Typography fontSize={'1rem'} ml={2}>Job Information</Typography> 
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                            <Box sx={{
                                                ml:{
                                                    xl:12,
                                                    lg:5,
                                                    md:0,
                                                    xs:0
                                                },
                                                mr:{
                                                    xl:12,
                                                    lg:5,
                                                    md:0,
                                                    xs:0
                                                }
                                            }}>
                                            <Grid container mb={5} spacing={5} display={'flex'}>
                                            {/* //SOP-95 Modified by PCS [alignment modified] */}
                                            {/* <Grid item lg={6} md={6} xs={12}  > */}
                                            <Grid item lg={12} md={12} xs={12}  >
                                            {/* //EOF SOP-95 Modified by PCS [alignment modified]  */}
                                                <TextField
                                                    disabled
                                                    type='text'
                                                    variant="standard"
                                                    required label='Job Code/ Title & Job'
                                                    name='Job Code/ Title & Job'
                                                    fullWidth
                                                    value={JobCodeAndTitleValue || ''}
                                                    // InputLabelProps={{
                                                    //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                    // }}
                                                    // InputProps={{
                                                    //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                    // }}
                                                /> 
                                                </Grid>
                                                 {/* //SOP-95 Commented by PCS [Remove Contract type] */}
                                                {/* <Grid item lg={6} md={6} xs={12} >
                                                    <Autocomplete
                                                        disabled
                                                        id="combo-box-demo"
                                                        fullWidth
                                                        options={ContractTypeListOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={ContractTypeListOptions.find((option: OptionType) => option.value === selectedContractTypeId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setselectedContractTypeId(newValue.value);
                                                                
                                                            } else {
                                                                setselectedContractTypeId('');
                                                            
                                                            }
                                                        }}
                                                        // sx={{ width: 235, ml: 1 }}
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                    
                                                            required label="Contract Type & Job" 
                                                            variant="standard"
                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }}
                                                            
                                                            />}
                                                    />
                                                </Grid> */}
                                                 {/* //EOF SOP-95 Commented by PCS [Remove Contract type] */}
                                            </Grid>
                                            <Grid container mb={5} spacing={5} >
                                                <Grid item lg={12} md={12} xs={12}  >
                                                    <TextField
                                                        disabled
                                                        type='text'
                                                        variant="filled"
                                                        label='Justification'
                                                        name='Justification'
                                                        fullWidth
                                                        multiline
                                                        maxRows={10}
                                                       value={JustificationValue}
                                                    //    InputLabelProps={{
                                                    //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                    // }}
                                                    // InputProps={{
                                                    //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                    // }}
                                                        
                                                    /> 
                                                </Grid>
                                            </Grid>
                                                <Grid container mb={5} spacing={5} >
                                                    <Grid item lg={12} md={12} xs={12} >
                                                        <TextField
                                                        disabled

                                                            type='text'
                                                        variant="filled"
                                                            label='Overview & Requested Job Profile'
                                                            name='Overview & Requested Job Profile'
                                                            fullWidth
                                                            multiline
                                                            maxRows={10}
                                                           value={OverviewAndRequestedJobProfileValue}
                                                        //    InputLabelProps={{
                                                        //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                        // }}
                                                        // InputProps={{
                                                        //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                        // }}
                                                            
                                                        /> 
                                                    </Grid>
                                                </Grid>
                                                <Grid container mb={5} spacing={5} display={'flex'}>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <TextField
                                                        disabled

                                                            type='text'
                                                            variant="standard"
                                                            required label='Minimum qualification need'
                                                            name='Minimum qualification need'
                                                            fullWidth
                                                            // multiline
                                                            value={MinimumqualificationNeedValue}
                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }}
                                                            
                                                        /> 
                                                    </Grid>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <TextField
                                                            disabled

                                                            type='text'
                                                            variant="standard"
                                                            label='Preferred qualification need'
                                                            name='Preferred qualification need'
                                                            fullWidth
                                                            // multiline
                                                            value={PreferredQualificationNeedValue}
                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }}
                                                            
                                                        /> 
                                                    </Grid>
                                                </Grid>

                                                <Grid container mb={5} spacing={5} display={'flex'} >
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <TextField
                                                            disabled
                                                            
                                                            type='text'
                                                            variant="standard"
                                                            required 
                                                            //SOP-95 Modified By PCS [Renamed]
                                                            // label='Keywords'
                                                            label='Skills'
                                                           //EOF SOP-95 Modified By PCS [Renamed]
                                                            name='Keywords'
                                                            fullWidth
                                                            value={KeywordsValue}
                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }}
                                                        
                                                        /> 
                                                    </Grid>
                                                    <Grid item lg={6} md={6} xs={12} >
                                                        <TextField
                                                        disabled

                                                            type='text'
                                                            variant="standard"
                                                            label='Other Remarks'
                                                            name='Other Remarks'
                                                            fullWidth
                                                            // multiline
                                                            value={OtherRemarksValue}
                                                            // InputLabelProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                            // }}
                                                            // InputProps={{
                                                            //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                            // }}
                                                        
                                                        /> 
                                                    </Grid>
                                                </Grid>
                                                <Grid container mb={5} spacing={5}  display={'flex'} >
                                                    <Grid item lg={6} md={6} xs={12} >                                               
                                                        <Autocomplete
                                                        disabled

                                                            id="combo-box-demo"
                                                            fullWidth
                                                            options={RequestStatusOptions as OptionType[]}
                                                            filterOptions={filterOptions}
                                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                            value={RequestStatusOptions.find((option: OptionType) => option.value === selectedRequestStatusId) || null}
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    setselectedRequestStatusId(newValue.value);
                                                                    
                                                                } else {
                                                                    setselectedRequestStatusId('');
                                                                }
                                                            }}
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                
                                                                    required label="Current Status of Hire Request"
                                                                    variant="standard"
                                                                    // InputLabelProps={{
                                                                    //     style: { color: "rgba(0, 0, 0, 0.6)" }
                                                                    // }}
                                                                    // InputProps={{
                                                                    //     style: { color: "rgba(0, 0, 0, 0.5)" }
                                                                    // }} 
                                                                    
                                                                    />}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                            </Accordion>
                        </Box>
                {/* End of Job Information Accordian */} 

               {/* Button  start */}
                        <Box>                    
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    onClick={()=>{
                                        //SOP-101 Modified by PCS [Existing page is modified,so abck naviagtion is modified]
                                        // navigate('/home')
                                        navigate('/view_hireRequestdetails_grid')
                                        //EOF SOP-101 Modified by PCS [Existing page is modified,so abck naviagtion is modified]
                                    }} 
                                >
                                    Back
                                </Button>

                                {/* <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        // CreateHRHireRequest(event);
                                    }}
                                >
                                    Save
                                </Button> */}
                            </Box>
                        </Box>
                {/* Button  end */}

            </form>
            </Box>
        </MainContainerWrapper>
        </>
    )
}
export default ViewHRNewHireRequest;
// EOF SOP-86 Included By PCS [View Hr Hire request by id]