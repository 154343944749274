import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Dialog,
    Divider,//SOP-101 Included by PCS
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import requestAsset from '../../asset/images/requestAsset1.png';
import raiseTicket from '../../asset/images/raiseTicket.png'
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';
import HR from '../../asset/images/HR employee.jpg'
import dayjs from 'dayjs';
import RequetAnAssetPage from '../RequestAnAsset/RequestAnAsset'; 

//SOP-101 Included by PCS[Images imported]
import RequestAssets from '../../asset/images/RequestAssets.png'
import RaiseATicket from '../../asset/images/RaiseATicket.png'
import TimeSheets from '../../asset/images/TimeSheets.png'
import NewHireRequests from '../../asset/images/NewHireRequests.png'
//SOP-101 Included by PCS [charts imported]

import MyApprovalsChart from '../../component/charts/HomePageMyApprovals';
import MyTicketsChart from '../../component/charts/HomePageMyTickets';
import MyHireRequestsChart from '../../component/charts/HomePageHireRequests';
import MyAssetRequestsChart from '../../component/charts/HomePageMyAssetRequests';
import { SickLeaveChart,CasualLeaveChart,PaidLeaveChart } from '../../component/charts/HomePageLeaveRequest';

//SOP-101 Included by PCS

import { Card,CardContent } from '@mui/material';//SOP-101 Included By PCS
// Define a type for menu details
interface MenuDetails {
    menuname: string;
    menuid: number;
}

const HomePage =()=>{ 
   // (SOP-64) Asset Management - Home Page - New hire Request - TS
    const [isHRRequestVisible, setHRRequestVisible] = useState(false); // Visible state
    // console.log("isHRRequestVisible", isHRRequestVisible)
    // (SOP-64) Asset Management - Home Page - New hire Request - TS
 
const navigate = useNavigate();

// To set action type for edit ad delete
let actionType;
        
  // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
  // console.log("GlobalData",GlobalData)
 
  //SOP-101 Included by PCS
    let GlobalUserName = GlobalData.GlobalUserName
    useEffect(() => {
            // Read from session storage
            const employeeMenuDetails = sessionStorage.getItem('EmployeeMenuDetails');
            if (employeeMenuDetails) {
              try {
                const menuDetailsArray: MenuDetails[] = JSON.parse(employeeMenuDetails);
                    // console.log("menuDetailsArray", menuDetailsArray)
                  
                // check if any item in the array matches the condition
                const isHRHierarchyMenuPresent = menuDetailsArray.some((menuDetails: MenuDetails) =>
                  menuDetails.menuname === 'HR Work Space' && menuDetails.menuid === 11
                  );
                //   console.log("isHRHierarchyMenuPresent",isHRHierarchyMenuPresent)
                setHRRequestVisible(isHRHierarchyMenuPresent);
              }
              catch (error) {
                // console.error('error from session storage', error);
                setHRRequestVisible(false);
              }
            } else {
              setHRRequestVisible(false);
            }
          }, []);

    useEffect(() => {}, []); 

//SOP-101 Included by PCS[New screen]

return (
<MainContainerWrapper headingText="">
  <Box fontFamily="Rubik"
   sx={{ 
    width:'100%', 
    height:'100%',
    // marginTop:'-2%',
    // backgroundColor:'#F5F4FB'
    backgroundColor:'white',
   }} >
  {/* Main grid divided into two grids */}
      <Grid
        spacing={2} // Adds space between the left and right grids
        container
        sx={{
         
          // backgroundColor:'#F5F4FB',
          backgroundColor:'white',
          width: '100%',
          height:'100%',
          flexWrap: 'wrap' 
      }}
        display={'flex'}
        // boxShadow="rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
      >
{/* First grid: Left side */}
  <Grid item lg={5.75} md={5.75} xs={12} ml={2} spacing={2} sx={{width: '90%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
{/* Card 1 */}
<Card sx={{ marginLeft:'2%',overflow: 'auto',
flex:'wrap',borderRadius: '8px', border: '2px solid #E6E6E6',
backgroundColor: 'white',
boxShadow:"E6E6E6 0px 2px 4px 0px, E6E6E6 0px 2px 16px 0px"
}}>
  <CardContent>
  <div>
    <Typography
      sx={{
        fontFamily: 'Rubik',
        position: 'relative',
        left: '2% !important',
        fontWeight: 600,
        fontSize: '26px',
        lineHeight: '37px',
        color: '#3C3C3C',
        letter: '0.25%',
        // Make the font size responsive with viewport width
'@media (max-width: 600px)': { fontSize: '20px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '22px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '26px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '28px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '30px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '32px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '36px' }, // Ultra-wide and large 4K monitors
  }}
     >
     Hello {GlobalUserName},
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Rubik',
          position: 'relative',
          left: '2% !important',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '24px',
          color: '#5B6269',
          marginTop: '2.25%',
          // Make the font size responsive with viewport width
'@media (max-width: 600px)': { fontSize: '16px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '18px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '20px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '20px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '22px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '24px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '27px' }, // Ultra-wide and large 4K monitors
        }}
      >
        How Can we help you today?
      </Typography>
    </div>
    {/* Images card grids */}
    <Grid container spacing={1} direction="row" justifyContent="flex-start">
      <Grid mt={1} mb={3}  item lg={4} sm={12} md={6} xs={12}>
        <Card 
          onClick={()=>{navigate("/request_an_asset")}}
        
        sx={{ backgroundColor: '#E4F2FF',cursor:'pointer'}}>
          <CardContent sx={{ display: 'flex', flexDirection: 'row', 
            justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Rubik',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '22px',
                color: '#3C3C3C',
                letter: '0.50%',
                // Make the font size responsive with viewport width
                          
'@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
              }}
            >
              Request Asset
            </Typography>
            <img src={RequestAssets} alt="Request an Asset Img" />
          </CardContent>
        </Card>
      </Grid>

    <Grid mt={1} item lg={4} sm={12} md={6} xs={12}>
      <Card 
        onClick={()=>{ navigate('/raise_a_ticket_page')}}
        sx={{ cursor:'pointer',backgroundColor: '#D9F1F5' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'row',
           justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Rubik',
              fontWeight: 500,
                fontSize: '16px',
                color: '#3C3C3C',
                letter: '0.50%',
                lineHeight: '22px',
            // Make the font size responsive with viewport width
            
'@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
              }}
          >
            Raise Ticket
          </Typography>
          <img src={RaiseATicket} alt="Request an Asset Img" />
        </CardContent>
      </Card>
    </Grid>
    {isHRRequestVisible && (
    <Grid mt={1} item lg={4} sm={12} md={6} xs={12}>
      <Card 
          onClick={()=>{navigate("/hr_new_hire_request")}}
          sx={{ cursor:'pointer',backgroundColor: '#FFF8E0' }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'row',
             justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Rubik',
                fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22px',
                color: '#3C3C3C',
                letter: '0.50%',
 '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
            }}
          >
            New Hire Request
          </Typography>
          <img src={NewHireRequests} alt="Request an Asset Img" />
        </CardContent>
      </Card>
    </Grid>)}
  </Grid>
</CardContent>
</Card>
{/* </Grid> */}

{/* Card 2 */}
<Card  sx={{ marginLeft:'2%',marginTop:'0.5%',overflow: 'auto',
 borderRadius: '8px', border: '2px solid #E6E6E6',
 backgroundColor: 'white',boxShadow:"E6E6E6 0px 2px 4px 0px, E6E6E6 0px 2px 16px 0px",
 padding: '8px',  // Reduced padding for outer card

 //  flex: 1
}}>
<CardContent sx={{ padding: '8px' }}>
   <Typography
      sx={{
        fontFamily: 'Rubik',
        // position: 'relative',
        left: '2% !important',
        fontWeight: 500,
        fontSize: '18px',
        color: '#3C3C3C',
        letter: '0.25%',
'@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
      }}
      >
        My Asset Requests
   </Typography>
   <Divider sx={{ marginTop:'3px',border: '0.1px solid #00000012' }} />
   <div style={{marginTop:'-30px'}}>
   <MyAssetRequestsChart />
   </div>
  <div onClick={()=>{navigate("/view_assetrequest_details_grid")}}>
    {/* <Typography
      sx={{
         
          borderRadius: '4px',
          border:'1px solid gray',
           marginLeft:'60%',
          fontFamily: 'Rubik',
          fontWeight: 400,
          fontSize: '15px',
          color: '#3C3C3C',
          backgroundColor:'#F2F2F2',
          padding:'2px',
          textAlign:'center',
          cursor:'pointer',
    }}
     >View Asset Request Details</Typography> */}
<Typography
    sx={{
      borderRadius: '4px',
      border: '1px solid gray',
      fontFamily: 'Rubik',
      fontWeight: 400,
      color: '#3C3C3C',
      backgroundColor: '#F2F2F2',
      padding: '2px',
      textAlign: 'center',
      cursor: 'pointer',
      // Default styles
      marginTop: '0%',
      marginLeft: '0%',
      // width: '100%',
      fontSize: '14px',  // Default font size for small screens
      // Media queries for different screen sizes
      '@media (max-width: 600px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 768px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 1024px)': {
        fontSize: '16px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (min-width: 1025px) and (max-width: 1280px)': {
        fontSize: '15px',
        marginTop: '-7%',
        marginLeft: '40%',
      },
      '@media (min-width: 1281px) and (max-width: 1600px)': {
        fontSize: '16px',
        marginTop: '-10%',
        marginLeft: '40%',
      },
      '@media (min-width: 1601px) and (max-width: 1920px)': {
        fontSize: '22px',
        marginTop: '-4%',
        marginLeft: '60%',
      },
      '@media (min-width: 1921px)': {
        fontSize: '24px',
        marginTop: '-3%',
        marginLeft: '60%',
      },
    }}
  >
    View Asset Request Details
</Typography>
</div>
    </CardContent>
  </Card> 
{/* Card 3 */}
<Card  sx={{ marginLeft:'2%',marginTop:'0.5%',overflow: 'auto',
 borderRadius: '8px', border: '2px solid #E6E6E6',boxShadow:"E6E6E6 0px 2px 4px 0px, E6E6E6 0px 2px 16px 0px",
 backgroundColor: 'white',
 
}}>
 <CardContent>
  <Typography
      sx={{
        fontFamily: 'Rubik',
        // position: 'relative',
        left: '2% !important',
        fontWeight: 500,
        fontSize: '18px',
        color: '#3C3C3C',
        letter: '0.25%',
'@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
      }}
  >
    My Approvals
  </Typography>
  <Divider sx={{ border: '0.1px solid #00000012' }} />
  <div style={{marginTop:'-30px'}}>
  <MyApprovalsChart /> 
  </div>
  <div>
  {/* <Typography
    sx={{
      borderRadius: '4px',
      border:'1px solid gray',
      marginLeft:'60%',
      fontFamily: 'Rubik',
      fontWeight: 400,
      fontSize: '15px',
      color: '#3C3C3C',
      backgroundColor:'#F2F2F2',
      padding:'2px',
      textAlign:'center',
      // cursor:'pointer',
 }}
  >New Approval Requests</Typography> */}

<Typography
    sx={{
      borderRadius: '4px',
      border: '1px solid gray',
      fontFamily: 'Rubik',
      fontWeight: 400,
      color: '#3C3C3C',
      backgroundColor: '#F2F2F2',
      padding: '2px',
      textAlign: 'center',
      // cursor: 'pointer',
      // Default styles
      marginTop: '0%',
      marginLeft: '0%',
      
      fontSize: '14px',  // Default font size for small screens
      // Media queries for different screen sizes
      '@media (max-width: 600px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 768px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 1024px)': {
        fontSize: '16px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (min-width: 1025px) and (max-width: 1280px)': {
        fontSize: '15px',
        marginTop: '-7%',
        marginLeft: '40%',
      },
      '@media (min-width: 1281px) and (max-width: 1600px)': {
        fontSize: '16px',
        marginTop: '-9%',
        marginLeft: '40%',
      },
      '@media (min-width: 1601px) and (max-width: 1920px)': {
        fontSize: '22px',
        marginTop: '-4%',
        marginLeft: '60%',
      },
      '@media (min-width: 1921px)': {
        fontSize: '24px',
        marginTop: '-3%',
        marginLeft: '60%',
      },
    }}
  >
   New Approval Requests
</Typography>
  </div>
</CardContent>
</Card> 
      </Grid>
{/* Second grid: Right side */}
    <Grid item lg={5.75} md={5.75} xs={12} ml={2}  spacing={2} sx={{ height: '100%', width: '90%',display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
       {/* Card 4 */} 
        
        <Card sx={{ marginLeft:'2%', marginTop:'0.5%',overflow: 'auto',
        borderRadius: '8px', border: '2px solid #E6E6E6',
        backgroundColor: 'white',boxShadow:"E6E6E6 0px 2px 4px 0px, E6E6E6 0px 2px 16px 0px"
          }}>
        <CardContent>
        <Grid container direction="row"
          sx={{ display: 'flex' }}>
        <Grid item lg={3} md={3} xs={3} >

            <Typography
              sx={{
                fontFamily: 'Rubik',
                // position: 'relative',
                left: '2% !important',
                fontWeight: 500,
                fontSize: '18px',
                color: '#3C3C3C',
                letter: '0.25%',
                '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
              }}
            >
              Quick Access
            </Typography>

        </Grid>
        <Grid item lg={4} md={4} xs={4} ></Grid>
        <Grid sx={{alignItems:'right',cursor:'pointer'}} 
        //  onClick={()=>{navigate("/hr_hierarchy")}}
         onClick={()=>{navigate(`/hr_hierarchy/${1}`);}}
        
        item lg={4} md={4} xs={4} >
        <Typography
            sx={{
              padding:'2.5%',
              textAlign:'center',
              fontFamily: 'Rubik',
              backgroundColor:'#195AFE',
              left: '4% !important',
              fontWeight: 400,
              fontSize: '14px',
              color: '#FFFFFF',
              letter: '0.25%',
              borderRadius:'4px',
              marginBottom:'4px',
              '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
              '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
              '@media (max-width: 1024px)': { fontSize: '14px' }, // Standard laptops
              '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '14px' }, // Larger laptops (1280px or less)
              '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '16px' }, // Standard desktop monitors
              '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
              '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors
            }}
              >
              View Employee Org
          </Typography>
          </Grid>
          </Grid>
            <Divider sx={{ border: '0.1px solid #00000012' }} />
            <Grid container direction="row" sx={{ display: 'flex' }}>
                <Grid item mt={0.5} lg={4} md={4} sm={12} xs={12} 
                  sx={{padding:'2%',backgroundColor:'#EEEEFF',borderRadius:'8px',display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                >
              <Typography
                sx={{
                             marginLeft:'5%',
                              fontFamily: 'Rubik',
                              fontWeight: 500,
                              
                              lineHeight: '14px',
                              color: '#3C3C3C',
                              letter: '0.25%',
                              '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
                              '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
                              '@media (max-width: 1024px)': { fontSize: '14px' }, // Standard laptops
                              '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '14px' }, // Larger laptops (1280px or less)
                              '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '16px' }, // Standard desktop monitors
                              '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
                              '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors
                            }}
                          >
                          Time Sheet
              </Typography>
              <img src={TimeSheets} alt="Request an Asset Img" />
            </Grid>
          <Divider 
            sx={{ 
              border: '0.1px solid #00000012', 
              marginLeft: '5%',
              marginTop: '2%' }}
            />
        <Grid item mt={0.5} lg={7} md={7} sm={12} xs={12} 
          sx={{padding:'2%',borderRadius:'4px',marginLeft:'2%',border:'0.5px solid #E89BB0',backgroundColor:'#FFF3F6', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
        <Grid sx={{display:'flex'}} mb={2} lg={12} md={12} xs={12}  sm={12}> 
              <Typography
                sx={{
                  fontFamily: 'Rubik',
                  fontWeight: 500,
                  lineHeight: '14px',
                  color: '#3C3C3C',
                  letter: '0.25%',
                  // Make the font size responsive with viewport width
                  '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
                  '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
                  '@media (max-width: 1024px)': { fontSize: '13px' }, // Standard laptops
                  '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '13px' }, // Larger laptops (1280px or less)
                  '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '13px' }, // Standard desktop monitors
                  '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
                  '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors
                }}
              >
              Upcoming Holiday 
            </Typography>
            <Typography
                sx={{
                  textAlign:'right',
                  marginLeft:'35%',
                  fontFamily: 'Rubik',
                  fontWeight: 500,
                  lineHeight: '14px',
                  color: '#3C3C3C',
                  letter: '0.25%',
                  // Make the font size responsive with viewport width
                  '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
                  '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
                  '@media (max-width: 1024px)': { fontSize: '13px' }, // Standard laptops
                  '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '13px' }, // Larger laptops (1280px or less)
                  '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '13px' }, // Standard desktop monitors
                  '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
                  '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors
                }}
              >
              View All
            </Typography>
        </Grid>

        <Grid  lg={12} md={12} xs={12}  sm={12}> 
          <Typography
          sx={{
            
            fontFamily: 'Rubik',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '14px',
            color: '#3C3C3C',
            letter: '0.25%',
            // Make the font size responsive with viewport width
            '@media (max-width: 1024px)': { fontSize: '14px' },
            '@media (max-width: 600px)': { fontSize: '12px' },
          }}
          
          
          >Navaratri / Dussehra</Typography></Grid>
        <Grid  lg={12} md={12} xs={12}  sm={12}> 
          <Typography
            sx={{
            marginTop:'3%',
            fontFamily: 'Rubik',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '14px',
            color: '#3C3C3C',
            letter: '0.25%',
'@media (max-width: 600px)': { fontSize: '11px' }, // Small devices like phones
'@media (max-width: 768px)': { fontSize: '11px' }, // Tablets and smaller laptops
'@media (max-width: 1024px)': { fontSize: '11px' }, // Standard laptops
'@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '11px' }, // Larger laptops (1280px or less)
'@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '14px' }, // Standard desktop monitors
'@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '16px' }, // Large desktops and some monitors
'@media (min-width: 1921px)': { fontSize: '16px' }, // Ultra-wide and large 4K monitors
          }}
          
          >Fri, 10th Oct - 11th Oct</Typography></Grid>
        </Grid>
          </Grid>
        <Grid display={'flex'} item mt={1} lg={12} md={12} xs={12} >
        <Typography sx={{
            textAlign:'left',
            fontFamily: 'Rubik',
            width:'50%',
            fontWeight: 500,
            // fontSize: '14px',
            color: '#3C3C3C',
            letter: '0.25%',
            marginTop:'1%',
            // Make the font size responsive with viewport width
              '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
              '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
              '@media (max-width: 1024px)': { fontSize: '13px' }, // Standard laptops
              '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '13px' }, // Larger laptops (1280px or less)
              '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '14px' }, // Standard desktop monitors
              '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
              '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors}}
           
            }}>Leave History </Typography>

        <Divider 
            sx={{  marginBottom:'1%',width:'80%',order: '0.1px solid #00000012'}}/>
          </Grid>
        {/* //LEave chart grid */}
        <Grid  container spacing={1} display={'flex'} item  lg={12} md={12} xs={12}>
          <Grid display={'flex'} item  lg={8} md={12} xs={12} sm={12} >
              <Grid display={'flex'}  item  lg={12} md={12} xs={12} >
                <Grid item lg={4} md={6} xs={12} sm={12} >
                  <SickLeaveChart />
                  <Typography
                  sx={{            
                    // Make the font size responsive with viewport width
                    '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
                    '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
                    '@media (max-width: 1024px)': { fontSize: '13px' }, // Standard laptops
                    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '13px' }, // Larger laptops (1280px or less)
                    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '14px' }, // Standard desktop monitors
                    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
                    '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors}}
                  }}
                    > Sick Leave</Typography>
                 
                </Grid>
                <Grid item lg={4} md={6} xs={12} sm={12} >
                   <CasualLeaveChart />
                   <Typography
                  sx={{            
                    // Make the font size responsive with viewport width
                    '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
                    '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
                    '@media (max-width: 1024px)': { fontSize: '13px' }, // Standard laptops
                    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '13px' }, // Larger laptops (1280px or less)
                    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '14px' }, // Standard desktop monitors
                    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
                    '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors}}
                  }}
                    > Casual Leave</Typography>
                </Grid>
                <Grid item  lg={4} md={6} xs={12} sm={12} >
                  <PaidLeaveChart />
                  <Typography
                  sx={{            
                    // Make the font size responsive with viewport width
                    '@media (max-width: 600px)': { fontSize: '12px' }, // Small devices like phones
                    '@media (max-width: 768px)': { fontSize: '12px' }, // Tablets and smaller laptops
                    '@media (max-width: 1024px)': { fontSize: '13px' }, // Standard laptops
                    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '13px' }, // Larger laptops (1280px or less)
                    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '14px' }, // Standard desktop monitors
                    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '18px' }, // Large desktops and some monitors
                    '@media (min-width: 1921px)': { fontSize: '20px' }, // Ultra-wide and large 4K monitors}}
                  }}
                    > Paid Leave</Typography>
                </Grid>
              </Grid>
          </Grid>

        <Grid item mt={1} lg={4} md={12} xs={12} sm={12}>

        <div style={{ }}>
              {/* <Typography
                sx={{
                  
                  fontFamily: 'Rubik',
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#3C3C3C',
                  backgroundColor:'#F2F2F2',
                  textAlign:'center'
              }}
              >Request Leave </Typography> */}
<Typography
    sx={{
      borderRadius: '4px',
      border: '1px solid gray',
      fontFamily: 'Rubik',
      fontWeight: 400,
      color: '#3C3C3C',
      backgroundColor:'#F2F2F2',
      padding: '2px',
      textAlign: 'center',
     
      marginTop: '0%',
      marginLeft: '0%',
      fontSize: '14px',  // Default font size for small screens
      // Media queries for different screen sizes
      '@media (max-width: 600px)': {
        fontSize: '12px'},
      '@media (max-width: 768px)': {
        fontSize: '12px'},
      '@media (max-width: 1024px)': {
        fontSize: '12px'},
      '@media (min-width: 1025px) and (max-width: 1280px)': {
        fontSize: '12px'},
      '@media (min-width: 1281px) and (max-width: 1600px)': {
        fontSize: '14px'},
      '@media (min-width: 1601px) and (max-width: 1920px)': {
        fontSize: '16px'},
      '@media (min-width: 1921px)': {
        fontSize: '18px'},
    }}
  >Request Leave</Typography>
</div>
<div style={{ }}>
  {/* <Typography
    sx={{
    marginTop:'3px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '14px',
    color: '#3C3C3C',
    backgroundColor:'#F2F2F2',
    textAlign:'center'
  }}
  >View Leave History</Typography> */}
  <Typography
    sx={{
      borderRadius: '4px',
      border: '1px solid gray',
      fontFamily: 'Rubik',
      fontWeight: 400,
      color: '#3C3C3C',
      backgroundColor:'#F2F2F2',
      padding: '2px',
      textAlign: 'center',
     marginTop: '4%',
     fontSize: '14px',  // Default font size for small screens
      // Media queries for different screen sizes
      '@media (max-width: 600px)': {
        fontSize: '12px'},
      '@media (max-width: 768px)': {
        fontSize: '12px'},
      '@media (max-width: 1024px)': {
        fontSize: '12px'},
      '@media (min-width: 1025px) and (max-width: 1280px)': {
        fontSize: '12px'},
      '@media (min-width: 1281px) and (max-width: 1600px)': {
        fontSize: '14px'},
      '@media (min-width: 1601px) and (max-width: 1920px)': {
        fontSize: '16px'},
      '@media (min-width: 1921px)': {
        fontSize: '18px'},
    }}
  >View Leave History</Typography>


        </div>

        </Grid>
        </Grid>
        </CardContent>
        </Card>
   {/* Card 5 */}      
<Card  sx={{ marginLeft:'2%',marginTop:'0.5%',overflow: 'auto',
 borderRadius: '8px', border: '2px solid #E6E6E6',
 boxShadow:"E6E6E6 0px 2px 4px 0px, E6E6E6 0px 2px 16px 0px",
 backgroundColor: 'white',
 //  flex: 1
}}>
<CardContent>
  <Typography
            sx={{
              fontFamily: 'Rubik',
              // position: 'relative',
              left: '2% !important',
              fontWeight: 500,
              fontSize: '18px',
              color: '#3C3C3C',
              letter: '0.25%',
      '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
      '@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
      '@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
      '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
      '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
      '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
      '@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
            }}
          >
         My Tickets & Assets
</Typography>
  <Divider sx={{ border: '0.1px solid #00000012' }} />
  <div style={{marginTop:'-30px'}}>
  <MyTicketsChart />
  </div>
<div 
    onClick={()=>{navigate("/view_tickets_asset_details_grid")}}
  
>
  {/* <Typography
  sx={{
    borderRadius: '4px',
    border:'1px solid gray',
    marginLeft:'60%',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '15px',
    color: '#3C3C3C',
    backgroundColor:'#F2F2F2',
    padding:'2px',
    textAlign:'center',
     cursor:'pointer',
  }}
  
  >View Tickets & Assets </Typography> */}

<Typography
    sx={{
      borderRadius: '4px',
      border: '1px solid gray',
      fontFamily: 'Rubik',
      fontWeight: 400,
      color: '#3C3C3C',
      backgroundColor: '#F2F2F2',
      padding: '2px',
      textAlign: 'center',
      cursor: 'pointer',
      // Default styles
      marginTop: '0%',
      marginLeft: '0%',
      // width: '100%',
      fontSize: '14px',  // Default font size for small screens
      // Media queries for different screen sizes
      '@media (max-width: 600px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 768px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 1024px)': {
        fontSize: '16px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (min-width: 1025px) and (max-width: 1280px)': {
        fontSize: '15px',
        marginTop: '-7%',
        marginLeft: '40%',
      },
      '@media (min-width: 1281px) and (max-width: 1600px)': {
        fontSize: '16px',
        marginTop: '-7%',
        marginLeft: '40%',
      },
      '@media (min-width: 1601px) and (max-width: 1920px)': {
        fontSize: '22px',
        marginTop: '-2%',
        marginLeft: '60%',
      },
      '@media (min-width: 1921px)': {
        fontSize: '24px',
        marginTop: '-1%',
        marginLeft: '60%',
      },
    }}
  >
    View Tickets & Assets 
</Typography>
  </div>
 </CardContent>
</Card>
                   
{/* Card 6 */}
<Card  sx={{ marginLeft:'2%',marginTop:'0.5%',overflow: 'auto',
 borderRadius: '8px', border: '2px solid #E6E6E6',
 backgroundColor: 'white',boxShadow:"E6E6E6 0px 2px 4px 0px, E6E6E6 0px 2px 16px 0px"
 
}}>
<CardContent>
    <Typography
    sx={{
      fontFamily: 'Rubik',
      // position: 'relative',
      left: '2% !important',
      fontWeight: 500,
      fontSize: '18px',
      color: '#3C3C3C',
      letter: '0.25%',
    '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
    '@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
    '@media (max-width: 1024px)': { fontSize: '16px' }, // Standard laptops
    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '18px' }, // Larger laptops (1280px or less)
    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '20px' }, // Standard desktop monitors
    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '22px' }, // Large desktops and some monitors
    '@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
    }}
    > 
      Hire Request
    </Typography>
    <Divider sx={{ border: '0.1px solid #00000012' }} />
    <div style={{marginTop:'-30px'}}>
    <MyHireRequestsChart />
    </div> 
    <div 
     onClick={()=>{navigate("/view_hireRequestdetails_grid")}}
  
     >
    {/* <Typography
      sx={{
        borderRadius: '4px',
        border:'1px solid gray',
        marginLeft:'60%',
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: '15px',
        color: '#3C3C3C',
        backgroundColor:'#F2F2F2',
        padding:'2px',
        textAlign:'center',
       cursor:'pointer',
          
       
  }}
  
  >View Request History</Typography> */}

<Typography
    sx={{
      borderRadius: '4px',
      border: '1px solid gray',
      fontFamily: 'Rubik',
      fontWeight: 400,
      color: '#3C3C3C',
      backgroundColor: '#F2F2F2',
      padding: '2px',
      textAlign: 'center',
      cursor: 'pointer',
      // Default styles
      marginTop: '0%',
      marginLeft: '0%',
      // width: '100%',
      fontSize: '14px',  // Default font size for small screens
      // Media queries for different screen sizes
      '@media (max-width: 600px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 768px)': {
        fontSize: '14px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (max-width: 1024px)': {
        fontSize: '16px',
        marginTop: '1%',
        marginLeft: '0%',
      },
      '@media (min-width: 1025px) and (max-width: 1280px)': {
        fontSize: '15px',
        marginTop: '-7%',
        marginLeft: '40%',
      },
      '@media (min-width: 1281px) and (max-width: 1600px)': {
        fontSize: '16px',
        marginTop: '-9%',
        marginLeft: '40%',
      },
      '@media (min-width: 1601px) and (max-width: 1920px)': {
        fontSize: '22px',
        marginTop: '-4%',
        marginLeft: '60%',
      },
      '@media (min-width: 1921px)': {
        fontSize: '24px',
        marginTop: '-1%',
        marginLeft: '60%',
      },
    }}
  >
   View Request History
</Typography>
  </div>
  </CardContent>
  </Card>   
    </Grid>   
</Grid>
  </Box>

</MainContainerWrapper>
)
 
}

export default HomePage;