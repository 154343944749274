//SOP-102 Included by PCS [Uploading atatchment]

import { useState, useRef, useEffect, useCallback } from 'react';
import React from 'react';
import { Box, Button, Typography,Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // CSS for PDF rendering
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import ArticleIcon from '@mui/icons-material/Article';
import Swal from "sweetalert2";

function UploadDocument() {
  const { title } = useParams<{ title?: string }>(); 
  const navigate = useNavigate();

// Global data from session storage
   const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
   const GlobalData = JSON.parse(GlobalDataFromSession);
   let CompanyId = GlobalData.GlobalCompanyId
   const UserId = GlobalData.GlobalUserId;
   let EmployeeId = GlobalData.GlobalEmployeeId
   let UserName = GlobalData.GlobalUserName

//Use state declaartion
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState('');
  const [fileType, setFileType] = useState('');
  const [imageSrclink, setImageSrclink] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [fileName, setFileName] = useState('');

//To remove uploaded attachment
   const handleRemoveFile = () => {
    setFile(null);          // Reset the file
    setFileName("");        // Clear the file name
    setFileURL("");         // Clear the preview URL
    setFileType("");        // Clear the file type
    setImageSrclink('')
  };

// Handle file upload and preview
const handleFileUpload = (event:any) => 
  {
      const uploadedFile = event.target.files[0];   
      const maxSize = 3 * 1024 * 1024; // 5MB in bytes

      if (uploadedFile && uploadedFile.size > maxSize) {
         Swal.fire({
            html: '<span style="font-size: 1.3rem;">File size exceeds the 2MB limit. Please upload a smaller file</span>',
            confirmButtonText: 'OK',
            });
          event.target.value = null; // Reset the input to avoid submitting the large file
          return; 
      }
      if (uploadedFile) 
      {
          setFileName(uploadedFile.name);
          // Set file URL for preview
          const fileUrl = URL.createObjectURL(uploadedFile);
          setFile(uploadedFile);
          setFileURL(fileUrl);
          
          // Determine file type for conditional rendering
          if (uploadedFile.type.startsWith('image/')) 
          {
              const reader = new FileReader();
              reader.onloadend = () => 
              {
               const imageDataUrl = reader.result;
               setImageSrclink(String(imageDataUrl))
               setImageUrl(imageDataUrl); 
              };
              reader.readAsDataURL(uploadedFile);
              setFileType('image');
          } 
        else if (uploadedFile.type === 'application/pdf') 
          {
            const reader = new FileReader();
            reader.onloadend = () => 
            {
             const imageDataUrl = reader.result;
             setImageSrclink(String(imageDataUrl))
             setImageUrl(imageDataUrl); 
            };
            reader.readAsDataURL(uploadedFile);
            setFileType('pdf');
        }
    }
};

// Handle form submission 
  const handleSubmit =async (event:any)=>
  {
      event.preventDefault()
      // Check if the file is empty
        if (!imageSrclink) {
          Swal.fire({
                html: '<span style="font-size: 1.3rem;">Please select a file to upload</span>',
                confirmButtonText: 'OK',
                });
              return; 
        }
        const PayloadCreateDocPoint={
                SaveUpdateKey:'create',
                CompanyId:CompanyId,
                DocumentId:null,
                SequenceNumber:null,
                TitleName:title,
                VersionNumber:'',
                EffectiveFrom:null,
                CategoryId:null,
                AuthorId:UserId,
                isCancelled:null,
                CreatedBy:UserId,
                CreatedDateTime:null,
                lastModifiedBy:null,
                lastModifiedDate:null,
                Content:'' ,
                DocumentType:2,
                UploadDocument:imageSrclink
                             
            }
              commonService
              .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/docpoint_document_create_update_delete`, PayloadCreateDocPoint)
              .then((res) => {
                if (res.status === 200) {
                    Success("Document attached successfully");
                    navigate('/docpoint')
                }
                }).catch((error) => {
                  Failed("Document attachment failed ");
                    console.error("Error:", error);
                });
     
    }

 return (
  <MainContainerWrapper headingText="">
  <form onSubmit={handleSubmit} style={{marginTop:'-3%'}}>
  <div style={{justifyContent: 'center',alignItems: 'center'}}>
  <div style={{
      width: '100%',
      maxWidth: '100%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
         
  }}>
  <Typography variant='h3'
       sx={{
        // color:'cornflowerblue',
        color:'steelblue',
        textAlign: 'center',
         marginTop:'1%',
        "@media (max-width: 600px)": { fontSize: "19px" },
        "@media (max-width: 768px)": { fontSize: "20px" },
        "@media (max-width: 1024px)": { fontSize: "21px" },
        "@media (min-width: 1025px) and (max-width: 1280px)": { fontSize: "24px" },
        "@media (min-width: 1281px) and (max-width: 1600px)": { fontSize: "26px" },
        "@media (min-width: 1601px) and (max-width: 1920px)": { fontSize: "27px" },
        "@media (min-width: 1921px)": { fontSize: "29px" },
        
  }}>{title}</Typography>
  <Typography variant='h3'
    sx={{
        fontWeight: '600',
        color:'#333',
        marginBottom: '20px',
        marginLeft:'4%',
        marginTop:'1%',
        "@media (max-width: 600px)": { fontSize: "19px" },
        "@media (max-width: 768px)": { fontSize: "20px" },
        "@media (max-width: 1024px)": { fontSize: "21px" },
        "@media (min-width: 1025px) and (max-width: 1280px)": { fontSize: "24px" },
        "@media (min-width: 1281px) and (max-width: 1600px)": { fontSize: "26px" },
        "@media (min-width: 1601px) and (max-width: 1920px)": { fontSize: "27px" },
        "@media (min-width: 1921px)": { fontSize: "29px" },
      }}>Upload Image or PDF</Typography>
{/* ----------File Upload Form----------- */}
<div onSubmit={handleSubmit}
 style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft:'4%'
  }}>
<div style={{ display: 'flex', width: '80%' }}>
<div 
style={{width:'35%',color:'darkcyan'}}>
<input
type="file"
  accept="image/*,application/pdf"
  onChange={handleFileUpload}
  style={{
    display: 'none', // Hide the default file input
  }}
  id="fileUploadInput"
/>
<label 
  htmlFor="fileUploadInput" 
  style={{
    padding: '8px 20px',
    fontSize: '14px',
    backgroundColor: 'lightblue', 
    color: 'darkcyan',
    border: '2px solid gainsboro',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 0.3s, border-color 0.3s',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }}>
  <ArticleIcon sx={{ }}></ArticleIcon>
  <Typography sx={{ 
    color: 'darkcyan',
    "@media (max-width: 600px)": { fontSize: "12px" },
    "@media (max-width: 768px)": { fontSize: "12px" },
    "@media (max-width: 1024px)": { fontSize: "12px" },
    "@media (min-width: 1025px) and (max-width: 1280px)": { fontSize: "16px" },
    "@media (min-width: 1281px) and (max-width: 1600px)": { fontSize: "18px" },
    "@media (min-width: 1601px) and (max-width: 1920px)": { fontSize: "19px" },
    "@media (min-width: 1921px)": { fontSize: "19px" },
    '&:hover':{
      color:'#fff'
  }
    
    
    }}>Choose a file</Typography>
</label>
</div>
{/*-------------- Display selected file name next to the button----------- */}
  <div style={{ marginLeft: '0.5%',marginTop:'1%',
      fontSize: '16px', 
      color: '#333' }}>
      {fileName && <span>{fileName}</span>}
    </div>
{/*-------------- Remove selected file---------- */}
  <div style={{ marginLeft: '0.5%',marginTop:'1%'}}>
      {fileName && (
        <CloseIcon
          style={{ cursor: 'pointer',color: 'red'}}
          onClick={handleRemoveFile}
        />
      )}
  </div>
</div>
</div>
<Divider sx={{mt:3, border:'0.5px solid grey', ml:7,mr:7}}/>
{/* -----------Preview Section ----------*/}
<Box className="preview-container" style={{ marginLeft:'4%',marginRight:'4%',marginTop: '3%' ,marginBottom: '1%' }}>

{fileType === 'image' && file && (
<div id="imageSection" className="image-preview">
    <img src={fileURL} alt="Preview" style={{maxWidth: '100%', height: 'auto' }} />
</div>
)}

{fileType === 'pdf' && file && (
<div id="pdfSection" className="pdf-preview">
    <iframe src={fileURL} title="PDF Preview" style={{ width: '100%', height: '500px' }} />
</div>
)}

</Box>
{/* ----------Preview Section ends--------- */}
{/* --------No FILE selected ----------*/}
      {!fileName && (
      <div className="preview-container" style={{
              marginTop: '5%',
              height:'30vh'
            }}>

        <div>
          <Typography
          sx={{
            fontWeight: '600',
            color:'steelblue ',
            marginBottom: '20px',
            marginLeft:'4%',
            marginTop:'1%',
            textAlign:'center',
            "@media (max-width: 600px)": { fontSize: "19px" },
            "@media (max-width: 768px)": { fontSize: "20px" },
            "@media (max-width: 1024px)": { fontSize: "21px" },
            "@media (min-width: 1025px) and (max-width: 1280px)": { fontSize: "24px" },
            "@media (min-width: 1281px) and (max-width: 1600px)": { fontSize: "26px" },
            "@media (min-width: 1601px) and (max-width: 1920px)": { fontSize: "27px" },
            "@media (min-width: 1921px)": { fontSize: "29px" },

          }}
          
          >
        No Files Selected
          </Typography>
        </div>
        </div>
          )}
{/* -----------No FILE selected--------- */}
</div>
</div>
{/*---------------- Back & Save -----------*/}
<Box mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
<Button
    sx={{
      "@media (max-width: 600px)": { fontSize: "13px",padding:'0 30px' },
      "@media (max-width: 768px)": { fontSize: "14px" ,padding:'0 35px' },
      "@media (max-width: 1024px)": { fontSize: "15px" ,padding:'0 35px' },
      "@media (min-width: 1025px) and (max-width: 1280px)": { fontSize: "16px",padding:'0 45px'  },
      "@media (min-width: 1281px) and (max-width: 1600px)": { fontSize: "17px",padding:' 0 50px'  },
      "@media (min-width: 1601px) and (max-width: 1920px)": { fontSize: "18px" ,padding:'0 55px' },
      "@media (min-width: 1921px)": { fontSize: "19px",padding:'0 60px'  },
        background: 'white',
        border: 'none',
        borderRadius: '5px',
        height: "3rem",
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
  }}
  onClick={() => {
  navigate('/docpoint')
    }}
  >
   Back
  </Button>
  <Button
      type="submit"
      sx={{
        "@media (max-width: 600px)": { fontSize: "13px",padding:'0 30px' },
        "@media (max-width: 768px)": { fontSize: "14px" ,padding:'0 35px' },
        "@media (max-width: 1024px)": { fontSize: "15px" ,padding:'0 35px' },
        "@media (min-width: 1025px) and (max-width: 1280px)": { fontSize: "16px",padding:'0 45px'  },
        "@media (min-width: 1281px) and (max-width: 1600px)": { fontSize: "17px",padding:' 0 50px'  },
        "@media (min-width: 1601px) and (max-width: 1920px)": { fontSize: "18px" ,padding:'0 55px' },
        "@media (min-width: 1921px)": { fontSize: "19px",padding:'0 60px'  },
      background: '#25425F',
      border: 'none',
      borderRadius: '5px',
      "&:hover": {
      backgroundColor: '#25425F',
      color: 'white',
           },
           color: 'white',
           height: "3rem",
           boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
       }}
      >
    Submit
    </Button>
    </Box>
{/*---------------- Back & Save ends-----------*/}
</form> 
</MainContainerWrapper>
);}

export default UploadDocument;
//EOF SOP-102 Included by PCS [Uploading atatchment]






