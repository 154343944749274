//SOP-101 Included by PCS [View Hire request Grid details - Home page]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Dialog,
    Card
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import requestAsset from '../../asset/images/requestAsset1.png';
import raiseTicket from '../../asset/images/raiseTicket.png'
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';
import dayjs from 'dayjs';

const ViewHireRequestGridDetails =()=>{
   
// {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - HR Grid - start */}- TS - start 
    let  [HRHireRequestorder, setHRHireRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [HRHireRequestorderBy, setHRHireRequestOrderBy] = React.useState("");
    let  [HRHireRequesttableData, setHRHireRequestTableData] = React.useState([]);
    const [HRHireRequestpage, setHRHireRequestPage] = React.useState<number>(0);
    const [HRHireRequestcount, setHRHireRequestCount] = useState<number>(0);
    const [HRHireRequestrowsPerPage, setHRHireRequestsetRowsPerPage] = React.useState<number>(5);
// (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - TS - end
    
const navigate = useNavigate();

// To set action type for edit ad delete
        let actionType;

// Head cell of My Property
const HRHireRequestHeadCell = [
   
    {
        id: "hirerequest_hiring_jobcode_title",
        numeric: false,
        disablePadding: true,
        label: "Job Profile",
        isSort: true,
        isBold: true,
    },
    {
        id: "hirerequest_total_open_position",
        numeric: false,
        disablePadding: true,
        label: "Total Position",
        isSort: true,
        isBold: true,
    },
    {
        id: "departmentname",
        numeric: false,
        disablePadding: true,
        label: "Department",
        isSort: true,
        isBold: true,
    },
    {
        id: "employee_name",
        numeric: false,
        disablePadding: true,
        label: "Hiring Manager",
        isSort: true,
        isBold: true,
    },
    {
        id: "sys_hr_employment_type_name",
        numeric: false,
        disablePadding: true,
        label: "Type of Employment",
        isSort: true,
        isBold: true,
    },
    {
        id: "sys_hr_hirerequest_status_name",
        numeric: false,
        disablePadding: true,
        label: "Current Status",
        isSort: true,
        isBold: true,
    },
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
      },
    ];

//SOP-92 Included By PCS [Including search functionality]
const [cells_HireRequest, setcells_HireRequest] = useState<any>(HRHireRequestHeadCell);//Hire Requests grid
//EOF SOP-92 Included By PCS [Including search functionality]
 
//  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId
  
    const getHRHireRequestList = () => 
          {      
  
          //SOP-92 Included by PCS [search functionality in 'Hire Request' grid]
              // Initializing Variable 
  
              let JobProfile = null;
              let TotalPosition = null;
              let Department = null;
              let HiringManager = null;
              let TypeOfEmployment = null;
              let CurrentStatus = null;
       
              for (const item of cells_HireRequest)
               {
                  if (item.id === "hirerequest_hiring_jobcode_title") {
                      JobProfile = item.value;
                  }
  
                 if (item.id === "hirerequest_total_open_position") {
                  TotalPosition = item.value;
                  }
  
                  if (item.id === "departmentname") {
                  Department = item.value;
                   }
  
                 if (item.id === "employee_name") {
                  HiringManager = item.value;
                  }
  
                 if (item.id === "sys_hr_employment_type_name") {
                  TypeOfEmployment = item.value;
                  }
  
                 if (item.id === "sys_hr_hirerequest_status_name") {
                  CurrentStatus = item.value;
                  }
             }
          //EOF SOP-92 Included by PCS [search functionality 'Hire Request'  grid]
              
  
          commonService
          //SOP-86 Modified by PCS [to display only Hire request for that employee]
              // .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hr_hire_requestlist`, {
              .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hr_hire_requestlist_for_employee`, {
          //EOF SOP-86 Modified by PCS [to display only Hire request for that employee]
                   //SOP-92 Inlcuded By PCS [To include search functionality in 'Hire request' Grid]
                   SearchJobProfile: JobProfile,
                   SearchTotalPosition: TotalPosition,
                   SearchDepartment: Department,
                   SearchHiringManager: HiringManager,
                   SearchTypeofEmployment:TypeOfEmployment,
                   SearchCurrentStatus: CurrentStatus,
                   //EOF SOP-92 Included By PCS [To include search functionality in 'Hire request' Grid]
                   SortColumnName: HRHireRequestorderBy,
                  SortColumnDirection: HRHireRequestorder,
                  StartIndex: HRHireRequestpage + 1,
                  PageSize: HRHireRequestrowsPerPage,
                  CompanyId:CompanyId,
                  CreatedBy:UserId,
              })
              .then((res: any) => 
              {
             
              const formattedTableData= res.data.data.map((item:any)=>{
              return {...item,
                  id:item.hirerequestid,//SOP-86 Included By PCS[to get hire request id from grid]
                  hirerequest_start_date: item.hirerequest_start_date ? dayjs(item.hirerequest_start_date).format("DD-MM-YYYY") : "",
              }
      
              }) 
              // console.log("formattedTableData111",formattedTableData)
              setHRHireRequestTableData(formattedTableData);
              if(res.data.data[0]===undefined)
              {
                  setHRHireRequestCount(0);
              }
              else
              {
                  setHRHireRequestCount(res.data.data[0].total_count);
              }
              })
          .catch((error) => 
              {
              if (error.response.status === 404)
              {
                  setHRHireRequestTableData([]);
              }
              Failed(error.response.message);
              });
      };


//My Assets Grid's function
      
//Hire Request Grid's function
// handle Sort
const handleHRHireRequestIncidentRequestSort = (event: any, property: any) =>
    {
    const isAsc = HRHireRequestorderBy === property && HRHireRequestorder === "asc";
    HRHireRequestorder = isAsc ? "desc" : "asc";
    setHRHireRequestOrder(HRHireRequestorder);
    HRHireRequestorderBy = property;
    setHRHireRequestOrderBy(HRHireRequestorderBy);
};
        
// RequestactionFunction
const HRHireRequestactionFunction = (id: string, actionText: string) => 
    {
        //SOP-86 Uncommented & modified  by PCS [to enable view ICON]
            if (actionText === "view") navigate(`/hr_view_hire_request/${id}`);
        //EOF SOP-86 Uncommented & modified  by PCS [to enable view ICON]
        // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
    };
    
// Pagination Handler of table
const HRHireRequesthandleChangePage = (event: unknown, newPage: number) => {
    setHRHireRequestPage(newPage);
    };
    
//SOP-92 Included by PCS [search function in 'Hire Requests' Grid]
    // Search Handler of table
    const handleSearchHireRequests = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = [...cells_HireRequest];
        const index = cells_HireRequest.findIndex((c: any) => c.id === e.target.name);
        if (index !== -1) {
        search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
        }
        setcells_HireRequest(search);
        HRHireRequesthandleChangePage(setHRHireRequestPage,0);
        getHRHireRequestList();
    };
//EOF SOP-92 Included by PCS [search function in 'Hire Requests' Grid]
    
// Rows Per page Handler
const HRHireRequesthandleChangeRowsPerPage = (
event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setHRHireRequestsetRowsPerPage(+event.target.value);
        setHRHireRequestPage(0);
};

// (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - end
// Sort, Action function,Pagination, Per page Handler - end
//EOF Hire Request Grid's function
       
//EOF My Assets Grid's function

//UseEffect
   useEffect(() => {
         getHRHireRequestList();  //  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - API 
     }, [
        //  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager
        HRHireRequestorder,HRHireRequestorderBy,HRHireRequestpage,HRHireRequestrowsPerPage
       ]); 
  
return(
    <>
    <MainContainerWrapper 
    headingText="Hire Request "
    isSearch={false}
    >
    <Box>
        <Box bgcolor={'white'}>
        <Box>
            <EnhancedTable
               headCells={HRHireRequestHeadCell}
               rows={HRHireRequesttableData}
               rowsPerPage={HRHireRequestrowsPerPage}
               page={HRHireRequestpage}
               handleChangePage={HRHireRequesthandleChangePage}
               handleChangeRowsPerPage={HRHireRequesthandleChangeRowsPerPage}
               handleRequestSort={handleHRHireRequestIncidentRequestSort}
               handleSearch={handleSearchHireRequests}//SOP-92 Included By PCS [search in 'Hire Requests' grid]
               order={HRHireRequestorder}
               orderBy={HRHireRequestorderBy}
               actionFunction={HRHireRequestactionFunction}
               isView={true}
               isDelete={false}
               isEdit={false}
               total={HRHireRequestcount}
               //SOP-92 Modified By PCS [to enable search function]
               // isSearch={false}
               isSearch={true}
               //EOF SOP-92 Modified By PCS
               HoverColor={'#E7F1FB'}
            />
        </Box>
        </Box>
    </Box>
        <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
        <Button
        sx={{
            background: 'white',
            border: 'none',
            borderRadius: '5px',
            padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px'

            },
            height: "3rem",
            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
        }}
        onClick={()=>{
        navigate('/home')
        }} 
        >
        Back
        </Button>
        </Box>
</MainContainerWrapper>
</>) }

export default ViewHireRequestGridDetails;

//EOF SOP-101 Included by PCS [View Hire request Grid details - Home page]