// SOP-92 Included By PCS [View Asset Request details By Id]
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,   
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const ViewAssetRequest =()=>  {

const navigate = useNavigate();
const {id} = useParams();
const AssetRequestId = id
    
//Declaring Use State..
   const [RequestDate, setRequestDate] = useState<dayjs.Dayjs | null>(null);
   const [ApprovedDate, setApprovedDate] = useState<dayjs.Dayjs | null>(null);
   const [AllocatedDate, setAllocatedDate] = useState<dayjs.Dayjs | null>(null);
   const [RequestIdValue, setRequestIdValue]=useState('')
   const [RequestNumberValue, setRequestNumberValue]=useState('')
   const [AssetNameValue, setAssetNameValue]=useState('')
   const [EmployeeNameValue, setEmployeeNameValue]=useState('')
   const [RequestedByValue, setRequestedByValue]=useState('')
   const [RequestRemarksValue, setRequestRemarksValue]=useState('')
   const [ApproveStatusValue, setApproveStatusValue]=useState('')
   const [ApprovedByValue, setApprovedByValue]=useState('')
   const [ApprovedRemarksValue, setApprovedRemarksValue]=useState('')
   const [AlllocatedStatusValue, setAllcoatedStatusValue]=useState('')
   const [AllocatedByValue, setAllocatedByValue]=useState('')
   const [AllocatedRemarksValue, setAllocatedRemarksValue]=useState('')
   const [CategoryValue, setCategoryValue]=useState('')
   const [SubCategoryValue, setSubCategoryValue]=useState('')

//EOF UseState
 
   // Global data from session storage
      const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
      const GlobalData = JSON.parse(GlobalDataFromSession);
      let CompanyId = GlobalData.GlobalCompanyId
      let CreatedBy = GlobalData.GlobalUserId 

  // To get Asset request details by Asset Request Id..
      const GetAssetRequestById =()=>
         {

           

            commonService .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/view_asset_request_by_id`, {
            CompanyId:CompanyId,
            AssetRequestId:AssetRequestId,
            })
            .then((res: any) => 
            { 
            const AssetRequestData = res.data.data[0]
            //  console.log("AssetRequestData ..",AssetRequestData)
            setRequestDate(AssetRequestData.asset_date)
            setApprovedDate(AssetRequestData.request_approve_date)
            setAllocatedDate(AssetRequestData.allocate_date)
            setRequestIdValue(AssetRequestData.requestid)
            setRequestNumberValue(AssetRequestData.request_number)
            setAssetNameValue(AssetRequestData.assetname)
            setEmployeeNameValue(AssetRequestData.requestedfor) 
            setRequestedByValue(AssetRequestData.requestedby)
            setRequestRemarksValue(AssetRequestData.request_remarks)
            setApproveStatusValue(AssetRequestData.approvestatus)
            setApprovedByValue(AssetRequestData.approvedby)
            setApprovedRemarksValue(AssetRequestData.request_approve_remarks)
            setAllcoatedStatusValue(AssetRequestData.allocationstatus)
            setAllocatedByValue(AssetRequestData.allocatedby)
            setAllocatedRemarksValue(AssetRequestData.allocate_remarks)
            setCategoryValue(AssetRequestData.categoryname||"")
            setSubCategoryValue(AssetRequestData.subcategoryname||"")
   
         })
         }
            
   // UseEffect 
      useEffect(() => {
         GetAssetRequestById();
      }, []);

return(
   <>
   <MainContainerWrapper
   headingText='View Asset Request'>
   <Box>
   <form noValidate>      
   {/* Asset Request Information Accordian - start*/}
   <Box>
   <Accordion defaultExpanded sx={{borderRadius:'30px'}}>
   <AccordionSummary
      expandIcon={<ArrowDropDownIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
   >
   <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
   <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
   <Typography fontSize={'1rem'} ml={2}>Asset Information</Typography> 
   </Box>
   </AccordionSummary>
   <AccordionDetails>
   <Box  
      sx={{ml:{
         xl:12,
         lg:5,
         md:0,
         xs:0
         },
         mr:{
         xl:12,
         lg:5,
         md:0,
         xs:0
         },
      color: 'red'
      }}  >
   {/* ----------------1st row------------- */}
   {/* SOP-92_A Testing feedback correction [text field readonly is modified to disabled ] */}
      <Grid container  sx={{ color: 'darkblue' }} mb={5} spacing={5} display={'flex'} >
            <Grid item lg={4} md={6} xs={12} >
            <TextField
             //SOP-92_A [modified by PCS]
               // Readonly
                 disabled
            //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Request Id'
               name='Request Id'
               fullWidth
               value={RequestIdValue}
              
               /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
            <TextField
               //SOP-92_A [modified by PCS]
               // Readonly
                  disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Request Number'
               name='Request Number'
               fullWidth
               value={RequestNumberValue}
            /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <FormControl fullWidth>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DatePicker
               //SOP-92_A  [modified by PCS]
               // readOnly
               disabled
               //EOF SOP-92_A [modified by PCS]
               label=" Asset Request Date"
               value={RequestDate ? dayjs(RequestDate) : null}
               format="DD-MM-YYYY"
               slotProps={{
                  textField: {required: true}
                          }}
               />                                                         
               </LocalizationProvider>
               </FormControl>
            </Grid>
   {/* ----------------2nd row------------- */}
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A  [modified by PCS]
               // readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Category Name'
               name= 'Category Name'
               fullWidth
               value={CategoryValue}
               /> 
     
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A [modified by PCS]
               // readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Sub Category Name'
               name='Sub Category Name'
               fullWidth
               value={SubCategoryValue}
               /> 
            </Grid>                                
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A  [modified by PCS]
               // readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Asset Name'
               name='Asset Name'
               fullWidth
               value={AssetNameValue}
               /> 
            </Grid>
   {/* ----------------3rd row------------- */} 
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Asset Requested for'
               name='Asset Requested for'
               fullWidth
               value={EmployeeNameValue}
               /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A  [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Requested By'
               name='Requested By'
               fullWidth
               value={RequestedByValue}
               /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A  [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Request Remarks'
               name='Request Remarks'
               fullWidth
               multiline
               value={RequestRemarksValue}
               /> 
            </Grid>
   {/* ----------------4th row------------- */}  
           <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A  [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Approve Status'
               name='Approve Status'
               fullWidth
               value={ApproveStatusValue}
               /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A  [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Approve by'
               name='Approve by'
               fullWidth
               value={ApprovedByValue}
               /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
            <FormControl fullWidth>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DatePicker
               //SOP-92_A  [modified by PCS]
               // readOnly
               disabled
               //EOF SOP-92_A [modified by PCS]
               label="Approve Date"
               value={ApprovedDate ? dayjs(ApprovedDate) : null}
               format="DD-MM-YYYY"
               slotProps={{
                  textField: {required: true}
                          }}
               />                                                       
               </LocalizationProvider>
               </FormControl>
            </Grid>
   {/* ----------------5th row------------- */}  
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A  [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Approve Remarks '
               name='Approve Remarks '
               fullWidth
               multiline
               value={ApprovedRemarksValue}
               /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Allocation Status '
               name='Allocation Status'
               fullWidth
               value={AlllocatedStatusValue}
               /> 
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <TextField
              //SOP-92_A [modified by PCS]
               // Readonly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Allocation by '
               name='Allocation by'
               fullWidth
               value={AllocatedByValue}
               /> 
            </Grid>
   {/* ----------------6th row------------- */}  
            <Grid item lg={4} md={6} xs={12} >
            <FormControl fullWidth>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DatePicker
               //SOP-92_A [modified by PCS]
               // readOnly
               disabled
               //EOF SOP-92_A [modified by PCS]
               
               label="Allocation Date"
               value={AllocatedDate ? dayjs(AllocatedDate) : null}
               format="DD-MM-YYYY"
               slotProps={{
                  textField: {required: true}
                          }}
               />                                                        
               </LocalizationProvider>
               </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12} >
               <TextField
               //SOP-92_A [modified by PCS]
               //readOnly
               disabled
               //EOF SOP-92_A [modified by PCS]
               type='text'
               variant="standard"
               label='Allocation Remarks '
               name='Allocation Remarks '
               fullWidth
               multiline
               value={AllocatedRemarksValue}
               /> 
            </Grid>
      </Grid>
   </Box>
   </AccordionDetails>
   </Accordion>
   </Box>
{/* EOF  Asset Request Information Accordian - start*/} 
{/* Button start */}
<Box>                    
<Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
   <Button
   sx={{
      background: 'white',
      border: 'none',
      borderRadius: '5px',
      padding:{
      lg: "0 70px",
      md: "0 70",
      xs:'0 50px'
      },
      height: "3rem",
      boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
   }}
   onClick={()=>{
  //SOP-101 modified by PCS [Existing page is modified,so abck naviagtion is modified]
   // navigate('/home')
   navigate('/view_assetrequest_details_grid')
   //EOF SOP-101 modified by PCS [Existing page is modified,so abck naviagtion is modified]

   }} 
>
Back
</Button>
</Box>
</Box>
</form>
</Box>
</MainContainerWrapper>
</>
)
}
export default ViewAssetRequest;
// EOF SOP-92 Included By PCS [View Asset Request details By Id]