import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, Typography } from "@mui/material";

// project imports
import ProfileSection from "./ProfileSection";

// assets
import { IconMenu2 } from "@tabler/icons";
import LogoSection from "../../../../atic-common-helpers/layout/MainLayout/LogoSection";
import { useEffect } from "react";
import { json } from "stream/consumers";
import { margin } from "@mui/system";

//SOP-101 Included By PCS [Revamping of Homepage]
import NavBarCollapse from "../../../../asset/images/NavBarCollapse.png"
import NavBarExpand from "../../../../asset/images/NavBarExpand.png"
import { useState } from 'react'; // Import useState hook
//EOF SOP-101 Included By PCS [Revamping of Homepage]

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }: HeaderTypes) => {

//SOP-101 Included by PCS [revamping of header]
    // Track the drawer state (open or closed)
    const [drawerOpen, setDrawerOpen] = useState(false);

    //Toggle the drawer state
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
        handleLeftDrawerToggle();
    };
//EOF SOP-101 Included by PCS [revamping of header]

// Included by sanjana SOP-46
    const CompanyName: any = sessionStorage.getItem("GlobalVariable");
    const singedPerson = JSON.parse(CompanyName);
    // console.log("singedPerson",singedPerson)
// End of SOP-46

  const theme = useTheme();
  return (
    <>
    {/* logo & toggler button */}
    <Box
        sx={{
            display: 'flex',
            width: '100%', // SOP-101 Included by PCS
            height: '100%', // SOP-101 Included by PCS
            [theme.breakpoints.down('md')]: {
            width: 'auto'
            } ,
        }}
    >
    <Box>
    <LogoSection /> 
    </Box>
    {/* //SOP-101 Commented by PCS */}
       {/* <ButtonBase disableRipple sx={{ borderRadius: '12px', overflow: 'hidden' }}>
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography?.commonAvatar,
                    ...theme.typography?.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: 'transparent',
                    color:theme.palette.primary.buttonColor,
                    '&:hover': {
                        background: theme.palette.primary.buttonColor ,
                        color:"white"
                    },

                     marginLeft:"4rem"
                }}
                onClick={handleLeftDrawerToggle}
                color="inherit"
            >
                <IconMenu2 stroke={1.5} size="1.8rem" />
                
            </Avatar> 
        </ButtonBase> */}
    {/* //EOF SOP-101 Commented by PCS */}

    {/* //SOP-101 Included by PCS [icon included for menu open/close]*/}
        <img
            style={{ marginLeft:"4rem"}}
            color="inherit"
            onClick={toggleDrawer} 
            src={drawerOpen ? NavBarExpand : NavBarCollapse }// Show NavBarExpand when drawer is open, otherwise NavBarCollapse
            alt="Toggle Drawer"
        />
   {/* //EOF SOP-101 Included by PCS [icon included for menu open/close]*/}
    </Box>

    {/* Included by sanjana SOP-46 to add company name */}
            {/* //SOP-75 feedback points L1 modified By PCS */}
            {/* <Box sx={{ flexGrow: 15 }} display={'flex'} justifyContent={'center'}></Box> */}
            <Box sx={{ flexGrow: 15 }} display={'flex'} ml={1.5} justifyContent={'left'}>
            {/* //EOF SOP-75 feedback points L1 modified By PCS */}

    {/* //SOP-101 commented by PCS */}
    {/* <h1> {singedPerson.GlobalCompanyNAme} </h1> */}
    {/* //EOF SOP-101 commented by PCS */}

    </Box>
    {/* End of SOP-46 */}

    {/* header search */}
    <Box sx={{ flexGrow: 1 }} />
    {/* <Box sx={{ flexGrow: 1 }} /> */}
    

    {/* notification & profile */}
    <ProfileSection />
</>
  );
};

type HeaderTypes = {
  handleLeftDrawerToggle: () => void;
};

export default Header;
