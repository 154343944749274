//SOP-101 Included by PCS //EOF SOP-101 Included by PCS [View Tickets &  Asset requests Grid details - Home page]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Dialog,
    Card
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import requestAsset from '../../asset/images/requestAsset1.png';
import raiseTicket from '../../asset/images/raiseTicket.png'
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';
import dayjs from 'dayjs';

// (SOP-64) Asset Management - Home Page - New hire Request - TS - start
// Define a type for menu details
interface MenuDetails {
        menuname: string;
        menuid: number;
            }
// (SOP-64) Asset Management - Home Page - New hire Request - TS - end

const ViewTicketandAssetGridDetails =()=>{
   
//My Assets  UseState  
let  [UserAssetorder, setUserAssetOrder] = React.useState<"desc" | "asc">("desc");
let  [UserAssetorderBy, setUserAssetOrderBy] = React.useState("");
let  [UserAssettableData, setUserAssetTableData] = React.useState([]);
const [UserAssetpage, setUserAssetPage] = React.useState<number>(0);
const [UserAssetcount, setUserAssetCount] = useState<number>(0);
const [UserAssetrowsPerPage, setUserAssetsetRowsPerPage] = React.useState<number>(5);
    

//Ticket request UseState
let  [TicketTypeIncidentorder, setTicketTypeIncidentOrder] = React.useState<"desc" | "asc">("desc");
let  [TicketTypeIncidentorderBy, setTicketTypeIncidentOrderBy] = React.useState("");
let  [TicketTypeIncidenttableData, setTicketTypeIncidentTableData] = React.useState([]);
const [TicketTypeIncidentpage, setTicketTypeIncidentPage] = React.useState<number>(0);
const [TicketTypeIncidentcount, setTicketTypeIncidentCount] = useState<number>(0);
const [TicketTypeIncidentrowsPerPage, setTicketTypeIncidentsetRowsPerPage] = React.useState<number>(5);
    
const navigate = useNavigate();

// To set action type for edit ad delete
let actionType;

// ---------------------------Asset  Related----------------------------
// Head cell of My Assets
const MyPropertyHeadCell = [
    {
    id: "assetname",
    numeric: false,
    disablePadding: true,
    label: "Asset Name",
    isSort: true,
    isBold: true,
    },
    {
    id: "modelnumber",
    numeric: false,
    disablePadding: false,
    label: "Model number",
    isSort: true,
    isBold: true,
    },
    {
    id: "serialnumber",
    numeric: false,
    disablePadding: false,
    label: "Serial number",
    isSort: true,
    isBold: true,
    },
    {
    id: "manufacturer",
    numeric: false,
    disablePadding: false,
    label: "Manufacturer",
    isSort: true,
    isBold: true,
    },
    //SOP-92 Inlcuded By PCS  [to include view in 'My asset' grid]
    {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    isSort: false,
    },
    //EOF SOP-92 Inlcuded By PCS  [to include view in 'My asset' grid]
    ];

//Fucntion to get Asset details in grid
const getUserAssetList = () => 
        {     
            
        //SOP-92 Included by PCS [search functionality for 'My Asset' grid]
        // Initializing Variable 
            let AssetName = null;
            let ModelNumber = null;
            let SerialNumber = null;
            let Manufacturer = null;
            for (const item of cells_MyAssets)
            {
                if (item.id === "assetname") {
                AssetName = item.value;
                }

                if (item.id === "modelnumber") {
                ModelNumber = item.value;
                }

                if (item.id === "serialnumber") {
                SerialNumber = item.value;
                }

                if (item.id === "manufacturer") {
                Manufacturer = item.value;
                    }
            }
    //EOF SOP-92 Included by PCS [search functionality for My Asset Request Grid]
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_user_asset_list`, {

                //SOP-92 Inlcuded By PCS [To include search functionality]
                SearchAssetName: AssetName,
                SearchModelNumber: ModelNumber,
                SearchSerialNumber: SerialNumber,
                SearchManufacturer: Manufacturer,
                //EOF SOP-92 Included By PCS [To include search functionality]
                SortColumnName: UserAssetorderBy,
                SortColumnDirection: UserAssetorder,
                StartIndex: UserAssetpage + 1,
                PageSize: UserAssetrowsPerPage,
                CompanyId:CompanyId,
                //SOP-89 Modified By PCS [passing employeeid instead of userid- to display assets used by employee]
                // Userid:UserId
                    Userid:EmployeeId,
                //EOF SOP-89 Modified By PCS
                
            })
            .then((res: any) => 
            {
            
            const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
            //SOP-92 Modified By PCS [including view option in the grid]
            // id:item.ticketid,
                assetid:item.request_forwhichassetid,
            //EOF SOP-92 Modified By PCS [including view option in the grid]
            
            }
            }) 
           
                setUserAssetTableData(formattedTableData);
                if(res.data.data[0]===undefined)
            {
                setUserAssetCount(0);
            }
            else
            {
                setUserAssetCount(res.data.data[0].total_count);
            }
            })

            .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setUserAssetTableData([]);
            }
            Failed(error.response.message);
            // console.log(' FAIled',error.response.message)
            });
        };

                
//My Assets Grid's function
// Sort Handler
const handleUserAssetSort = (event: any, property: any) =>
    {
    const isAsc = UserAssetorderBy === property && UserAssetorder === "asc";
    UserAssetorder = isAsc ? "desc" : "asc";
    setUserAssetOrder(UserAssetorder);
    UserAssetorderBy = property;
    setUserAssetOrderBy(UserAssetorderBy);
    };
        
// Action function of table icons
    const UserAssetactionFunction = (id: string, actionText: string) => 
    {   
       //SOP-92 modified by PCS [enable view icon in my asset grid]
        // if (actionText === "view") navigate(`/view_nationality/${id}`);
            if (actionText === "view") navigate(`/view_asset_details/${id}`);
        //EOF SOP-92 modified by PCS [enable view icon in my asset grid]
    };

// Pagination Handler of table
    const UserAssethandleChangePage = (event: unknown, newPage: number) => {
        setUserAssetPage(newPage);
    };

//Search
    const handleSearchMyAssets = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = [...cells_MyAssets];
        const index = cells_MyAssets.findIndex((c: any) => c.id === e.target.name);
        if (index !== -1) {
            search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
        }
        
        setcells_MyAssets(search);
        UserAssethandleChangePage(setUserAssetPage,0);
        getUserAssetList();
        

        };
    
// Rows Per page Handler
    const UserAssethandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setUserAssetsetRowsPerPage(+event.target.value);
            setUserAssetPage(0);
    };
    
//EOF My Assets Grid's function

// ---------------------------Ticket Related----------------------------

 // Head cell of My Tickets Request
        const TicketTypeIncidentHeadCell = [
            {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
            },
            {
                id: "ticket_date",
                numeric: false,
                disablePadding: true,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
            id: "sys_issue_type_name",
            numeric: false,
            disablePadding: false,
            label: "Issue Type",
            isSort: true,
            isBold: true,
            },
           
            {
            id: "ticket_issueshortdescription",
            numeric: false,
            disablePadding: false,
            label: "Issue ShortDescription",
            isSort: true,
            isBold: true,
            },
            {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
              },
            
            ];

// To get ticket details - Function
       const getTicketTypeIncidentList = () => 
                {   
                //SOP-92 Included by PCS [search functionality for My Ticket(Incident) grid]
                // Initializing Variable 
                let TicketNumber = null;
                let TicketDate = null;
                let TicketIssueType = null;
                let TicketShortDescription = null;
                for (const item of cells_MyTicket)
                {
                if (item.id === "ticket_number") {
                TicketNumber = item.value;
                }

                if (item.id === "ticket_date") { 
                TicketDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
                TicketDate =TicketDate? TicketDate.add(5, 'hour').add(30, 'minute'):null;
                }

                if (item.id === "sys_issue_type_name") {
                TicketIssueType = item.value;
                }

                if (item.id === "ticket_issueshortdescription") {
                TicketShortDescription = item.value;
                }
                }
                //EOF SOP-92 Included by PCS [search functionality for My Ticket(Incident) grid]

                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_ticket_list_for_employee`, {
                //SOP-92 Inlcuded By PCS [To include search functionality]
                SearchTicketNumber: TicketNumber,
                SearchTicketDate: TicketDate,
                SearchTicketIssueType: TicketIssueType,
                SearchTicketIssueShortDescription: TicketShortDescription,
                //EOF SOP-92 Included By PCS [To include search functionality]
                SortColumnName: TicketTypeIncidentorderBy,
                SortColumnDirection: TicketTypeIncidentorder,
                StartIndex: TicketTypeIncidentpage + 1,
                PageSize: TicketTypeIncidentrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
                // // ticket type 1 for Incident
                // // TicketType:1
                // IssueType:1,
                // IssueShortDescription:'tested by sivasuri'
                // "SortColumnName":TicketTypeIncidentorderBy,
                // "SortColumnDirection":null,
                // "StartIndex":1,
                //   "PageSize":10,
                //   "CompanyId":65,
                //   "CreatedBy":109
                })
                .then((res: any) => 
                {

                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                id:item.ticketid,
                ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                }
                }) 
                //console.log("formattedTableData",formattedTableData)
                setTicketTypeIncidentTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                setTicketTypeIncidentCount(0);
                }
                else
                {
                setTicketTypeIncidentCount(res.data.data[0].total_count);
                }
                })
                .catch((error) => 
                {
                if (error.response.status === 404)
                {
                setTicketTypeIncidentTableData([]);
                }
                Failed(error.response.message);
                });
           };

//My Tickets grid function
// Sort Handler
    const handleTicketTypeIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = TicketTypeIncidentorderBy === property && TicketTypeIncidentorder === "asc";
        TicketTypeIncidentorder = isAsc ? "desc" : "asc";
        setTicketTypeIncidentOrder(TicketTypeIncidentorder);
        TicketTypeIncidentorderBy = property;
        setTicketTypeIncidentOrderBy(TicketTypeIncidentorderBy);
        };
    
// Action function of table icons
    const TicketTypeIncidentactionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "view"){
            // SOP-55 - TS commented and included (navigation) - start
            // Asset Management-Ticketing-View and Edit Page
                // handleViewTicketOpen(id);
                navigate(`/View_raise_a_ticket_page/${id}`)
            // SOP-55 - TS commented and included (navigation) - end
        }
    };
    
// Pagination Handler of table
    const TicketTypeIncidenthandleChangePage = (event: unknown, newPage: number) => {
        setTicketTypeIncidentPage(newPage);
    };

 // Search Handler of table
    const handleSearchMyTicket = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                const search = [...cells_MyTicket];
                                const index = cells_MyTicket.findIndex((c: any) => c.id === e.target.name);
                                if (index !== -1) {
                                search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
                                }
                                setcells_MyTicket(search);
                                TicketTypeIncidenthandleChangePage(setTicketTypeIncidentPage,0);
                                getTicketTypeIncidentList();
    };
    
// Rows Per page Handler
    const TicketTypeIncidenthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setTicketTypeIncidentsetRowsPerPage(+event.target.value);
            setTicketTypeIncidentPage(0);
    };
//EOF My Tickets grid function

  
const [cells_MyTicket, setcells_MyTicket] = useState<any>(TicketTypeIncidentHeadCell);//My Tickets (Incident) grid
const [cells_MyAssets, setcells_MyAssets] = useState<any>(MyPropertyHeadCell);//My Assets grid

//  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId
  
//UseEffect
  
 useEffect(() => {
  getUserAssetList();
  getTicketTypeIncidentList();
    },[UserAssetrowsPerPage,UserAssetpage,UserAssetorderBy,UserAssetorder,
        TicketTypeIncidentrowsPerPage,TicketTypeIncidentpage, TicketTypeIncidentorderBy,TicketTypeIncidentorder,
      ]); 
  
return(
<>
<MainContainerWrapper headingText="">
<Box>
<Box  bgcolor={'white'}>
<Typography sx={{padding:'8px'}} marginLeft={3} variant='h3'> My Asset(s)</Typography>
<Box>
<EnhancedTable
    headCells={MyPropertyHeadCell}
    rows={UserAssettableData}
    rowsPerPage={UserAssetrowsPerPage}
    page={UserAssetpage}
    handleChangePage={UserAssethandleChangePage}
    handleChangeRowsPerPage={UserAssethandleChangeRowsPerPage}
    handleSearch={handleSearchMyAssets}//SOP-92 Included By PCS [search in 'My Assets' grid]
    handleRequestSort={handleUserAssetSort}
    order={UserAssetorder}
    orderBy={UserAssetorderBy}
    actionFunction={UserAssetactionFunction}
    //SOP-92 Modified by PCS [enable view icon]
    // isView={false}
    isView={true}
    //SOP-92 Modified by PCS 
    isDelete={false}
    isEdit={false}
    total={UserAssetcount}
    //SOP-92 Modified By PCS [enable search function]
    // isSearch={false}
    isSearch={true}
    //EOF SOP-92 Modified by PCS [enable search function]
    HoverColor={'#E7F1FB'}
    />
    </Box>
    </Box>
    </Box>
    <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
        <Button
        sx={{
        background: 'white',
        border: 'none',
        borderRadius: '5px',
        padding:{
        lg: "0 70px",
        md: "0 70",
        xs:'0 50px'

        },
        height: "3rem",
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
        }}
        onClick={()=>{
        navigate('/home')
        }} 
        >
        Back
    </Button>
    </Box>
{/* ---Ticket Related Grid---- */}
    <Box>
    <Box mt={2} bgcolor={'white'}>
    <Typography  sx={{padding:'8px'}} marginLeft={3} variant='h3'>Incident(s)</Typography>
    <Box>
    <EnhancedTable
        headCells={TicketTypeIncidentHeadCell}
        rows={TicketTypeIncidenttableData}
        rowsPerPage={TicketTypeIncidentrowsPerPage}
        page={TicketTypeIncidentpage}
        handleChangePage={TicketTypeIncidenthandleChangePage}
        handleChangeRowsPerPage={TicketTypeIncidenthandleChangeRowsPerPage}
        handleRequestSort={handleTicketTypeIncidentRequestSort}
        handleSearch={handleSearchMyTicket}//SOP-92 Included By PCS [search in 'My Ticket' grid]
        order={TicketTypeIncidentorder}
        orderBy={TicketTypeIncidentorderBy}
        actionFunction={TicketTypeIncidentactionFunction}
        isView={true}
        isDelete={false}
        isEdit={false}
        total={TicketTypeIncidentcount}
        //SOP-92 Modified by PCS [to enable search function]
        // isSearch={false}
        isSearch={true}
        //EOF SOP-92 Modified by PCS
        HoverColor={'#E7F1FB'}
    />
    </Box>
    </Box>
    </Box>
    <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
    <Button
    sx={{
    background: 'white',
    border: 'none',
    borderRadius: '5px',
    padding:{
    lg: "0 70px",
    md: "0 70",
    xs:'0 50px'

    },
    height: "3rem",
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    }}
    onClick={()=>{
    navigate('/home')
    }} 
    >
    Back
    </Button>
    </Box>
</MainContainerWrapper>
</>) }

export default ViewTicketandAssetGridDetails;

//EOF SOP-101 Included by PCS [View Tickets &  Asset requests Grid details - Home page]