// material-ui
import { Box, ButtonBase,Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SmartOpz_Logo from '../../../../asset/images/SmartOpz_LogoResized.jpg'

// ==============================|| FOOTER LOGO ||============================== //
// SOP-101 Included By PCS
const LogoInSideBar = () => {
const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
const GlobalData = JSON.parse(GlobalDataFromSession);
let CompanyId = GlobalData.GlobalCompanyId;
let UserId = GlobalData.GlobalUserId;
const navigate = useNavigate();

let GlobalEmail = GlobalData.GlobalUserEmail
 useEffect(() => { }, []);     
  return (
    <Box>
   <Typography sx={{color:'gray',textAlign:'center'}}>Powered By</Typography>
   <a href="https://SMARTOPZ.COM/" target="_blank">
      <img src={SmartOpz_Logo} style={{cursor:'pointer'}}  width={150} height={40}></img>
   </a>
   </Box>
);
};

export default LogoInSideBar;
