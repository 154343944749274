//SOP-101 Included By PCS [Homepage- Hire request Chart]

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import { commonService } from "../../atic-common-helpers/helpers/common.service";
import {Grid} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useEffect } from "react";

export default function MyHireRequestsChart() {

//Global data
  const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
  const GlobalData = JSON.parse(GlobalDataFromSession);
    
  const [NewHireRequest, setNewHireRequest] = useState<number>(0); 
  const [InprogressHirerequest, setInprogressHirerequest] = useState<number>(0); 
  const [CompletedHireRequest, setCompletedHireRequest] = useState<number>(0); 
  const allHireRequestsCount = NewHireRequest+InprogressHirerequest+CompletedHireRequest;

  const MyHireRequestsData = async () => 
  {
    try {
          const HireRequestPayload = {
                  CompanyId: GlobalData.GlobalCompanyId,
                  EmployeeId:GlobalData.GlobalEmployeeId,
                  UserId:GlobalData.GlobalUserId
                                    };
    //API    
          const response = await commonService
              .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/homepage_hirerequest_request_chart`, HireRequestPayload);
              //console.log("Hire response",response.data.data[0])
              setNewHireRequest(response.data.data[0].request_count); 
              setInprogressHirerequest(response.data.data[0].pending_requests); 
              setCompletedHireRequest(response.data.data[0].completed_requests);   
        } 
    catch (error) {
        console.error("Error fetching data:", error);
                 }
   };
  useEffect(() => 
  {
    MyHireRequestsData();
  }, []);
  
  const [itemNb, setItemNb] = React.useState(3);
  const COLORS = ['#FF53A9', '#9A54E4', '#F49C3D'];

 //Hire Requests values from DB is set here
  const MyHireRequestsvalues = [
                { label: 'New Hire Request', value: NewHireRequest },
                { label: 'Waiting for Onboarding Process', value: InprogressHirerequest },
                { label: 'Hired', value: CompletedHireRequest },
              ];

// Apply colors to each pie slice
  const dataWithColors = MyHireRequestsvalues.slice(0, itemNb).map((item, index) => ({
                ...item,
                color: COLORS[index % COLORS.length],  
              }));
              
const valueFormatter = (value:any) => `${value.value}`;

const theme = useTheme();
const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
const radius = isSmallScreen ? 20 : isMediumScreen ? 30 :50; 
const chartSize = isSmallScreen ? 220 : isMediumScreen ? 240 : 260; 

return (
<Box sx={{ display: 'flex' ,width: '100%'
}}>
<Grid  container display={'flex'}> 
{/*------------- Pie Chart-------------- */}
  <Grid item  lg={4} md={12} xs={12} sm={12} >
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <PieChart 
        width={chartSize}
        height={chartSize}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        sx={{
          "& text": {
            fontWeight: 'normal',
          },
          }}
        series={[
          {
            data: dataWithColors, // Use the data with colors
            innerRadius: radius,
            arcLabelMinAngle: 10,
            valueFormatter,
          },
        ]}
      />
     <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '32%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
     >
     <Typography
      variant="h6"
      sx={{
        fontWeight: 'bold',
         color: '#3F424D',
        '@media (max-width: 600px)': {fontSize:'10px'}, 
        '@media (max-width: 768px)': {fontSize:'11px'}, 
        '@media (max-width: 1024px)': {fontSize:'12px'}, 
        '@media (min-width: 1025px) and (max-width: 1280px)': {fontSize:'13px'}, 
        '@media (min-width: 1281px) and (max-width: 1600px)': {fontSize:'14px'},  
        '@media (min-width: 1601px) and (max-width: 1920px)':{fontSize:'15px'},  
        '@media (min-width: 1921px)': {fontSize:'16px'}, 
      }}
     >
    {allHireRequestsCount}
    </Typography>
    <Typography sx={{
       width:'95%', wordWrap: 'break-word',
       '@media (max-width: 600px)': {fontSize:'6px'}, 
       '@media (max-width: 768px)': {fontSize:'6px'}, 
       '@media (max-width: 1024px)': {fontSize:'6px'}, 
       '@media (min-width: 1025px) and (max-width: 1280px)': {fontSize:'13px'}, 
       '@media (min-width: 1281px) and (max-width: 1600px)': {fontSize:'14px'},  
       '@media (min-width: 1601px) and (max-width: 1920px)':{fontSize:'15px'},  
       '@media (min-width: 1921px)': {fontSize:'16px'}, 
       
       }}>Total Hire Requests</Typography>
 </Box>
</Box>
</Grid> 
<Grid item 
      sx={{ 
        '@media (max-width: 600px)': { marginTop:'-8%'}, 
        '@media (max-width: 768px)': {  marginTop:'-8%'}, 
        '@media (max-width: 1024px)': {  marginTop:'-9%' }, 
        '@media (min-width: 1025px) and (max-width: 1280px)': { marginTop:'12%'}, 
        '@media (min-width: 1281px) and (max-width: 1600px)': { marginTop:'10%'}, 
        '@media (min-width: 1601px) and (max-width: 1920px)': { marginTop:'9%' }, 
        '@media (min-width: 1921px)': { marginTop:'5%'},
      }} 
      lg={8} sm={12} md={12} xs={12} > 
{/* -------Custom Legend-----------[color box & label] */}
{dataWithColors.map((item, index) => (
    <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginTop: '1%' }}> 
        <Box
        sx={{
          backgroundColor: item.color,
          borderRadius: '2px',
          marginTop:'1.5%',
          marginLeft:'10%',
          '@media (max-width: 600px)': { width: '10px',height:'10px' }, // Small devices like phones
          '@media (max-width: 768px)': { width: '10px',height:'10px' }, // Tablets and smaller laptops
          '@media (max-width: 1024px)': { width: '10px',height:'10px'  }, // Standard laptops
          '@media (min-width: 1025px) and (max-width: 1280px)': { width: '10px',height:'10px' }, // Larger laptops (1280px or less)
          '@media (min-width: 1281px) and (max-width: 1600px)': { width: '12px',height:'12px' }, // Standard desktop monitors
          '@media (min-width: 1601px) and (max-width: 1920px)': { width: '14px',height:'14px'  }, // Large desktops and some monitors
          '@media (min-width: 1921px)': { width: '16px',height:'16px' }, // Ultra-wide and large 4K monitors
          
        }}
      />
    <Typography variant="body2"
    sx={{
      marginLeft:'2.5%', 
      marginTop:'1%',
      color: '#3F424D',
      // Media Queries
    '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
    '@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
    '@media (max-width: 1024px)': { fontSize: '14px' }, // Standard laptops
    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '16px' }, // Larger laptops (1280px or less)
    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '16px' }, // Standard desktop monitors
    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '19px' }, // Large desktops and some monitors
    '@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
               }}
    >
    {item.value} - {item.label}
    </Typography>
    </Box>
    ))}
    </Grid>  
</Grid> 
</Box>
);
}
//EOF SOP-101 included By PCS  [Homepage- Hire request Chart]

