// Included by sanjana SOP-30 to create Allocations approval page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";//SOP-100 Included by PCS
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';//SOP-100 Included by PCS
import IconButton from '@mui/material/IconButton';//SOP-100 Included by PCS
import AssetImg from '../../asset/images/Goods.png';//SOP-100 Included by PCS

//SOP-100 Commented by PCS [page modoifed from dialog box to separate page]  

// const EditAllocationsPage=
// ({
//     handleEditClose,
//     getMyPendingAllocationssList,
//     getMyApprovedAllocationsList,
//     RequestId
// }: {
//     handleEditClose: (event: React.MouseEvent) => void,
//     getMyPendingAllocationssList: (event: React.MouseEvent) => void,
//     getMyApprovedAllocationsList: (event: React.MouseEvent) => void,
//     RequestId:any
// })=>{

const EditAllocationsPage=()=>{
    
    //SOP-100 Included by PCS 
        const {id}= useParams();
        const AssetRequestId = id
        const navigate = useNavigate();
    //EOF SOP-100 Included by PCS 

    // useState
    const [AllocationsRemarksError, setAllocationsRemarksError]=useState(false)
    const [AllocationsRemarksValue, setAllocationsRemarksValue]=useState('')
    const [AllocationsRemarksErrorMessage, setAllocationsRemarksErrorMessage]=useState('')
    const [AllocationsOptions, setAllocationsOptions] = useState<OptionType[]>([]);
    const [selectedAllocationsId, setSelectedAllocationsId] = useState<any | null>('');
  
    const [AllocationsError, setAllocationStatusError]=useState(false)
    const [AllocationsErrorMessage, setAllocationStatusErrorMessage]=useState('')

    //SOP-100 Included by PCS [error message for asset]
        const [AssetError, setAssetError]=useState(false)
        const [AssetErrorMessage, setAssetErrorMessage]=useState('')
    //SOP-100 Included by PCS

        //For Asset cards
        const [ SearchValue, setSearchValue] = useState('');
        const [ AssetData, setAssetData] = useState([]);
        const [AssetId, setAssetId] = useState(null);
        const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');
        const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string | null>('');
        const [selectedAssetName, setSelectedAssetName] = useState<string | null>('');
        const [displayNoAssetsMessage, setDisplayNoAssetsMessage] = useState<string>('');

        //To display in header
        const [RequestedBy, setRequestedBy] = useState('');
        const [ApprovedBy, setApprovedBy] = useState('');
        const [CategoryName, setCategoryName] = useState('');
        const [SubCategoryName, setSubCategoryName] = useState('');

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;

    //  Handle Allocations while typing Allocations and validations
    const handleAllocationsRemarksChange = (event:any) => {
        setAllocationsRemarksValue(event.target.value);
        const trimmedAllocationsRemarksValue =  event.target.value
        if( trimmedAllocationsRemarksValue.length !== 255 ){
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }
    };

//SOP-100 Commented by PCS [not used - page modified from dialog box to separate page]
    // To render handle close function to close dialouge box
    // const handleClose = (event: React.MouseEvent) => {
    //     if (handleEditClose && typeof handleEditClose === 'function') {
    //         handleEditClose(event);
    //     }
    // };

    // to render getMyPendingAllocationssList function after approving request
    // const handlegetMyPendingAllocationssList= (event: React.MouseEvent) => {
    //     if (getMyPendingAllocationssList && typeof getMyPendingAllocationssList === 'function') {
    //         getMyPendingAllocationssList(event);
    //     }
    // };

    // to render getMyApprovedAllocationsList function after approving request
    // const handlegetMyApprovedAllocationsList= (event: React.MouseEvent) => {
    //     if (getMyApprovedAllocationsList && typeof getMyApprovedAllocationsList === 'function') {
    //         getMyApprovedAllocationsList(event);
    //     }
    // };
//EOF SOP-100 Commented by PCS [not used -page modified from dialog box to separate page]

    const GetAllocationsList = async () => { 
        try {
            //SOP-81 Included By PCS [API changed- drop down options changed]
            // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approve_status_list`);
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_allocation_status_list`);
            //EOF SOP-81 Included By PCS
            //console.log("Allocations" ,res.data.data);
            let formattedAllocations = res.data.data.map((item:any) => {
                const { sys_approve_status_id, sys_approve_status_name } = item;
                return {
                    name: sys_approve_status_name,
                    value: sys_approve_status_id,
                };
            });
            setAllocationsOptions(formattedAllocations)
        } catch (err) {
            //console.log( "co",err);
        }
        };

    // To get Allocationsd Approval details as per the id 
    const GetAllocationsdApprovalListById =()=>{

        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_allocation_approved_list_by_id`, {
            CompanyId: CompanyId,
            //SOP-100 Modified by PCS
            // RequestId: RequestId,
            RequestId: AssetRequestId,
            //EOF SOP-100 Modified by PCS
            })
            .then((res: any) => 
            { 
           
            const AllocationsdAssetData = res.data.data[0]
            setAllocationsRemarksValue(AllocationsdAssetData.allocate_remarks)
            let formattedAllocationsStatus = res.data.data.map((item:any) => {
                const { allocationstatusid, allocationstatusname } = item;
                return {
                    name: allocationstatusname,
                    value: allocationstatusid,
                };
                });
                setSelectedAllocationsId(formattedAllocationsStatus[0].value)            
            }).catch((error) =>  
            { 
            Failed(error.response.message);
            });
    }
    

    // To Allocations approval

    // Define a type for errors
    type Errors = {
    AllocationsSts?:boolean;
    apprveRemarks?:boolean;
    AssetIdSts?:boolean;//SOP-100 Included by PCS
    };

    const AllocationsApprovals =(event:any)=>{

        event.preventDefault()
        let errors: Errors = {};

        //SOP-100 Included by PCS
        if(selectedAllocationsId === 1 && AssetId === null ){
            errors.AssetIdSts = true;
            setAssetErrorMessage("Asset is required")
            setAssetError(true)
        }
       //EOF SOP-100 Included by PCS

        if (selectedAllocationsId === '' || selectedAllocationsId === null || selectedAllocationsId === undefined) {
            errors.AllocationsSts = true;
            setAllocationStatusErrorMessage("Allocations Status is required")
            setAllocationStatusError(true)
        }
        
        const trimmedAllocationsRemarksValue = AllocationsRemarksValue.trim()
        if( trimmedAllocationsRemarksValue.length >255 ){
            errors.apprveRemarks = true
            setAllocationsRemarksError(true);
            setAllocationsRemarksErrorMessage("Max 255 characters");
        }
        else {
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }

        let AllocationsShortName;
 //SOP-81 Modified by PCS
        // if(selectedAllocationsId === 2){
        //     AllocationsShortName= 'A'
        // }

        // if(selectedAllocationsId === 3){
        //     AllocationsShortName= 'R'
        // }

        // if(selectedAllocationsId === 4){
        //     AllocationsShortName= 'H'
        // }

        if(selectedAllocationsId === 1){
            AllocationsShortName= 'A'
        }

        if(selectedAllocationsId === 2){
            AllocationsShortName= 'H'
        }

        if(selectedAllocationsId === 3){
            AllocationsShortName= 'I'
        }
   //EOF SOP-81 Modified by PCS

        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setAllocationStatusError(errors.AllocationsSts || false);
            setAllocationsRemarksError(errors.apprveRemarks || false);
            return;
        }

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/allocation_asset_request_update`, 
            {
            SaveUpdateKey: "update",
            CompanyId: CompanyId,
            //SOP-100 Modified by PCS
            // RequestId: RequestId,
            RequestId: AssetRequestId,
            //EOF SOP-100 Modified by PCS
            RequestAllocationStatus: AllocationsShortName, 
            RequestAllocationByEmployeeId: UserId, 
            RequestAllocationRemarks: AllocationsRemarksValue,
            AssetId:AllocationsShortName==='A'?AssetId :null //SOP-100 Included by PCS
            }
        )
        .then((res) => {
          if (res.status === 200) {
              Success("Allocation Status Updated Successfully");
              navigate('/allocations')//SOP-100
            
          }
          }).catch((error) => {
              console.error("Error:", error);
          });
    }
 
//SOP-100 Included by PCS [to fetch asset request detils by id]

const GetAssetDetailsById =()=>{
    const  AssetPayload = {
        CompanyId: CompanyId,
        RequestId: AssetRequestId
       }
    commonService
    .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_asset_request_by_id`, AssetPayload)
        .then((res: any) => 
        { 
         const AssetRequestDetails = res.data.data[0]
        //  console.log("Asset data",AssetRequestDetails)
         setRequestedBy(AssetRequestDetails.requestedby)
         setApprovedBy(AssetRequestDetails.approved_by_name)
         setCategoryName(AssetRequestDetails.categoryname)
         setSubCategoryName(AssetRequestDetails.subcategoryname)
         setSelectedCategoryId(AssetRequestDetails.categoryid)
         setSelectedSubCategoryId(AssetRequestDetails.subcategoryid)

        }).catch((error) =>  
        { 
        Failed(error.response.message);
        });
}
//EOF SOP-100 Included by PCS [to fetch asset request detils by id] 


//SOP-100 Included By PCS [get asset list in drop down]
const getRequestAnAssetList =(event:any)=>{
    event.preventDefault();
   const payload ={
         CategoryId:selectedCategoryId ,
         SubCategoryId:selectedSubCategoryId,
         CompanyId:CompanyId,
         AssetName:SearchValue,
    }
    commonService
     .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_asset_by_category_and_subcategory`, {
         CategoryId:selectedCategoryId ,
         SubCategoryId:selectedSubCategoryId,
         CompanyId:CompanyId,
         AssetName:SearchValue,
     })  
     .then((res: any) => 
     { 
       const AssetsforSelectedSubcategory = res.data.data
    //    console.log("RequestAnAsetData",AssetsforSelectedSubcategory.length)
    // Set the message if no assets are found
        if (AssetsforSelectedSubcategory.length === 0) {
            setAssetData([]);
            setDisplayNoAssetsMessage("No assets available for the subcategory");
         } 
         else {
            setAssetData(AssetsforSelectedSubcategory);
            setDisplayNoAssetsMessage(""); 
         }
// Set the message if no assets are found   
        
     }).catch((error) =>   
     { 
      Failed(error.response.message);
     });
}  
//EOF SOP-100 Included By PCS [get asset list in drop down]

// To get search value while typing in search text field
const handleSearchChange =( event:any)=>{
    setSearchValue(event.target.value);
}


 // To open request an asset box
 const handleClickAddRequestOpen = (Assetid:any,AssetName:any) => {
    // console.log('Assetid',Assetid)
    setAssetId(Assetid)
    setSelectedAssetName(AssetName)
//to make Asset field error disappear 
    setAssetErrorMessage("")
    setAssetError(false)

  };

    useEffect(() => {
        GetAllocationsList();
        GetAllocationsdApprovalListById();
        GetAssetDetailsById();//SOP-100 Included By PCS
    }, []); 

//SOP-100 Modified by PCS [new page instaed of dialog box]
    // return(
    //     <Box>
    //         <Box bgcolor={'white'} borderRadius={3}
    //         sx={{
    //             width:{
    //                 lg:600,
    //                 md:600,
    //                 xs:350
    //         }}} >
    //             <Box>
    //             <Box p={3}>
    //                     <Typography variant='h1'
    //                     sx={{
    //                         fontSize:{
    //                             lg:'2rem',
    //                             md:'2rem',
    //                             xs:'1.5rem'
    //                         }
    //                     }}
    //                     >
    //                     Edit Allocations
    //                     </Typography>
    //                 </Box>
                    
    //                 <form noValidate>
    //                     <Box p={3}>
    //                         <Grid container >
    //                             <Grid item lg={12} md={12} xs={12} >
    //                                 <Autocomplete
    //                                     id="combo-box-demo"
    //                                     options={AllocationsOptions as OptionType[]}
    //                                     filterOptions={filterOptions}
    //                                     getOptionLabel={(option: unknown) => (option as OptionType).name}
    //                                     value={AllocationsOptions.find((option: OptionType) => option.value === selectedAllocationsId) || null}
    //                                     onChange={(event, newValue) => {
    //                                         if (newValue) {
    //                                             setSelectedAllocationsId(newValue.value);
    //                                             setAllocationStatusError(false);
    //                                             setAllocationStatusErrorMessage("");
    //                                         } else {
    //                                             setSelectedAllocationsId('');
    //                                             setAllocationStatusError(true);
    //                                             setAllocationStatusErrorMessage("Allocations is required");
    //                                         }
    //                                     }}
    //                                     // sx={{ width: 235, ml: 1 }}
    //                                     renderInput={(params) => 
    //                                     <TextField {...params}
    //                                     error={AllocationStatusError}
    //                                     autoFocus
    //                                     helperText={AllocationStatusErrorMessage}
    //                                         required label="Allocations Status" 
    //                                     variant="standard" />}
    //                                 />
    //                             </Grid>

    //                             <Grid item mt={3} lg={12} md={12} xs={12} >
    //                                         <TextField 
    //                                         id="outlined-basic" 
    //                                         label="Allocations Remarks" 
    //                                         variant="outlined" 
    //                                         name='AllocationsRemarks'
    //                                         fullWidth
    //                                         multiline
    //                                         rows={4}
    //                                         error={AllocationsRemarksError}
    //                                         onChange={handleAllocationsRemarksChange}
    //                                         value={AllocationsRemarksValue}
    //                                         helperText={AllocationsRemarksErrorMessage}                                           
    //                                         />
    //                                     </Grid>
    //                         </Grid>
    //                     </Box>

    //                 <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
    //                             <Button
    //                                 sx={{
    //                                     background: 'white',
    //                                     border: 'none',
    //                                     borderRadius: '5px',
    //                                     padding:{
    //                                         lg: "0 70px",
    //                                         md: "0 70",
    //                                         xs:'0 50px'
                
    //                                     },
    //                                     // color: {accent},
    //                                     height: "3rem",
    //                                     boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    //                                 }}

    //                                 onClick={handleClose} 
    //                             >
    //                                 Back
    //                             </Button>

    //                             <Button
    //                                 sx={{
    //                                     background: '#25425F',
    //                                     border: 'none',
    //                                     borderRadius: '5px',
    //                                     padding:{
    //                                         lg: "0 70px",
    //                                         md: "0 70",
    //                                         xs:'0 50px'
                
    //                                     },
    //                                     "&:hover":{
    //                                         backgroundColor:'#25425F',
    //                                         color:'white',
    //                                     },
    //                                     color: 'white',
    //                                     height: "3rem",
    //                                     boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    //                                 }}
    //                                 type='submit'
    //                                 onClick={(event)=>{
    //                                     AllocationsApprovals(event);
    //                                 }}
    //                             >
    //                                 Update
    //                             </Button>
    //                             </Box>
    //                 </form>


    //             </Box>
    //         </Box>
        
    //     </Box>
    // )

    return(
        <Box>
        <Box bgcolor={'white'} borderRadius={3}>
        <Box p={3}>
            <Typography variant='h3'
                sx={{
                    fontSize:{
                        lg:'1.5rem',
                        md:'1rem',
                        xs:'1rem'
                    }
                }}
                >
                Edit Asset Allocation
                </Typography>
        </Box>
        <Box>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <Grid mb={2}  ml={5} sx={{marginLeft:'2%'}}
           container
           spacing={{ xs: 2, md: 2 }}
           columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
           rowSpacing={{ xs: 2, md: 1 }}
           columnSpacing={{ xs: 1, md: 3 }}
        >
        <Grid item  xs={4} sm={4} md={4} lg={2}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <Typography sx={{ wordWrap: 'break-word',
                    overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'#002984'}}>
                Request Id
            </Typography>
            <Typography sx={{  wordWrap: 'break-word',
                    overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'black'}}>
                {AssetRequestId}
            </Typography>
            </div>
            </Grid>
            <Grid item  xs={4} sm={4} md={4} lg={2}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{ wordWrap: 'break-word',
                    overflowWrap: 'break-word',  fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'#002984'}}>
                Requested By
                </Typography>
                <Typography sx={{fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'black',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word'}}>
                {RequestedBy}
                </Typography>
            </div>
        
            </Grid>
            <Grid item  xs={4} sm={4} md={4} lg={2}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{ wordWrap: 'break-word',
                    overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'#002984'}}>
                Approved By
                </Typography>
                <Typography sx={{  wordWrap: 'break-word',
                    overflowWrap: 'break-word', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'black'}}>
                {ApprovedBy}
                </Typography>
            </div>
            </Grid>
            <Grid item  xs={4} sm={4} md={4} lg={2}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{  wordWrap: 'break-word',
                    overflowWrap: 'break-word',fontWeight: 'medium', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'#002984'}}>
                Category 
                </Typography>
                <Typography sx={{ wordWrap: 'break-word',
                    overflowWrap: 'break-word', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'black'}}>
                {CategoryName}
                </Typography>
            </div>
            </Grid>
            <Grid item  xs={4} sm={4} md={4} lg={2}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{  wordWrap: 'break-word',
                    overflowWrap: 'break-word',fontWeight: 'medium', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'#002984'}}>
                Sub Category 
                </Typography>
                <Typography sx={{ wordWrap: 'break-word',
                    overflowWrap: 'break-word', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.9rem'}, textAlign: 'center', color:'black'}}>
                {SubCategoryName}
                </Typography>
                </div>
            </Grid>
       
        </Grid>
        </div>
        <form noValidate>
        <Box p={1}>
        <Grid container>
        <Grid mb={2}  ml={1}
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
            rowSpacing={{ xs: 2, md: 1 }}
            columnSpacing={{ xs: 2, md: 3 }}
        ></Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
        {/* First half */}
        
        <Box ml={3} display={'flex'}>
        <TextField
            type='text'
            variant="standard"
            label='Search'
            name='Search'
               sx={{
                    width: '50%', // Default width (for screens larger than 1024px)
                    '@media (max-width: 1024px)': {
                    width: '40%', // For screens <= 1024px
                    },
                    '@media (max-width: 600px)': {
                    width: '100%', 
                    }
                }}
            fullWidth
            onChange={handleSearchChange}
            value={SearchValue}
        />
        <Box ml={3}>
            <IconButton
            sx={{
                mt:1,
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                bgcolor:'#0079FF',
                ":hover":{
                    color:'#0079FF',
                    bgcolor:'white'
                }
            }}
            type='submit'
            onClick={(event)=>{
                getRequestAnAssetList(event);
            }}
         >
        <SearchIcon sx={{
            fontSize:'1.5rem',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            cursor:'pointer',
            color:'white',
            ":hover":{
                color:'#0079FF',
            }
        }}
        />
        </IconButton>
        </Box>
        </Box>

{/* Display error message on no assets available */}

{displayNoAssetsMessage && <div style={{
    //   backgroundColor: "#f8d7da",
      color: "#721c24",
      padding: "15px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "3px solid #f5c6cb",
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: "center",
      width:'50%'
    }}>{displayNoAssetsMessage}</div>}
        
        <Box mt={3} display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', maxHeight: '400px', // Set a max height for scrolling
                overflowY: 'auto'   // Enable vertical scrolling when content overflows
         }}>
                {AssetData.map((AssetData, index) => {
                     const isSelected = AssetData["assetid"] === AssetId; 
                    return (
                        <Box 
                            key={index}
                            className="flip-box" 
                            // p={0.5} // Reduced padding
                            onClick={() => {                    
                                handleClickAddRequestOpen(AssetData["assetid"],AssetData["assetname"]);
                            }}
                            sx={{
                                cursor: 'pointer', 
                                maxWidth: '140px', 
                                margin: '0.5rem', 
                                display: 'flex', 
                                flexDirection: 'column', // Ensure elements stack properly in each box
                                // Conditionally apply styles when the asset is selected
                                border: isSelected ? '4px solid #0079FF' : '1px solid gray',
                                boxShadow: isSelected ? '0 0 10px rgba(32, 69, 204, 0.59)' : 'none',
                             }}
                        >
                            <Box className="flip-box-inner" sx={{ height: '60%', border:'1px solid gray',borderRadius:'1px'}}>
                                <Box className="flip-box-front" borderRadius={3} sx={{ height: '60%' }}>
                                    <Box style={{ width: '80px', height: '40px', textAlign: 'center', margin: '0 auto' }}>
                                        <img
                                            style={{
                                                borderRadius: '50%',
                                                height: '30px', // Reduced image size
                                                width: '30px',  // Reduced image size
                                                marginTop: '0.8rem',
                                            }}
                                            src={AssetImg}
                                            alt=""
                                        />
                                        <Typography sx={{ fontSize: '0.85rem', color: 'black', mt: 1 }}>
                                            {AssetData["assetname"]}
                                        </Typography>
                                        <Typography sx={{ fontSize: '0.9rem', color: '#0079FF', mt: 1 }}>
                                            {AssetData["categoryname"]}
                                        </Typography>
                                    </Box>
                                </Box>
        
                                <Box className="flip-box-back" borderRadius={3}>
                                    <Typography sx={{ fontSize: 12, mt: 0.25, color: '#25425f' }}>
                                        Model Number
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: '#25425f', fontWeight: 'bold' }}>
                                        {AssetData["modelnumber"]}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, mt: 0.25, color: '#25425f' }}>
                                        Serial Number
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: '#25425f', fontWeight: 'bold' }}>
                                        {AssetData["serialnumber"]}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, mt: 0.25, color: '#25425f' }}>
                                        Manufacturer
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: '#25425f', fontWeight: 'bold' }}>
                                        {AssetData["manufacturer"]}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
        
        </Grid>
        <Grid item ml={3} xs={12} sm={12} md={12} lg={3}>
            <Grid item mt={3} lg={12} md={8} xs={8} >
            <TextField 
            id="outlined-basic" 
            label="Selected Asset " 
            variant="outlined" 
            name='AllocationsRemarks'
            fullWidth
            error={AssetError}
            // onChange={handleAllocationsRemarksChange}
            value={selectedAssetName}
            helperText={AssetErrorMessage}                                           
        />
        </Grid>
       <Grid item mt={3} lg={12} md={8} xs={8} >
        <Autocomplete
        id="combo-box-demo"
        options={AllocationsOptions as OptionType[]}
        filterOptions={filterOptions}
        getOptionLabel={(option: unknown) => (option as OptionType).name}
        value={AllocationsOptions.find((option: OptionType) => option.value === selectedAllocationsId) || null}
        onChange={(event, newValue) => {
            if (newValue) {
                setSelectedAllocationsId(newValue.value);
                setAllocationStatusError(false);
                setAllocationStatusErrorMessage("");
            } else {
                setSelectedAllocationsId('');
                setAllocationStatusError(true);
                setAllocationStatusErrorMessage("Allocations is required");
            }
            }}
        // sx={{ width: 235, ml: 1 }}
        renderInput={(params) => 
        <TextField {...params}
        error={AllocationsError}
        autoFocus
        helperText={AllocationsErrorMessage}
        required label="Allocations Status" 
        variant="standard" />}
        />
        </Grid>
        <Grid item mt={3} lg={12} md={8} xs={8} >
                    <TextField 
                    id="outlined-basic" 
                    label="Allocations Remarks" 
                    variant="outlined" 
                    name='AllocationsRemarks'
                    fullWidth
                    multiline
                    rows={4}
                    error={AllocationsRemarksError}
                    onChange={handleAllocationsRemarksChange}
                    value={AllocationsRemarksValue}
                    helperText={AllocationsRemarksErrorMessage}                                           
                    />
        </Grid>
                </Grid>
        </Grid>
        </Box>
        
        </form> 
        </Box>
        </Box>
        <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Button
        sx={{
            background: 'white',
            border: 'none',
            borderRadius: '5px',
            padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px'
        
            },
            // color: {accent},
            height: "3rem",
            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
        }}
       
        onClick={()=>{
            navigate(`/asset_view_allocation/${AssetRequestId}`)}}
        >
        Back
        </Button>
        <Button
        sx={{
            background: '#25425F',
            border: 'none',
            borderRadius: '5px',
            padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px'
        
            },
            "&:hover":{
                backgroundColor:'#25425F',
                color:'white',
            },
            color: 'white',
            height: "3rem",
            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
        }}
        type='submit'
        onClick={(event)=>{
            AllocationsApprovals(event);
        }}
        >
        Save
        </Button>
        </Box>
                
        </Box>)
}
export default EditAllocationsPage;

// End of SOP-30