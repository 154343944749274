//SOP-101 Included by PCS [View Asset Grid details - Home page]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Dialog,
    Card
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import requestAsset from '../../asset/images/requestAsset1.png';
import raiseTicket from '../../asset/images/raiseTicket.png'
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';
import dayjs from 'dayjs';

const ViewAssetRequestGridDetails =()=>{
   
//Asset request UseState
let  [AssetRequestorder, setAssetRequestOrder] = React.useState<"desc" | "asc">("desc");
let  [AssetRequestorderBy, setAssetRequestOrderBy] = React.useState("");
let  [AssetRequesttableData, setAssetRequestTableData] = React.useState([]);
const [AssetRequestpage, setAssetRequestPage] = React.useState<number>(0);
const [AssetRequestcount, setAssetRequestCount] = useState<number>(0);
const [AssetRequestrowsPerPage, setAssetRequestsetRowsPerPage] = React.useState<number>(5);
    

const navigate = useNavigate();

// To set action type for edit ad delete
        let actionType;

// Head cell of My Asset request
const AssetRequestHeadCell = [
    {
        id: "request_number",
        numeric: false,
        disablePadding: true,
        label: "Request #",
        isSort: true,
        isBold: true,
    },
    {
        id: "assetname",
        numeric: false,
        disablePadding: true,
        label: "Asset Name",
        isSort: true,
        isBold: true,
    },
    {
        id: "asset_date",
        numeric: false,
        disablePadding: false,
        label: "Request Date",
        isSort: true,
        isBold: true,
        dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
        id: "request_remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        isSort: true,
        isBold: true,
    },
    //SOP-92 Included By PCS [to include view in asset request grid]
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
      },
    //EOF SOP-92 Included By PCS [to include view in asset request grid]
    
    ];
          
// To get asset request - Function
const getAssetRequestList = () => 
    {     
        
        //SOP-92 Included by PCS [search functionality for 'My Asset Request' grid]
            // Initializing Variable 
                let AssetRequestNumber = null;
                let AssetName = null;
                let AssetRequestDate = null;
                let AssetRemarks = null;
               for (const item of cells)
               {
                if (item.id === "request_number") {
                    AssetRequestNumber = item.value;
                }

                if (item.id === "assetname") {
                    AssetName = item.value;
                }

                if (item.id === "asset_date") { 
                    AssetRequestDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
                    AssetRequestDate =AssetRequestDate? AssetRequestDate.add(5, 'hour').add(30, 'minute'):null;
                }

                if (item.id === "request_remarks") {
                    AssetRemarks = item.value;
                }
               }
        //EOF SOP-92 Included by PCS [search functionality for My Asset Request Grid]

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_request_and_asset_list_for_employee`, {
            
            //SOP-92 Inlcuded By PCS [Including search functionality]
            SearchAssetRequestNumber: AssetRequestNumber,
            SearchAssetName: AssetName,
            SearchAssetRequestDate: AssetRequestDate,
            SearchAssetRemarks: AssetRemarks,
            //EOF SOP-92 Included By PCS [Including search functionality]
            SortColumnName: AssetRequestorderBy,
            SortColumnDirection: AssetRequestorder,
            StartIndex: AssetRequestpage + 1,
            PageSize: AssetRequestrowsPerPage,
            CompanyId:CompanyId,
            // Modified by sanjana SOP-40 to set employee id insted of user id
            // CreatedBy:UserId,
            // Modified by sanjana SOP-58 to set userid id insted of employee id
             CreatedBy:UserId,
            //CreatedBy:EmployeeId,
            // End of SOP-58
            
            // End of SOP-40
        })
        .then((res: any) => 
        {
        const formattedTableData= res.data.data.map((item:any)=>{
        return {...item,
            //SOP-92 Modified By PCS [including view option in the grid]
            // id:item.ticketid,
            // request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
               asset_date: item.asset_date ? dayjs(item.asset_date).format("DD-MM-YYYY") : "",
               id:item.requestid,
           //EOF SOP-92 Modified By PCS [including view option in the grid]
        }

        }) 
        // console.log("Asset Request",formattedTableData)
        
         setAssetRequestTableData(formattedTableData);
        if(res.data.data[0]===undefined)
        {
            setAssetRequestCount(0);
        }
        else
        {
            setAssetRequestCount(res.data.data[0].total_count);
        }
        })
    .catch((error) => 
        {
        if (error.response.status === 404)
        {
            setAssetRequestTableData([]);
        }
        Failed(error.response.message);
        });
    };

const [cells, setCells] = useState<any>(AssetRequestHeadCell); //My Asset request grid
    
//Asset Request Grid's function starts
    
// Sort Handler
const handleAssetRequestIncidentRequestSort = (event: any, property: any) =>
    {
    const isAsc = AssetRequestorderBy === property && AssetRequestorder === "asc";
    AssetRequestorder = isAsc ? "desc" : "asc";
    setAssetRequestOrder(AssetRequestorder);
    AssetRequestorderBy = property;
    setAssetRequestOrderBy(AssetRequestorderBy);
    };

// Action function of table icons
    const AssetRequestactionFunction = (id: string, actionText: string) => 
    {
        //SOP-92  modified  by PCS [to enable view ICON in asset request grid]
        // if (actionText === "view") navigate(`/view_nationality/${id}`);
           if (actionText === "view") navigate(`/view_asset_request/${id}`);
        //EOF SOP-92 modified  by PCS [to enable view ICON in asset request grid]
    
    };

// Pagination Handler of table
    const AssetRequesthandleChangePage = (event: unknown, newPage: number) => {
        setAssetRequestPage(newPage);
    };

    // Search Handler of table
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = [...cells];
        const index = cells.findIndex((c: any) => c.id === e.target.name);
        if (index !== -1) {
            search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
        }
        setCells(search);
        AssetRequesthandleChangePage(setAssetRequestPage,0);
        getAssetRequestList();
   };

// Rows Per page Handler
const AssetRequesthandleChangeRowsPerPage = (
event: React.ChangeEvent<HTMLInputElement>
) => {
    setAssetRequestsetRowsPerPage(+event.target.value);
    setAssetRequestPage(0);
};

//EOF Asset Request Grid's function ends
      
//SOP-92 Included By PCS [Including search functionality]
  
//  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId

//UseEffect
    useEffect(() => {
        getAssetRequestList();
     }, 
        [AssetRequestrowsPerPage,AssetRequestpage,AssetRequestorderBy,AssetRequestorder,]); 
  
return(
    <>
    <MainContainerWrapper 
    headingText="Asset Request Details"
    isSearch={false}
    >
    <Box>
    <Box  bgcolor={'white'}>
    <Box>
    <EnhancedTable
      headCells={AssetRequestHeadCell}
      rows={AssetRequesttableData}
      rowsPerPage={AssetRequestrowsPerPage}
      page={AssetRequestpage}
      handleChangePage={AssetRequesthandleChangePage}
      handleChangeRowsPerPage={AssetRequesthandleChangeRowsPerPage}
      handleRequestSort={handleAssetRequestIncidentRequestSort}
      handleSearch={handleSearch}//SOP-92 Included By PCS [Including search in My Asset request grid]
      order={AssetRequestorder}
      orderBy={AssetRequestorderBy}
      actionFunction={AssetRequestactionFunction}
      //SOP-92 Modified by PCS [enable view icon]
      // isView={false}
      isView={true}
      //SOP-92 Modified by PCS 
      isDelete={false}
      isEdit={false}
      total={AssetRequestcount}
      //SOP-92 Modified by PCS [enable search function]
      // isSearch={false}
      isSearch={true}
      //EOF SOP-92 Modified by PCS
      HoverColor={'#E7F1FB'}         
    />
    </Box>
    </Box>
    </Box>
    <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
    <Button
    sx={{
    background: 'white',
    border: 'none',
    borderRadius: '5px',
    padding:{
        lg: "0 70px",
        md: "0 70",
        xs:'0 50px'

    },
    height: "3rem",
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    }}
    onClick={()=>{
    navigate('/home')
    }} 
    >
    Back
    </Button>
    </Box>
</MainContainerWrapper>
</>) }

export default ViewAssetRequestGridDetails;

//EOF SOP-101 Included by PCS [View Asset Grid details - Home page]