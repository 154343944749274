// Included by sanjana SOP-30 to create Allocations approval page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";//SOP-100 Included by PCS
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

//SOP-100 Modified by PCS 
// const ViewAllocationsPage=
// ({
//     handleViewClose,
//     handleEditOpen,
//     RequestId
// }: {
//     handleViewClose: (event: React.MouseEvent) => void,
//     handleEditOpen: (event: React.MouseEvent) => void,
//     RequestId:any
// })=>{

const ViewAllocationsPage=()=>{
//EOF SOP-100 Modified by PCS 

//SOP-100 Commented by PCS 
    const {id}= useParams();
    const AssetRequestId = id
    const navigate = useNavigate();
//EOF SOP-100 Commented by PCS 

    const [AllocationsRemarksError, setAllocationsRemarksError]=useState(false)
    const [AllocationsRemarksValue, setAllocationsRemarksValue]=useState('')
    const [AllocationsRemarksErrorMessage, setAllocationsRemarksErrorMessage]=useState('')
    const [AllocationsOptions, setAllocationsOptions] = useState<OptionType[]>([]);
    const [selectedAllocationsId, setSelectedAllocationsId] = useState<any | null>('');
    const [AllocationsError, setAllocationsError]=useState(false)
    const [AllocationsErrorMessage, setAllocationsErrorMessage]=useState('')
    const [ ApprovalAllocatationSts, setApprovalAllocatationSts]= useState('')   

   //SOP-100 Included by PCS [To display in header]
    const [RequestedBy, setRequestedBy] = useState('');
    const [ApprovedBy, setApprovedBy] = useState('');
    const [CategoryName, setCategoryName] = useState('');
    const [SubCategoryName, setSubCategoryName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string | null>('');
    const [selectedAssetName, setSelectedAssetName] = useState<string | null>('');

  //EOF SOP-100 Included by PCS [To display in header]
    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId
        const UserId = GlobalData.GlobalUserId;

    //  Handle Allocations while typing Allocations and validations
    const handleAllocationsRemarksChange = (event:any) => {
        setAllocationsRemarksValue(event.target.value);
        const trimmedAllocationsRemarksValue =  event.target.value
        if( trimmedAllocationsRemarksValue.length !== 255 ){
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }
    };

//SOP-100 Commented by PCS [not used changed form dialog box to separate page]

    // To render handle close function to close dialouge box
    // const handleClose = (event: React.MouseEvent) => {
    //     if (handleViewClose && typeof handleViewClose === 'function') {
    //         handleViewClose(event);
    //     }
    // };
//SOP-100 Commented by PCS [not used changed form dialog box to separate page]

    // to render handleEditOpen function after closing dialouge box
    // const EditAllocationsOpen= (event: React.MouseEvent) => {
    //     //console.log("ApprovalAllocatationSts",ApprovalAllocatationSts)
    //     if(handleEditOpen && typeof handleEditOpen === 'function' && ApprovalAllocatationSts === "A"){
    //         Swal.fire({
    //             html: '<span style="font-size: 1.3rem;">Request is already allocated, You cannot edit</span>',
    //             confirmButtonText: 'OK',
    //             });
    //     }
    //     else if(handleEditOpen && typeof handleEditOpen === 'function'){
    //         handleEditOpen(RequestId)
    //     }
    // };



//SOP-100 Included by PCS [to fetch asset request detils by id]
const GetAssetDetailsById =()=>{
   const  AssetPayload = {
        CompanyId: CompanyId,
        RequestId: AssetRequestId
       }
    commonService
    .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_asset_request_by_id`, AssetPayload)
        .then((res: any) => 
        { 
       
         const AssetRequestDetails = res.data.data[0]
         setRequestedBy(AssetRequestDetails.requestedby)
         setApprovedBy(AssetRequestDetails.approved_by_name)
         setCategoryName(AssetRequestDetails.categoryname)
         setSubCategoryName(AssetRequestDetails.subcategoryname)
         setSelectedCategoryId(AssetRequestDetails.categoryid)
         setSelectedSubCategoryId(AssetRequestDetails.subcategoryid)
         setAllocationsRemarksValue(AssetRequestDetails.request_approve_remarks)
         setSelectedAssetName(AssetRequestDetails.assetname)
         
        }).catch((error) =>  
        { 
        Failed(error.response.message);
        });
}
//EOF SOP-100 Included by PCS [to fetch asset request detils by id] 

    const GetAllocationsList = async () => { 
        try {
            //SOP-81 Included By PCS [API changed- drop down options changed]
            // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approve_status_list`);
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_allocation_status_list`);
            //EOF SOP-81 Included By PCS
            // console.log("Allocations" ,res.data.data);
            let formattedAllocations = res.data.data.map((item:any) => {
            const { sys_approve_status_id, sys_approve_status_name } = item;
            return {
                name: sys_approve_status_name,
                value: sys_approve_status_id,
            };
            });
            setAllocationsOptions(formattedAllocations)
        } catch (err) {
            //console.log( "co",err);
        }
        };

    // To get Allocationsd Approval details as per the id 
    const GetAllocationsdApprovalListById =()=>{

        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_allocation_approved_list_by_id`, {
            CompanyId: CompanyId,
            //SOP-100 Modifed by PCS
            // RequestId: RequestId,
            RequestId: AssetRequestId,
            })
            .then((res: any) => 
            { 
            // console.log("res",res.data.data[0])
            const AllocationsdAssetData = res.data.data[0]
            //console.log("AllocationsdAssetData",AllocationsdAssetData)
            setAllocationsRemarksValue(AllocationsdAssetData.allocate_remarks)
            setApprovalAllocatationSts(AllocationsdAssetData.allocatation_status)
            let formattedAllocationsStatus = res.data.data.map((item:any) => {
                const { allocationstatusid, allocationstatusname } = item;
                return {
                    name: allocationstatusname,
                    value: allocationstatusid,
                };
                });
                setSelectedAllocationsId(formattedAllocationsStatus[0].value)            
            }).catch((error) =>  
            { 
            Failed(error.response.message);
            });
    }
    

    useEffect(() => {
        GetAllocationsList();
        GetAllocationsdApprovalListById();
        GetAssetDetailsById()//SOP-100 Included by PCS
    }, []); 

//SOP-100 Modified By PCS[modified as full page]
    // return(
    //     <Box>
    //         <Box bgcolor={'white'} borderRadius={3}
    //         sx={{
    //             width:{
    //                 lg:600,
    //                 md:600,
    //                 xs:350
    //         }}} >
    //             <Box>
    //             <Box p={3}>
    //                     <Typography variant='h1'
    //                     sx={{
    //                         fontSize:{
    //                             lg:'2rem',
    //                             md:'2rem',
    //                             xs:'1.5rem'
    //                         }
    //                     }}
    //                     >
    //                     View Allocations
    //                     </Typography>
    //                 </Box>
    //                     <Box p={3}>
    //                         <Grid container >
    //                             <Grid item lg={12} md={12} xs={12} >
    //                                 <Autocomplete
    //                                     id="combo-box-demo"
    //                                     disabled
    //                                     options={AllocationsOptions as OptionType[]}
    //                                     filterOptions={filterOptions}
    //                                     getOptionLabel={(option: unknown) => (option as OptionType).name}
    //                                     value={AllocationsOptions.find((option: OptionType) => option.value === selectedAllocationsId) || null}
    //                                     onChange={(event, newValue) => {
    //                                         if (newValue) {
    //                                             setSelectedAllocationsId(newValue.value);
    //                                             setAllocationsError(false);
    //                                             setAllocationsErrorMessage("");
    //                                         } else {
    //                                             setSelectedAllocationsId('');
    //                                             setAllocationsError(true);
    //                                             setAllocationsErrorMessage("Allocations is required");
    //                                         }
    //                                     }}
    //                                     // sx={{ width: 235, ml: 1 }}
    //                                     renderInput={(params) => 
    //                                     <TextField {...params}
    //                                     error={AllocationsError}
    //                                     autoFocus
    //                                     disabled
    //                                     helperText={AllocationsErrorMessage}
    //                                     required label="Allocations Status" 
    //                                     variant="standard" />}
    //                                 />
    //                             </Grid>

    //                             <Grid item mt={3} lg={12} md={12} xs={12} >
    //                                         <TextField 
    //                                         id="outlined-basic" 
    //                                         label="Allocations Remarks" 
    //                                         variant="outlined" 
    //                                         name='AllocationsRemarks'
    //                                         fullWidth
    //                                         disabled
    //                                         multiline
    //                                         rows={4}
    //                                         error={AllocationsRemarksError}
    //                                         onChange={handleAllocationsRemarksChange}
    //                                         value={AllocationsRemarksValue}
    //                                         helperText={AllocationsRemarksErrorMessage}                                           
    //                                         />
    //                                     </Grid>
    //                         </Grid>
    //                     </Box>

    //                 <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
    //                             <Button
    //                                 sx={{
    //                                     background: 'white',
    //                                     border: 'none',
    //                                     borderRadius: '5px',
    //                                     padding:{
    //                                         lg: "0 70px",
    //                                         md: "0 70",
    //                                         xs:'0 50px'
                
    //                                     },
    //                                     // color: {accent},
    //                                     height: "3rem",
    //                                     boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    //                                 }}

    //                                 onClick={handleClose} 
    //                             >
    //                                 Back
    //                             </Button>

    //                             <Button
    //                                 sx={{
    //                                     background: '#25425F',
    //                                     border: 'none',
    //                                     borderRadius: '5px',
    //                                     padding:{
    //                                         lg: "0 70px",
    //                                         md: "0 70",
    //                                         xs:'0 50px'
                
    //                                     },
    //                                     "&:hover":{
    //                                         backgroundColor:'#25425F',
    //                                         color:'white',
    //                                     },
    //                                     color: 'white',
    //                                     height: "3rem",
    //                                     boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    //                                 }}
    //                                 onClick={(event)=>{
    //                                     EditAllocationsOpen(event);
    //                                     handleClose(event);
    //                                 }}
    //                             >
    //                                 Edit
    //                             </Button>
    //                             </Box>
    //             </Box>
    //         </Box>
        
    //     </Box>
    // )

    return(
        <Box>
        <Box bgcolor={'white'} borderRadius={3}
            sx={{
                
            }} >
        <Box p={3}>
            <Typography variant='h3'
                sx={{
                    fontSize:{
                        lg:'1.5rem',
                        md:'1rem',
                        xs:'1rem'
                    }
                }}
                >
                View Asset Allocation
                </Typography>
        </Box>
        <Box>
        
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <Grid mb={2}  ml={1}
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
            rowSpacing={{ xs: 2, md: 1 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
        {/* <Box sx={{marginTop:'4%'}}> */}
                <Grid item ml={3} xs={8} sm={8} md={8} lg={2}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{ wordWrap: 'break-word',
                        overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'#002984'}}>
                    Request Id
                </Typography>
                <Typography sx={{  wordWrap: 'break-word',
                        overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'black'}}>
                    {AssetRequestId}
                </Typography>
                </div>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={2}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography sx={{ wordWrap: 'break-word',
                        overflowWrap: 'break-word',  fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'#002984'}}>
                    Requested By
                    </Typography>
                    <Typography sx={{fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'black',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word'}}>
                    {RequestedBy}
                    </Typography>
                </div>
        
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={2}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography sx={{ wordWrap: 'break-word',
                        overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'#002984'}}>
                    Approved By
                    </Typography>
                    <Typography sx={{  wordWrap: 'break-word',
                        overflowWrap: 'break-word', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'black'}}>
                    {ApprovedBy}
                    </Typography>
                </div>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={2}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography sx={{  wordWrap: 'break-word',
                        overflowWrap: 'break-word',fontWeight: 'medium', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'#002984'}}>
                    Category 
                    </Typography>
                    <Typography sx={{ wordWrap: 'break-word',
                        overflowWrap: 'break-word', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'center', color:'black'}}>
                    {CategoryName}
                    </Typography>
                </div>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={2}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography sx={{  wordWrap: 'break-word',
                        overflowWrap: 'break-word',fontWeight: 'medium', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
                    Sub Category 
                    </Typography>
                    <Typography sx={{ wordWrap: 'break-word',
                        overflowWrap: 'break-word', fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
                    {SubCategoryName}
                    </Typography>
        
                </div>
                </Grid>
        
        </Grid>
        </div>
        <form noValidate>
        <Box p={1}>
        <Grid container>
        <Grid mb={2}  ml={1}
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
            rowSpacing={{ xs: 2, md: 1 }}
            columnSpacing={{ xs: 2, md: 3 }}
        ></Grid>
        
        {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
        <Grid item mt={3} ml={3} lg={3} md={3} xs={3} >
            <TextField 
            id="outlined-basic" 
            label="Selected Asset " 
            variant="outlined" 
            name='AllocationsRemarks'
            fullWidth
            disabled
            // error={AssetError}
            // onChange={handleAllocationsRemarksChange}
            value={selectedAssetName}
            // helperText={AssetErrorMessage}                                           
        />
        </Grid>
        <Grid item mt={3} ml={3} lg={3} md={3} xs={3} >
        <Autocomplete
        id="combo-box-demo"
        disabled
        options={AllocationsOptions as OptionType[]}
        filterOptions={filterOptions}
        getOptionLabel={(option: unknown) => (option as OptionType).name}
        value={AllocationsOptions.find((option: OptionType) => option.value === selectedAllocationsId) || null}
        renderInput={(params) => 
        <TextField {...params}
        error={AllocationsError}
        autoFocus
        helperText={AllocationsErrorMessage}
        required label="Allocations Status" 
        variant="standard" />}
        />
        </Grid>
        <Grid item mt={3}  ml={3} lg={3} md={3} xs={3} >
        <TextField 
        id="outlined-basic" 
        label="Allocations Remarks" 
        variant="outlined" 
        name='AllocationsRemarks'
        fullWidth
        multiline
        disabled
        rows={5}
        value={AllocationsRemarksValue}
        />
        </Grid>
    </Grid>
        {/* </Grid> */}
        </Box>

        </form> 
        </Box>
        </Box>
        <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Button
        sx={{
            background: 'white',
            border: 'none',
            borderRadius: '5px',
            padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px'
        
            },
     
            height: "3rem",
            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
        }}
       
        onClick={()=>{
            navigate("/allocations")
            
        }}
        >
        Back
        </Button>
        <Button
        sx={{
            background: '#25425F',
            border: 'none',
            borderRadius: '5px',
            padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px'
        
            },
            "&:hover":{
                backgroundColor:'#25425F',
                color:'white',
            },
            color: 'white',
            height: "3rem",
            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
        }}
        type='submit'
        // onClick={()=>{
        //     navigate(`/asset_edit_allocation/${AssetRequestId}`)
            
        // }}

        onClick={() => {
            if (selectedAllocationsId === 1) {
                // Show the error message if selectedAllocationsId is 1('Allocated')
                Swal.fire({
                    html: '<span style="font-size: 1.3rem;">Request is already allocated, You cannot edit</span>',
                    confirmButtonText: 'OK',
                });
            } else {
                // Navigate to the edit page if selectedAllocationsId is not 1
                navigate(`/asset_edit_allocation/${AssetRequestId}`);
            }
        }}
        >
        Edit
        </Button>
        </Box>
                
        </Box>
            )

//EOF SOP-100 Modified By PCS[modified as full page]

}
export default ViewAllocationsPage;

// End of SOP-30