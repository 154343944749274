// included by sanjana SOP-25 to create request an asset page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
    TablePagination,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import { BiSolidCategory } from "react-icons/bi";
import './RequestAnAsste.scss'
import AssetImg from '../../asset/images/Goods.png'
import AddRequestAnAssetPage from './AddRequestAnAsset';
//SOP-89 Included By PCS [Request an asset from employee grid]
import { useParams } from "react-router-dom";

import { flexbox } from '@mui/system';
//SOP-100 Included By PCS
import Chip from '@mui/material/Chip';
import Swal from 'sweetalert2';

const RequetAnAssetPage = () => {
    // Use navigate
    const navigate = useNavigate();

    //SOP-89 Included By PCS [getting employeeid from employee grid]
       const {id} = useParams();
       const IntEmployeeIdFromGrid = parseInt(id || '', 10);
       const [EmployeeName, setEmployeeName]=useState('')
   //EOF SOP-89 Included By PCS

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //  console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId

    //SOP-100 Modified by PCS [for better clarity]
    //let CreatedBy = GlobalData.GlobalUserId
    let UserId = GlobalData.GlobalUserId
    let EmployeeId = GlobalData.GlobalEmployeeId
    //EOF SOP-100 Modified by PCS  [for better clarity]
 
    //SOP-89 Included By PCS
    let GlobalEmployeeId = GlobalData.GlobalEmployeeId
    
    //SOP-100 Included By PCS
    let GlobalUserName = GlobalData.GlobalUserName

//Payload section
    const [CategoryOptions, setCategoryOptions] = useState<OptionType[]>([]);
    const [SubCategoryOptions, setSubCategoryOptions] = useState<OptionType[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');  
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string | null>(null);
    const [ RequesRemarksValue, setRequesRemarksValue] = useState('');
     const [CategoryError, setCategoryError]=useState(false)
     const [CategoryErrorMessage, setCategoryErrorMessage]=useState('')

//Header section
    const [seletedCategoryName, setSeletedCategoryName] = useState('');  
    const [seletedItem, setSeletedItem] = useState('');  
    const [requestdate, setRequestdate] = useState('');  
    const [requestTobeapprovedby, setRequestTobeapprovedby] = useState('Karthik'); //Static to change later
    const [departmentName, setDepartmentName] = useState('IT'); //Static to change later
    const [SearchValue, setSearchValue] = useState<string>('');
    const [selectedColumns, setSelectedColumns] = useState<Subcategory[]>([]);

  
//To get Today's date
    const getTodaysDate =()=>{
        const todayDate = new Date();
        const day = String(todayDate.getDate()).padStart(2, '0');
        const month = String(todayDate.getMonth() + 1).padStart(2, '0');
        const year = todayDate.getFullYear();
        const formattedTodayDate = `${day}-${month}-${year}`;
        setRequestdate(formattedTodayDate)
    }

//Interface   
    interface Subcategory {
        subcategoryId: number;
        subcategoryName: string;
    }
     
//EOF SOP-100 Included By PCS [subcategories for the categories]

 // Define a type for errors
 type Errors = {
    categoryId?:boolean;
    subcategoryId?:boolean;
   };

//Creating Asset request..
   const createRequestAnAsset = (event:any) =>
    {
        event.preventDefault();
        const parsedSubCategoryId = parseInt(selectedSubCategoryId || '0', 10); //converts to int format
        let errors: Errors = {};

    //Validations for asset request creation[selected sub category, category]

    let APIValidation = true;
    
    if (selectedCategoryId === '' || selectedCategoryId === null || selectedCategoryId === undefined) {
        Swal.fire({
            html: '<span style="font-size: 1.3rem;">No item selected</span>',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        });
        APIValidation = false;
        
    }

//EOF Validations for asset request creation[selected sub category, category]
if(APIValidation){
        const RequestAnAssetPayload ={
             SaveUpdateKey : "create",
             CompanyId : CompanyId,
             RequestId: 0,
             RequestBy:UserId,
             //SOP-100 Modified by PCS
            // RequestForWhichEmployeeId:EmployeeId,
             RequestForWhichEmployeeId:IntEmployeeIdFromGrid?IntEmployeeIdFromGrid:EmployeeId,
            //EOF SOP-100 Modified by PCS
             RequestForWhichAssetId:null,
             RequestRemarks:RequesRemarksValue,
             IsCancelled:null,
             CreatedBy:UserId,
             LastModifiedBy:null,
             RequestForWhichSubcategoryId:parsedSubCategoryId,
             RequestForWhichCategoryId:selectedCategoryId,
         }
          commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/asset_request_create_update_cancel`, RequestAnAssetPayload)
         .then((res) => {
           if (res.status === 200) {
               Success("Asset Requested Successfully");
               navigate('/home')}
           }).catch((error) => {
               console.error("Error:", error);
           });

        }
    }

// to set autocomplete drop down vale and name as string
    type OptionType = {
        name: string;
        value: string;
    };

    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

// To get category list to fill category dropdown
    const GetCategoryList = async () => 
    {    
        const CategoryPayload ={
                CompanyId:CompanyId}
           
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getCategoryList`,CategoryPayload);
            let formattedCategory = res.data.data.map((item:any) => {
            const { categoryid, categoryname } = item;
            return {
                name: categoryname,
                value: categoryid,
            };
            });
            setCategoryOptions(formattedCategory)
        } catch (err) {
            console.log( "co",err);
        }
    };

//SOP-100 Included by PCS [to get Sub categories list on selecting category]
    const GetSubCategoriesList = async (selectedCategoryId:any) =>
        { 
            const payloadValues = {
                    categoryid:selectedCategoryId,
                    CompanyId: CompanyId,
                    active: 'Y' }
           
                try {
                    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_subcategories_list_by_categoryid`,payloadValues );
                            
                    // Extract subcategory names and IDs and store them in an array of objects
                        const subcategories = res.data.data.map((item: any) => ({
                            subcategoryName: item.subcategoryname,
                            subcategoryId: item.subcategoryid,
                        }));
                        setSelectedColumns(subcategories); 
                   } 
                catch (err) 
                   {
                            console.log( "co",err);
                   }
        };
//EOF SOP-100 Included by PCS [to get Sub categories list on selecting category]

//SOP-100 Included by PCS [to get all item list[sub category options for the company is loaded]
const GetSubCategoriesandCategoriesList = async () =>
    { 
        const payloadValues = {
                CompanyId: CompanyId,
                active: 'Y' }
       
            try {
                    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_request_an_asset_anyitem_select_list`,payloadValues );
                    //  console.log("GetSubCategoriesList" ,res.data);    
                     let formattedCategoryanSubcategory = res.data.data.map((item:any) => {
                        const { subcategoryid, subcategoryname } = item;
                        return {
                            name: subcategoryname,
                            value: subcategoryid,
                        };
                        });
                        setSubCategoryOptions(formattedCategoryanSubcategory)
                        // setSelectedColumns(formattedCategoryanSubcategory); 
               } 
            catch (err) 
               {
                        console.log( "co",err);
               }
    };
//EOF SOP-100 Included by PCS[sub category options for the company is loaded]

//SOP-100 Included by PCS [to get categoryid for the selected subcategory id]
const GetCategorydetailsBySubCategoryId = async (selectedSubCategoryId:any) =>
    { 
        // console.log('selectedSubCategoryId',selectedSubCategoryId)
        const payloadValues = {
                CompanyId: CompanyId,
                SubCategoryId: selectedSubCategoryId }

            try {
                    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_categoryid_by_subcategoryid`,payloadValues );
                    // console.log("Categories details" ,res.data.data[0].categoryid);   
                    const categoryIdfromResponse =res.data.data[0].categoryid
                    setSelectedCategoryId(categoryIdfromResponse)
                    const category = CategoryOptions.find(option => option.value === categoryIdfromResponse);
                if (category) {
                    // console.log('Category Name:', category.name); 
                    setSeletedCategoryName(category.name);
                    } else {
                    // console.log('Category not found for the given ID');
                    }
                     
               } 
            catch (err) 
               {
                console.log( "co",err);
               }
    };
//EOF SOP-100 Included by PCS [to get categoryid for the selected subcategory id]


//SOP-89 Included By PCS [to get employee name by employeeid]
    const getEmployeeById = async () => 
        {
            const EmployeeListPayload ={
            EmployeeId:IntEmployeeIdFromGrid?IntEmployeeIdFromGrid:GlobalEmployeeId,
            // EmployeeId:GlobalEmployeeId,
            CompanyId:CompanyId
                    }
                try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`,EmployeeListPayload);
                // console.log('res',res.data.data[0])
                setEmployeeName(res.data.data[0].employee_name)
                setDepartmentName(res.data.data[0].departmentname)
                setRequestTobeapprovedby(res.data.data[0].reported_to_whom_name)
                   } 
                catch (err) {
                console.log( "co",err);
                }
        };
//EOF SOP-89 Included By PCS [to get employee details by id]

// UseEffect declaration
    useEffect(() => {
        getEmployeeById();//SOP-89 Included By PCS
        GetCategoryList();
        getTodaysDate();
        GetSubCategoriesandCategoriesList();
      }, []);

// To get remarks data while typing text in remarks field
    const handleRequesRemarksChange =( event:any)=>{
        setRequesRemarksValue(event.target.value);
    }

//Selecting Sub category
const handleSubcategorySelect = (subcategory:any) => {
   setSelectedSubCategoryId(subcategory);  // Sets the selected subcategory ID as a string
 };


// Adding search filter logic for items based on entered value
const handleSearchButtonClick = () => {
    setSelectedCategoryId(null);
    setSeletedItem(" ")//removes
    setSeletedCategoryName(" ")//removes

    // Filter category and subcategory options based on search text

    const filteredCategories = CategoryOptions.filter(option =>
        option.name.toLowerCase().includes(SearchValue.toLowerCase())
    );

    const filteredSubcategories = SubCategoryOptions.filter(option =>
        option.name.toLowerCase().includes(SearchValue.toLowerCase())
    );

  if(filteredSubcategories.length>0)
    {  
    setSelectedColumns(
        filteredSubcategories.map(subcategory => ({
            subcategoryId: Number(subcategory.value),  
            subcategoryName: subcategory.name
        }))
      );
    }

    else if(filteredCategories.length>0)
        {  
            filteredCategories.forEach(category => {
            setSelectedCategoryId(category.value);
            GetSubCategoriesList(category.value)
            });

        }
};

const handleSearchValueChange = (event:any) => {
    setSelectedSubCategoryId(null)//deselects previously selected item
    setSearchValue(event.target.value); 
  };

return(
<>
<Box>
<Box mb={4} sx={{borderRadius:'4px', 
    // height:'500px',
    height: {
        xs: 'auto',   
        sm: '500px',  
        md: '600px',  
        lg: '480px',  
      },
   
    }}>
<h1 style={{marginLeft:'1.5%',marginTop:'2%'}}>Request an Asset</h1> 
<Box mt={2} sx={{ height: '100%' ,backgroundColor:'white'}}>
<Grid container mt={1} spacing={3} display={'flex'} justifyContent={'center'} >

{/* //First Grid */}
<Grid item lg={8} ml={1} md={12} xs={12}
//   sx={{ borderRight: '1px solid gray' }}
 >
<Box sx={{ flexWrap: 'wrap', marginTop: '8px',maxHeight: '100px',
  overflowY: 'auto'}}>

{selectedSubCategoryId && selectedColumns.length === 0 && (
    <Typography variant="body2" color="textSecondary">
        No items available .
    </Typography>
)}

{selectedColumns.length>0 && 
<Typography sx={{color:'cornflowerblue',marginBottom: '16px' }} variant='h3'>Items</Typography>}
{selectedColumns.map((item) => (
<Chip
 
    key={item.subcategoryId} 
    label={<Typography sx={{ fontSize: '14px'}}>{item.subcategoryName}</Typography>}
    sx={{
        backgroundColor: selectedSubCategoryId === String(item.subcategoryId) ? 'lightblue' : 'white',  // Highlight the selected chip
        marginTop: '10px', 
        padding: '14px 20px',  
        border: '1px solid gray',
        borderRadius: '16px',
        boxShadow: 2, 
        
    }}
    onClick={() => {
        setSeletedItem(item.subcategoryName);  // Set the selected item name
        setSeletedCategoryName(seletedCategoryName);  // Keep the selected category name (if needed)
        handleSubcategorySelect(String(item.subcategoryId));  // Ensure it's passed as a string
        GetCategorydetailsBySubCategoryId(item.subcategoryId)
      
    }} 
    />
    ))}
 </Box>

<Divider sx={{mt:4, border:'0.1px solid cornflowerblue',marginRight:'2px',marginLeft:'2px'}}/> 

{/* //Header section */}
<Box>   
     <Grid mt={2} mb={2} 
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 2, sm: 8, md: 8, lg: 12 }}
        rowSpacing={{ xs: 2, md: 1 }}
        columnSpacing={{ xs: 2, md: 3 }}
    >

    <Grid mt={2} item xs={3} sm={3} md={3} lg={2}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
        Requested By
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
            overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
        {GlobalUserName}
    </Typography>
    </div>
    </Grid>

    <Grid mt={2} item xs={3} sm={3} md={3} lg={1.75}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
      Department
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
            overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
        {departmentName}
    </Typography>
    </div>
    </Grid>
        
    <Grid mt={2} item xs={3} sm={3} md={3} lg={2}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
        Selected Item
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
            overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
        {seletedItem}
    </Typography>
    </div>
    </Grid>

    <Grid mt={2} item xs={3} sm={3} md={3} lg={2.25}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
        Selected Category
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
            overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
        {seletedCategoryName}
    </Typography>
    </div>
    </Grid>

    <Grid mt={2} item xs={3} sm={3} md={3} lg={2.25}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
    To be Approved by
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
            overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
        {requestTobeapprovedby}
    </Typography>
    </div>
    </Grid>

    <Grid mt={2} item xs={3} sm={3} md={3} lg={2}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',overflowWrap: 'break-word',fontWeight: 'medium',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
        Request Date 
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
            overflowWrap: 'break-word',fontSize: {lg: '0.9rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
        {requestdate}
    </Typography>
    </div>
    </Grid>
{/* //Header section ends */}
     </Grid>
{/* Form starts */}
    <form noValidate >
        <Grid container >
            {/* Remarks field */}
                <Grid item ml={1} mt={2} sm={8} lg={8} md={8} xs={8}  >
                <TextField
                    type='text'
                    label='Remarks'
                    name='request_remarks'
                    fullWidth
                    variant="outlined" 
                    rows={4}
                    multiline
                    onChange={handleRequesRemarksChange}
                    value={RequesRemarksValue}
                />
                </Grid>

            {/* Back button  & submit button field */}
            <Grid mt={4} mb={1} container display={'flex'}>
                <Grid item  lg={4} md={4} xs={4}  >
                    <Button
                    sx={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 40px",
                        md: "0 30px",
                        xs:'0 20px'

                    },
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}
                    onClick={()=>{navigate('/home')}} 
                    >Back
                    </Button>
                </Grid>
                {/* <Grid item  lg={4} md={4} xs={4}  ></Grid>  */}
                <Grid item mb={1} lg={4} md={4} xs={4} justifyItems={'flex-end'} >
                    <Button
                    sx={{
                        background: '#25425F',
                        border: 'none',
                        borderRadius: '5px',
                        padding:{
                            lg: "0 40px",
                            md: "0 30px",
                            xs:'0 20px'
    
                        },
                        "&:hover":{
                        backgroundColor:'#25425F',
                        color:'white',
                    },
                    color: 'white',
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}
                    type='submit'
                    onClick={(event)=>{
                    createRequestAnAsset(event);
                    }}
                    >
                    Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </form>

{/* //Form ends */}
</Box>
</Grid>
{/* first grid ends */}

<Divider sx={{alignItems:'vertical', border:'0.1px solid cornflowerblue',marginRight:'2px'}}/> 

{/* //Second Grid   */}
<Grid item lg={3} md={12} xs={12}  
// sx={{border:'1px solid gray'}}
>
<Box mt={1}>
    <Box display={'flex'}>
    <TextField
    sx={{fontSize:'14px'}}
        type='text'
        variant="standard"
        label='Search any item'
        name='Search'
        style={{ width: '70%' }}
        onChange={handleSearchValueChange}
        value={SearchValue}
        helperText={CategoryErrorMessage}  
        error={CategoryError}
      /> 
      <Button  
       onClick={() => {
        handleSearchButtonClick()    
        }} 

        style={{
            width:'25%',
            backgroundColor: '#25425F',  
            color: 'white',               
            border: 'none',               
            padding: '8px 14px',         
            textAlign: 'center',         
            textDecoration: 'none',       
            display: 'inline-block',     
            fontSize: '12px',
            marginLeft:'10px',           
            borderRadius: '8px',          
            transition: 'background-color 0.3s ease', 
            cursor: 'pointer',          
          }}
     >Search</Button>
    </Box>
<Divider sx={{mt:4, border:'0.1px solid cornflowerblue',marginRight:'2px',marginLeft:'2px' }}/> 
<Box sx={{marginTop:'5%'}}>
<Autocomplete
id="combo-box-demo"
fullWidth
style={{ width: '80%',fontSize:'14px'}}
options={CategoryOptions as OptionType[]}
filterOptions={filterOptions}
getOptionLabel={(option: unknown) => (option as OptionType).name}
value={CategoryOptions.find((option: OptionType) => option.value === selectedCategoryId) || null}
onChange={(event, newValue) => {
    if (newValue) {
    setSelectedCategoryId(newValue.value);
    GetSubCategoriesList(newValue.value)
    setSeletedCategoryName(newValue.name);
    setSearchValue('')//to remove search value 
    setSeletedItem('')//to remove selected name
    setSelectedSubCategoryId(null)//deselects prev selected item
       
} else {
    setSelectedCategoryId('');
    setSelectedColumns([])
}
}}

renderInput={(params) => 
<TextField {...params}
label="Select by Category" 
variant="standard" />}
/>                     
</Box>
</Box>
</Grid>
{/* Second grid ends */}

</Grid>
</Box>
</Box>
</Box>
</>
)

}

export default RequetAnAssetPage;
// End of SOP-25