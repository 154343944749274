// material-ui
import { Box, ButtonBase } from "@mui/material";

// project imports

// Modified by sanjana SOP-16 image has been changed in side bar
import Logo from "../../../../asset/images/Logo/SmartOpzLogo1.png"
// End of SOP-16

// Imported by sanjana SOP-46
import { useEffect, useState } from "react";
import axios from "axios";
// End of SOP-46


//SOP-84 Included By PCS [Clicking logo to navigate to home page]
import { useNavigate } from "react-router-dom";

// ==============================|| MAIN LOGO ||============================== //


const LogoSection = () => {

  // Included by sanjana SOP-46
const [ProfileImg, setProfileImg]= useState<any>(null);

const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
const GlobalData = JSON.parse(GlobalDataFromSession);
let CompanyId = GlobalData.GlobalCompanyId;
let UserId = GlobalData.GlobalUserId;

//SOP-84 Included By PCS [Clicking logo to navigate to home page]
const navigate = useNavigate();

let GlobalEmail = GlobalData.GlobalUserEmail

    // To get company user details API as per the email 
    const ToGetCompanyUserDetails = async ()=>{

        const  CompanyUserDetailsPayload = {
            UserName:GlobalEmail,
            Active:"Y"
        }
        //console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
        //console.log(res.data.data);
        const UserData = res.data.data[0]
        // console.log("UserData NEW",UserData)
        setProfileImg(UserData.company_logo_url)
        return res.data.data[0] || [];
        } catch (err) {
        console.log( "co",err); 
        } 
    
        }

    useEffect(() => { 
      ToGetCompanyUserDetails();
          }, []);     
// End of SOP-46

  return (
    <Box 
    //  mt={1} 
     width={90} 
    // Included by sanjana SOP-46 to call api of Company details to display the company logo
    onClick={ToGetCompanyUserDetails}
    // End of SOP-46
      >
        
      {/* //SOP-84 Commented by PCS   */}
          {/* Modified by sanjana SOP-46 to add dynamic company logo */}
            {/* <img src={ProfileImg||Logo} alt="logo" width={90} height={90}></img> */}
          {/* End of SOP-46 */}
      {/* //EOF SOP-84 Commented by PCS   */}

    {/* //SOP-84 Included By PCS [wrapping img tag in div]*/}
      {/* Modified by sanjana SOP-46 to add dynamic company logo */}
    {/* //SOP-101 Modified by PCS */}
      {/* <div onClick={()=>{navigate('/home')}} style={{ cursor: 'pointer' }}>
          <img src={ProfileImg||Logo} alt="logo" width={90} height={90}></img>
        </div>  */}
       <div onClick={()=>{navigate('/home')}} style={{ cursor: 'pointer'}}>
         <img src={ProfileImg||Logo} alt="logo" width={70} height={50}></img>
      </div> 
    {/* //EOF SOP-101 Modified by PCS */}

      {/* End of SOP-46 */}
      {/* //EOF SOP-84 Included By PCS [wrapping img tag in div]*/}
  

    </Box>
  );
};

export default LogoSection;
