//SOP-101 Included by PCS [Homepage chart- My Assets]

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { PieChart } from '@mui/x-charts/PieChart';
import { commonService } from "../../atic-common-helpers/helpers/common.service";
import {Grid} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useEffect } from "react";

export default function MyAssetRequestsChart(){

  const [TotalRequestedAssets, setTotalRequestedAssets] = useState<number>(0);
  const [InprogressRequestedAssets, setInprogressRequestedAssets] = useState<number>(0);
  const [CompletedRequestedAssets, setCompletedRequestedAssets] = useState<number>(0);
  const [PendingRequestedAssets, setPendingRequestedAssets] = useState<number>(0);

 //Global data
 const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
 const GlobalData = JSON.parse(GlobalDataFromSession);
  
const MyAssetRequestsData = async () => 
  {
      try {
          //Payload
            const AssetRequestPayload = {
              CompanyId: GlobalData.GlobalCompanyId,
              EmployeeId:GlobalData.GlobalEmployeeId,
              UserId:GlobalData.GlobalUserId
            };

        //API    
        const response = await commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/homepage_myasset_request_chart`, AssetRequestPayload);
          // console.log("response in chart",response.data.data[0])
          setTotalRequestedAssets(response.data.data[0].total_request_count); // Set the total count
          setPendingRequestedAssets(response.data.data[0].pending_request_count); // Set the total count
          setInprogressRequestedAssets(response.data.data[0].inprogress_count); // Set the total count
          setCompletedRequestedAssets(response.data.data[0].allocated_count); // Set the total count  
    } 
      catch (error) {
        console.error("Error fetching data:", error);
      }
};

useEffect(() => 
  {
    MyAssetRequestsData();
  }, []);


const [itemNb, setItemNb] = React.useState(4);
const COLORS = ['#FF6B6B', '#F8A349', '#1DC154'];

//Replace the values with My Assets grid vlues 
  const MyAssetsValues = [
                { label: 'Requested Assets', value:PendingRequestedAssets},
                { label: 'Inprogress Assets', value: InprogressRequestedAssets },
                { label: 'Completed Assets', value: CompletedRequestedAssets },
              ];
             

// Apply colors to each pie slice
  const dataWithColors = MyAssetsValues.slice(0, itemNb).map((item, index) => ({
                ...item,
                color: COLORS[index % COLORS.length],
                 
              }));
              
const valueFormatter = (value:any) => `${value.value}`;

const theme = useTheme();
const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
const radius = isSmallScreen ? 20 : isMediumScreen ? 30 :50; // Respons
const chartSize = isSmallScreen ? 220 : isMediumScreen ? 240 : 260; // Responsive pie chart size

return (
<Box sx={{ display: 'flex',width: '100%'
}}>
<Grid  container display={'flex'} > 
{/* -----------Pie Chart ---------*/}
<Grid item  lg={4} md={12} xs={12} sm={6} >
  <Box sx={{ position: 'relative', display: 'inline-block' }}>
<PieChart 
width={chartSize}
height={chartSize}
slotProps={{
  legend: {
    hidden: true,
  },
}}
sx={{
  "& text": {
    fontWeight: 'normal',
  },
}}
series={[
  {
    data: dataWithColors, 
    innerRadius: radius,
    arcLabelMinAngle: 10,
    valueFormatter,
  },
      ]}
/>
<Box
sx={{
position: 'absolute',
top: '50%',
left: '32%',
transform: 'translate(-50%, -50%)',
textAlign: 'center',
}}
>
<Typography
  variant="h6"
  sx={{
    fontWeight: 'bold',
    color: '#3F424D',
'@media (max-width: 600px)': {fontSize:'10px'}, 
'@media (max-width: 768px)': {fontSize:'11px'}, 
'@media (max-width: 1024px)': {fontSize:'12px'}, 
'@media (min-width: 1025px) and (max-width: 1280px)': {fontSize:'13px'}, 
'@media (min-width: 1281px) and (max-width: 1600px)': {fontSize:'14px'},  
'@media (min-width: 1601px) and (max-width: 1920px)':{fontSize:'15px'},  
'@media (min-width: 1921px)': {fontSize:'16px'}, 
  }}
  >
    {TotalRequestedAssets}
  </Typography>
  <Typography sx={{ width:'90%', wordWrap: 'break-word',
        '@media (max-width: 600px)': {fontSize:'6px'}, 
        '@media (max-width: 768px)': {fontSize:'6px'}, 
        '@media (max-width: 1024px)': {fontSize:'6px'}, 
        '@media (min-width: 1025px) and (max-width: 1280px)': {fontSize:'13px'}, 
        '@media (min-width: 1281px) and (max-width: 1600px)': {fontSize:'14px'},  
        '@media (min-width: 1601px) and (max-width: 1920px)':{fontSize:'15px'},  
        '@media (min-width: 1921px)': {fontSize:'16px'}, 
    }} >Total Requests</Typography>
</Box>
</Box>
</Grid> 
<Grid item 
sx={{ 

'@media (max-width: 600px)': { marginTop:'-8%'}, 
'@media (max-width: 768px)': {  marginTop:'-8%'}, 
'@media (max-width: 1024px)': {  marginTop:'-9%' }, 
'@media (min-width: 1025px) and (max-width: 1280px)': { marginTop:'12%'}, 
'@media (min-width: 1281px) and (max-width: 1600px)': { marginTop:'10%'}, 
'@media (min-width: 1601px) and (max-width: 1920px)': { marginTop:'9%' }, 
'@media (min-width: 1921px)': { marginTop:'5%'},
}} 
  lg={8} sm={12} md={12} xs={12} > 
    {/* Custom Legend */}
    {dataWithColors.map((item, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginTop: '1%' }} > 
      <Box
      sx={{
  backgroundColor: item.color,
  borderRadius: '2px',
  marginTop:'1.5%',
  marginLeft:'10%',
  '@media (max-width: 600px)': { width: '10px',height:'10px' }, // Small devices like phones
  '@media (max-width: 768px)': { width: '10px',height:'10px' }, // Tablets and smaller laptops
  '@media (max-width: 1024px)': { width: '10px',height:'10px'  }, // Standard laptops
  '@media (min-width: 1025px) and (max-width: 1280px)': { width: '10px',height:'10px' }, // Larger laptops (1280px or less)
  '@media (min-width: 1281px) and (max-width: 1600px)': { width: '12px',height:'12px' }, // Standard desktop monitors
  '@media (min-width: 1601px) and (max-width: 1920px)': { width: '14px',height:'14px'  }, // Large desktops and some monitors
  '@media (min-width: 1921px)': { width: '16px',height:'16px' }, // Ultra-wide and large 4K monitors
        
      }}
      />
      <Typography variant="body2" 
        sx={{
          marginLeft:'2.5%', 
          marginTop:'1%',
          color: '#3F424D',
      // Media Queries
    '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
    '@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
    '@media (max-width: 1024px)': { fontSize: '14px' }, // Standard laptops
    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '16px' }, // Larger laptops (1280px or less)
    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '16px' }, // Standard desktop monitors
    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '19px' }, // Large desktops and some monitors
    '@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
     }} >
       {item.value} - {item.label}
       </Typography>
        </Box>
  ))}
</Grid>  
</Grid> 
</Box>
 );
 } 
//EOF SOP-101 Included by PCS [Homepage chart- My Assets]