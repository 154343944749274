//SOP-101 Included By PCS [Homepage- Leave request Chart]
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import {Grid} from '@mui/material';

export  function SickLeaveChart() {
  const [radius, setRadius] = React.useState(25);
  const [itemNb, setItemNb] = React.useState(4);
  const [skipAnimation, setSkipAnimation] = React.useState(false);

  const COLORS = ['#195AFE', '#E7F1FB'];

//Leave Requests values 
  const SickLeaveValues = [
                { label: 'Sick Leave', value: 2 },
                { label: ' Available', value: 7 },
              
              ];

// Apply colors to each pie slice
  const dataWithColors = SickLeaveValues.slice(0, itemNb).map((item, index) => ({
                ...item,
                color: COLORS[index % COLORS.length],  
              }));
              
const valueFormatter = (value:any) => `${value.value}`;

return (<Box sx={{ display: 'flex' 
}}>
<Grid  container display={'flex'}> 
{/* ------------Pie Chart------------ */}
<Grid item  lg={4} md={4} xs={4} >
<Box sx={{ position: 'relative', display: 'inline-block' }}>
<PieChart 
        marginLeft={1}
        width={170}
        height={80}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
      sx={{
        "& text": {
          fontSize: '14px', // Smaller font size for labels
          fontWeight: 'normal',
        },
      }}
      series={[
        {
          data: dataWithColors, // Use the data with colors
          innerRadius: radius,
          arcLabelMinAngle: 10,
          valueFormatter,
        },
      ]}
/>
<Box
  sx={{
    position: 'absolute',
    top: '50%',
    left: '22%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  }}
>
<Typography
    variant="h6"
    sx={{
      fontWeight: 'bold',
      fontSize: '16px', 
      color: '#3F424D',
    }}
  >
{2}
</Typography>
           
    </Box>
    </Box>
  </Grid> 
</Grid> 
</Box>
);
}

// ---------------Casual leave----------------------
export  function CasualLeaveChart() {
    const [radius, setRadius] = React.useState(25);
    const [itemNb, setItemNb] = React.useState(4);
    const [skipAnimation, setSkipAnimation] = React.useState(false);
  
    const COLORS = ['#195AFE', '#E7F1FB'];
  
    //Replace the values with My Assets grid vlues 
    const CasualLeaveValues = [
                  { label: 'Casual Leave', value: 7},
                  { label: 'Available', value: 10 },
                  
                ];
  
    // Apply colors to each pie slice
      const dataWithColors = CasualLeaveValues.slice(0, itemNb).map((item, index) => ({
                    ...item,
                    color: COLORS[index % COLORS.length],  
                  }));
                  
    const valueFormatter = (value:any) => `${value.value}`;
              
return (<Box sx={{ display: 'flex'}}>
<Grid  container display={'flex'}> 
  {/* -----------Pie Chart ------------*/}
  <Grid item  lg={4} md={4} xs={4} >
  <Box sx={{ position: 'relative', display: 'inline-block' }}>
  <PieChart 
    width={170}
    height={80}
      slotProps={{
        legend: {
          hidden: true,
        },
        }}
        sx={{
          "& text": {
            fontSize: '14px', // Smaller font size for labels
            fontWeight: 'normal',
          },
        }}
        series={[
          {
            data: dataWithColors, // Use the data with colors
            innerRadius: radius,
            arcLabelMinAngle: 10,
            valueFormatter,
          },
        ]}
     />
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '22%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    }}
  >
  <Typography
      variant="h6"
      sx={{
        fontWeight: 'bold',
        fontSize: '16px', 
        color: '#3F424D',
      }}
  >
  {7}
  </Typography>
  </Box>
  </Box>
  </Grid> 
</Grid> 
</Box>
);  }

//   --------Paid leave-------------
 export  function PaidLeaveChart() {
    const [radius, setRadius] = React.useState(25);
    const [itemNb, setItemNb] = React.useState(4) 

    const COLORS = ['#195AFE', '#E7F1FB'];
     //Replace the values with My Assets grid vlues 
      const PaidLeaveValues = [
                    { label: 'Paid Leave', value: 3 },
                    { label: 'Available', value:20 },
                 ];
    
    // Apply colors to each pie slice
      const dataWithColors = PaidLeaveValues.slice(0, itemNb).map((item, index) => ({
                    ...item,
                    color: COLORS[index % COLORS.length],  
                  }));
                            
   const valueFormatter = (value:any) => `${value.value}`;
   
   return (<Box sx={{ display: 'flex' }}>
    <Grid  container display={'flex'}> 
    {/*----------- Pie Chart------------------ */}
    <Grid item  lg={4} md={4} xs={4} >
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
        <PieChart 
            width={170}
            height={80}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            sx={{
              "& text": {
                fontSize: '14px', // Smaller font size for labels
                fontWeight: 'normal',
              },
            }}
            series={[
              {
                data: dataWithColors, // Use the data with colors
                innerRadius: radius,
                arcLabelMinAngle: 10,
                valueFormatter,
              },
            ]}
        />
<Box
  sx={{
    position: 'absolute',
    top: '50%',
    left: '22%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  }}
  >
  <Typography
    variant="h6"
    sx={{
      fontWeight: 'bold',
      fontSize: '16px',
      color: '#3F424D',
    }}
  >
    {3}
  </Typography>
</Box>
</Box>
</Grid> 
</Grid> 
</Box>
  );}

//EOF SOP-101 Included By PCS [Homepage- Leave request Chart]

