// Included by sanjana SOP-52 to set menus as per the user logs in
import GridViewIcon from "@mui/icons-material/GridView";
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import WcIcon from '@mui/icons-material/Wc';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { BarChart, Business } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import { PiTicketBold } from "react-icons/pi";
import { HiMiniUserCircle } from "react-icons/hi2";
import { BiSolidLayer } from "react-icons/bi";
import { MdDashboard } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
// Included by sanjana SOP-29
import { PiCheckCircleFill } from "react-icons/pi";
// End of SOP-29

// Included by sanjana SOP-58 imports
import { VscTypeHierarchySub } from "react-icons/vsc";
// End of SOP-58

//SOP-61 Included By PCS [included DocPoint]
import { ImBooks } from "react-icons/im";

// SOP-66
import { BsPersonWorkspace } from "react-icons/bs";
// SOP-66
//SOP-69 Included By PCS [ Knowledge Base]
import { FaBookOpenReader } from "react-icons/fa6";

//SOP-101 Included by PCS [Revamping menu bar-Icons used in menu]
import {ReactComponent as Home  } from "../../../asset/images/Home.svg";
import {ReactComponent as Dashboard  } from "../../../asset/images/Dashboard.svg";
import {ReactComponent as Tickets  } from "../../../asset/images/Tickets.svg";
import {ReactComponent as MyApproval  } from "../../../asset/images/MyApproval.svg";
import { ReactComponent as Admin } from "../../../asset/images/Admin.svg";
import {ReactComponent as HRWorkspace  } from "../../../asset/images/HRWorkspace.svg";
import {ReactComponent as DocPoint  } from "../../../asset/images/DocPoint.svg";
import {ReactComponent as KnowledgeBase  } from "../../../asset/images/KnowledgeBase.svg";
//EOF SOP-101 Included by PCS [Revamping menu bar-Icons used in menu]


interface MenuItem {
    id: string;
    title: string;
    type: string;
    url: string;
    icon: any;
    breadcrumbs: boolean;
}

interface SessionData {
    menuname: string;
    menuid: number;
}

// Session data
const EmployeeMenuDataFromSession: any = sessionStorage.getItem("EmployeeMenuDetails");
const EmployeeMenuDetailssessionData: SessionData[] = EmployeeMenuDataFromSession ? JSON.parse(EmployeeMenuDataFromSession) : [];

// Mapping of menu IDs to menu items
const menuItems: { [key: number]: MenuItem } = {
    1: {
        id: "home",
        title: "Home",
        type: "item",
        url: "/home",
        //SOP-101 Existing icon is changed
        // icon: IoHome,
        icon: Home,
        breadcrumbs: false,
        
    },
    2: {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        url: "/dashboard",
        //SOP-101 Existing icon is changed
        // icon: MdDashboard,
        icon: Dashboard,
        breadcrumbs: false,
    },
  //SOP-75 feedback points L1 modified by PCS [removed 'employee' from menu icon]
    // 3: {
    //     id: "employee",
    //     title: "Employees",
    //     type: "item",
    //     url: "/employee",
    //     icon: FaUsers,
    //     breadcrumbs: false,
    // },
    //EOF SOP-75 feedback points L1 modified by PCS [removed 'employee' from menu icon]]
    //SOP-75 feedback points L1 modified by PCS [removed 'assets' from menu icon]
    // 4: {
    //     id: "assets",
    //     title: "Assets",
    //     type: "item",
    //     url: "/assets",
    //     icon: BiSolidLayer,
    //     breadcrumbs: false,
    // },
    //EOF SOP-75 feedback points L1 modified by PCS [removed 'assets' from menu icon]
    5: {
        id: "ticketing",
        title: "Ticketing",
        type: "item",
        //SOP-101 Existing icon is changed
        // icon: PiTicketBold,
        icon: Tickets,
        breadcrumbs: false,
        url: "/ticketlist",
    },
    6: {
        id: "myapprovals",
        title: "My Approvals",
        type: "item",
        //SOP-101 Existing icon is changed
        // icon: HiMiniUserCircle,
        icon: MyApproval,
        breadcrumbs: false,
        url: "/myapprovals",
    },
    7: {
        id: "allocations",
        //SOP-81 Modified By PCS [renamed]
        // title: "Allocations ",
        title: "Asset Allocations ",
        //EOF SOP-81 Modified By PCS [renamed]
        type: "item",
        //SOP-101 Existing icon is changed
        // icon: HiMiniUserCircle,
        icon: Tickets,
         breadcrumbs: false,
        url: "/allocations",
    },
    //SOP-75 feedback points L1 Modified by PCS - title renamed from BACK OFFICE to ADMIN*/}
    8: {
        id: "backoffice",
        // title: "Back Office",
        title: "Admin",
        type: "item",
        //SOP-101 Existing icon is changed
        // icon: ImOffice,
        icon: Admin, 
        breadcrumbs: false,
        url: "/backoffice",
    },
    //EOF SOP-75 feedback points L1 Modified by PCS - title renamed from BACK OFFICE to ADMIN*/}

    // SOP-76 feedback points L2 (4)commented by PCS - removing from menu*/}
    // 9: {
    //     id: "ticketing_services",
    //     title: "Ticketing Services",
    //     type: "item",
    //     icon: PiTicketBold,
    //     breadcrumbs: false,
    //     url: "/ticketing_services",
    // },
    //EOF SOP-76 feedback points L2 (4) commented by PCS - removing from menu*/}

    // SOP-66 - commented & included by - TS - start
    // Included by sanjana SOP-58 to add hr hierarchy in menu
    // 10: {
    //     id: "hr_hierarchy",
    //     title: "HR Hierarchy",
    //     type: "item",
    //     icon: VscTypeHierarchySub,
    //     breadcrumbs: false,
    //     url: "/hr_hierarchy",
    // },
    // End of SOP-58

    11: {
        id: "hr_hierarchy",
        title: "HR Workspace",
        type: "item",
       //SOP-101 Existing icon is changed
        // icon: BsPersonWorkspace,
        icon: HRWorkspace,   
        breadcrumbs: false,
        url: "/HRDashBoard",
    },
    // SOP-66 - commented & included by - TS - end

    // SOP-61 Included By PCS[DocPoint]
    12:{
    id: "docpoint",
    title: "DocPoint",
    type: "item",
    //SOP-101 Existing icon is changed
    // icon: ImBooks,
    icon: DocPoint,     
    breadcrumbs: false,
    url: "/docpoint",
},
    //EOF SOP-61 Included By PCS[DocPoint]

// SOP-69 Included By PCS [Knowledge Base]
13:{
    id: "knowledgebase",
    title: "Knowledge Base",
    type: "item",
    //SOP-101 Existing icon is changed
    // icon: FaBookOpenReader,
    icon: KnowledgeBase,        
    breadcrumbs: false,
    url: "/knowledge_base",
},
//EOF SOP-69 Included By PCS [Knowledge Base]
   
};


// Create the filtered menu items based on session data
const filteredMenuItems = EmployeeMenuDetailssessionData.map((data: SessionData) => menuItems[data.menuid]).filter(Boolean);

// Define the admin menu with the filtered items
const usermenu = {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    children: filteredMenuItems,
};

export default usermenu;
// End of SOP-52
