// Included by sanjana SOP-29 to create My Approvals page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import ApproveApprovalsPage from './ApproveApprovals';
import ViewApproveApprovalsPage from './ViewApproveApproval';
import EditApproveApprovalsPage from './EditApproveApprovals';

//SOP-76 feedback points L2 (7) included By PCS
import HireRequestApprovalsPage from './HireRequestApprovals';
import ViewHireRequestApprovalsPage from './ViewHireRequestApproval';
import EditHireRequestApprovalsPage from './EditHireRequestApproval';
//EOF SO-76 feedback points L2 (7) included By PCS

const MyApprovalsPage =()=>{

    const navigate = useNavigate();

//SOP-76 feedback points L2 (7)  Included By PCS [Pending -Hire Requests]
     let  [MyPendingHireRequestApprovalsorder, setMyPendingHireRequestApprovalsOrder] = React.useState<"desc" | "asc">("desc");
     let  [MyPendingHireRequestApprovalsorderBy, setMyPendingHireRequestApprovalsOrderBy] = React.useState("");
     let  [MyPendingHireRequestApprovalstableData, setMyPendingHireRequestApprovalsTableData] = React.useState([]);
     const [MyPendingHireRequestApprovalspage, setMyPendingHireRequestApprovalsPage] = React.useState<number>(0);
     const [MyPendingHireRequestApprovalscount, setMyPendingHireRequestApprovalsCount] = useState<number>(0);
     const [MyPendingHireRequestApprovalsrowsPerPage, setMyPendingHireRequestApprovalssetRowsPerPage] = React.useState<number>(10);
     const [MyPendingHireRequestApprovalsOpen, setMyPendingHireRequestApprovalsOpen] = useState(false);
   
//EOF SOP-76 feedback points L2 (7)  Included By PCS


//SOP-76 feedback points L2 (7)  Included By PCS [Approved -Hire Requests]
    let  [MyApprovedHireRequestApprovalsorder, setMyApprovedHireRequestApprovalsOrder] = React.useState<"desc" | "asc">("desc");
    let  [MyApprovedHireRequestApprovalsorderBy, setMyApprovedHireRequestApprovalsOrderBy] = React.useState("");
    let  [MyApprovedHireRequestApprovalstableData, setMyApprovedHireRequestApprovalsTableData] = React.useState([]);
    const [MyApprovedHireRequestApprovalspage, setMyApprovedHireRequestApprovalsPage] = React.useState<number>(0);
    const [MyApprovedHireRequestApprovalscount, setMyApprovedHireRequestApprovalsCount] = useState<number>(0);
    const [MyApprovedHireRequestApprovalsrowsPerPage, setMyApprovedHireRequestApprovalssetRowsPerPage] = React.useState<number>(10);
    const [ViewHireRequestOpen, setViewHireRequestOpen] = useState(false);
    const [EditHireRequestOpen, setEditHireRequestOpen] = useState(false);
    const [HireRequestId, setHireRequestId] = useState()
//EOF SOP-76 feedback points L2 (7) Included By PCS



    // useSate
    let  [MyPendingApprovalsorder, setMyPendingApprovalsOrder] = React.useState<"desc" | "asc">("desc");
    let  [MyPendingApprovalsorderBy, setMyPendingApprovalsOrderBy] = React.useState("");
    let  [MyPendingApprovalstableData, setMyPendingApprovalsTableData] = React.useState([]);
    const [MyPendingApprovalspage, setMyPendingApprovalsPage] = React.useState<number>(0);
    const [MyPendingApprovalscount, setMyPendingApprovalsCount] = useState<number>(0);
    const [MyPendingApprovalsrowsPerPage, setMyPendingApprovalssetRowsPerPage] = React.useState<number>(10);
    const [MyPendingApprovalsOpen, setMyPendingApprovalsOpen] = useState(false);
    let  [MyApprovedorder, setMyApprovedOrder] = React.useState<"desc" | "asc">("desc");
    let  [MyApprovedorderBy, setMyApprovedOrderBy] = React.useState("");
    let  [MyApprovedtableData, setMyApprovedTableData] = React.useState([]);
    const [MyApprovedpage, setMyApprovedPage] = React.useState<number>(0);
    const [MyApprovedcount, setMyApprovedCount] = useState<number>(0);
    const [MyApprovedrowsPerPage, setMyApprovedsetRowsPerPage] = React.useState<number>(10);
    const [ViewOpen, setViewOpen] = useState(false);
    const [EditOpen, setEditOpen] = useState(false);
    
    const [RequestId, setRequestId] = useState()
    
    //  To open add dialouge box
        const handleApproveApprovalsPageOpen = (id:any) => {
        setMyPendingApprovalsOpen(true);
        setRequestId(id)
    };

    // SOP-76 feedback points L2 (7) Included By PCS [pending Hire requests]
         //  To open add dialouge box [hire request approval]
             const handleHireRequestApproveApprovalsPageOpen = (id:any) => {
                setMyPendingHireRequestApprovalsOpen(true);
                setHireRequestId(id)
                     };

        // To close add dialouge box [hire request approval]
             const handleApproveHireRequestApprovalsPageClose = () => {
                setMyPendingHireRequestApprovalsOpen(false);
                };
    //EOF SOP-76 feedback points L2 (7) Included By PCS 

   

    // To close add dialouge box
    const handleApproveApprovalsPageClose = () => {
    setMyPendingApprovalsOpen(false);
    };

    //  To open View dialouge box
    const handleViewOpen = (id:any) => {
        setViewOpen(true);
        setRequestId(id)
    };

    // To close View dialouge box
    const handleViewClose = () => {
    setViewOpen(false);
    };

    //  To open Edit dialouge box
    const handleEditOpen = (id:any) => {
        setEditOpen(true);
        setRequestId(id)
    };

    // To close Edit dialouge box
    const handleEditClose = () => {
    setEditOpen(false);
    };

  //SOP-76 feedback points L2 (7) Included By PCS  [approved Hire requests- View & Edit]
        //  To open View dialouge box
            const handleViewHireRequestOpen = (id:any) => {
                setViewHireRequestOpen(true);
                setHireRequestId(id)
            };

        // To close View dialouge box
            const handleViewHireRequestClose = () => {
                setViewHireRequestOpen(false);
            };

        //  To open Edit dialouge box
            const handleEditHireRequestOpen = (id:any) => {
                setEditHireRequestOpen(true);
                setHireRequestId(id)
            };

        // To close Edit dialouge box
        const handleEditHireRequestClose = () => {
                setEditHireRequestOpen(false);
            };
   //EOF SOP-76 feedback points L2 (7) Included By PCS  [approved Hire requests- View & Edit]


    // Head cell of My Pending Asset Request
    const MyPendingApprovalsHeadCell = [
        {
            id: "request_number",
            numeric: false,
            disablePadding: true,
            label: "Request #",
            isSort: true,
            isBold: true,
        },        
        {
            id: "request_date",
            numeric: false,
            disablePadding: false,
            label: "Request Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "fullname",
            numeric: false,
            disablePadding: false,
            label: "Requested by",
            isSort: true,
            isBold: true,
            },

        //SOP-91 Inlcuded By PCS [including subcategory and category in My Approval pending grid]
        {
            id: "categoryname",
            numeric: false,
            disablePadding: true,
            label: "Category",
            isSort: true,
            isBold: true,
        },
        {
            id: "subcategoryname",
            numeric: false,
            disablePadding: true,
            label: "Sub Category",
            isSort: true,
            isBold: true,
        },
     //EOF SOP-91 Inlcuded By PCS [including subcategory and category in My Approval pending grid]
     //SOP-100 Commented by PCS [removing asset column in My Approval pending grid]
        // {
        //     id: "assetname",
        //     numeric: false,
        //     disablePadding: true,
        //     label: "Asset Name",
        //     isSort: true,
        //     isBold: true,
        // },
    //EOF SOP-100 Commented by PCS [removing asset column in My Approval pending grid]
        {
        id: "request_remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        isSort: true,
        isBold: true,
        },
        {
        id: "Approve",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
        },
   ];
         
    // Head cell of My Asset Request
    const MyApprovedHeadCell = [
        {
            id: "request_number",
            numeric: false,
            disablePadding: true,
            label: "Request #",
            isSort: true,
            isBold: true,
        },
        {
            id: "request_date",
            numeric: false,
            disablePadding: false,
            label: "Request Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "fullname",
            numeric: false,
            disablePadding: false,
            label: "Requested by",
            isSort: true,
            isBold: true,
            },
        //SOP-91 Inlcuded By PCS [including subcategory and category in 'My Approval'-approved grid]
        {
            id: "categoryname",
            numeric: false,
            disablePadding: true,
            label: "Category",
            isSort: true,
            isBold: true,
        },
        {
            id: "subcategoryname",
            numeric: false,
            disablePadding: true,
            label: "Sub Category",
            isSort: true,
            isBold: true,
        },
      //EOF SOP-91 Inlcuded By PCS [including subcategory and category in 'My Approval'- approved  grid]
        {
            id: "assetname",
            numeric: false,
            disablePadding: true,
            label: "Asset Name",
            isSort: true,
            isBold: true,
        },
        {
        id: "request_remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        isSort: true,
        isBold: true,
        },
        {
        id: "request_approve_date",
        numeric: false,
        disablePadding: false,
        label: "Approved Date",
        isSort: true,
        isBold: true,
        dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
        id: "sys_approve_status_name",
        numeric: false,
        disablePadding: false,
        label: "Approved Status",
        isSort: true,
        isBold: true,
        },
        {
        id: "request_approve_remarks",
        numeric: false,
        disablePadding: false,
        label: "Approved Remaks",
        isSort: true,
        isBold: true,
        },
        {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
        },     
        ];
    
    //SOP-76 feedback points L2 (7) included By PCS [Head cell for Pending hire requests]
      const MyPendingHireRequestHeadCell = [
       
        {
            id: "hirerequest_hiring_jobcode_title",
            numeric: false,
            disablePadding: true,
            label: "Job Profile",
            isSort: true,
            isBold: true,
        },
        //SOP-96 Included By PCS [to include Requested By column in 'Hire request' grid]
        {
            id: "fullname",
            numeric: false,
            disablePadding: true,
            label: "Requested By",
            isSort: true,
            isBold: true,
        },
        //EOF SOP-96 Included By PCS [to include Requested By column in 'Hire request' grid]
        {
            id: "hirerequest_total_open_position",
            numeric: false,
            disablePadding: true,
            label: "Total Position",
            isSort: true,
            isBold: true,
        },
        {
            id: "departmentname",
            numeric: false,
            disablePadding: true,
            label: "Department",
            isSort: true,
            isBold: true,
        },
        {
            id: "employee_name",
            numeric: false,
            disablePadding: true,
            label: "Hiring Manager",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_employment_type_name",
            numeric: false,
            disablePadding: true,
            label: "Type of Employment",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_hirerequest_status_name",
            numeric: false,
            disablePadding: true,
            label: "Current Status",
            isSort: true,
            isBold: true,
        },
        {
            id: "Approve",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
            },
        ];
   //EOF SOP-76 feedback points L2 (7) included By PCS [Head cell for Pending hire requests]

  //SOP-76 feedback points L2 (7) included By PCS [Head cell for approved hire requests]
    const MyApprovedHireRequestHeadCell = [
        
        {
            id: "hirerequest_hiring_jobcode_title",
            numeric: false,
            disablePadding: true,
            label: "Job Profile",
            isSort: true,
            isBold: true,
        },
        {
            id: "hirerequest_total_open_position",
            numeric: false,
            disablePadding: true,
            label: "Total Position",
            isSort: true,
            isBold: true,
        },
        {
            id: "departmentname",
            numeric: false,
            disablePadding: true,
            label: "Department",
            isSort: true,
            isBold: true,
        },
        {
            id: "employee_name",
            numeric: false,
            disablePadding: true,
            label: "Hiring Manager",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_employment_type_name",
            numeric: false,
            disablePadding: true,
            label: "Type of Employment",
            isSort: true,
            isBold: true,
        },
        {
            id: "request_approve_date",
            numeric: false,
            disablePadding: false,
            label: "Approved Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
       {
            id: "sys_approve_status_name",
            numeric: false,
            disablePadding: false,
            label: "Approved Status",
            isSort: true,
            isBold: true,
       },
      {
            id: "request_approve_remarks",
            numeric: false,
            disablePadding: false,
            label: "Approved Remarks",
            isSort: true,
            isBold: true,
      },
      {
            id: "sys_hr_hirerequest_status_name",
            numeric: false,
            disablePadding: true,
            label: "Current Status",
            isSort: true,
            isBold: true,
        },
        {
          id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
            },
        ];
  //EOF SOP-76 feedback points L2 (7) Included By PCS [Head cell for approved hire requests]

    //  Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        // console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId
        const UserId = GlobalData.GlobalUserId;

    //SOP-96 Included By PCS
        let GlobalEmployeeId = GlobalData.GlobalEmployeeId
     
    // SOP-76  feedback points L2 (7) Included By PCS[ API for getting 'pending Hire Requests' approval list & 'approved Hire requests'  ]
       //[to get Pending Hire Requests ]
         const getHireRequestPendingApproval = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_hire_requests_my_pending_approval_list`, {
                    SortColumnName: MyPendingHireRequestApprovalsorderBy,
                    SortColumnDirection: MyPendingHireRequestApprovalsorder,
                    StartIndex: MyPendingHireRequestApprovalspage + 1,
                    PageSize: MyPendingHireRequestApprovalsrowsPerPage,
                    CompanyId:CompanyId,
                    //SOP-96 Modified By PCS [existing condition is modified to filter]
                    // UserId:UserId
                    UserId:GlobalEmployeeId
                    //SOP-96 Modified By PCS [existing condition is modified to filter]
                })
                .then((res: any) => 
                {
              
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.hirerequestid,
                    request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
                }
                }) 
                //  console.log("res hire pending is ..",res.data.data)
                setMyPendingHireRequestApprovalsTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setMyPendingApprovalsCount(0);
                }
                else
                {
                    setMyPendingHireRequestApprovalsCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setMyPendingHireRequestApprovalsTableData([]);
                }
                Failed(error.response.message);
                });
            };
        //EOF SOP-76 feedback points L2 (7) Included By PCS [to get Pending Hire Requests ]

        //[to get All  Hire Requests except pending ]
        const getHireRequestApprovedList = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_hire_requests_my_approved_approval_list`, {
                    SortColumnName: MyApprovedHireRequestApprovalsorderBy,
                    SortColumnDirection: MyApprovedHireRequestApprovalsorder,
                    StartIndex: MyApprovedHireRequestApprovalspage + 1,
                    PageSize: MyApprovedHireRequestApprovalsrowsPerPage,
                    CompanyId:CompanyId,
                    //SOP-96 Modified By PCS [existing condition is modified to filter]
                    // UserId:UserId
                    UserId:GlobalEmployeeId
                    //SOP-96 Modified By PCS [existing condition is modified to filter]
                })
                .then((res: any) => 
                {
            
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.hirerequestid,
                    request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
                    request_approve_date: item.request_approve_date ? dayjs(item.request_approve_date).format("DD-MM-YYYY") : "",

                }
                }) 
                //  console.log("res hire All request is ..",formattedTableData)
                
                 setMyApprovedHireRequestApprovalsTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                     setMyApprovedHireRequestApprovalsCount(0);
                }
                else
                {
                     setMyApprovedHireRequestApprovalsCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setMyApprovedHireRequestApprovalsTableData([]);
                }
                Failed(error.response.message);
                });
            };
        //EOF SOP-76 feedback points L2 Included By PCS [to get All  Hire Requests  except pending]
    //EOF  SOP-76 [ pending Hire Requests approval list & approval Hire requests API ]



        const getMyPendingApprovalsList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_requestanasset_my_approval_list`, {
                SortColumnName: MyPendingApprovalsorderBy,
                SortColumnDirection: MyPendingApprovalsorder,
                StartIndex: MyPendingApprovalspage + 1,
                PageSize: MyPendingApprovalsrowsPerPage,
                CompanyId:CompanyId,
                UserId:UserId
            })
            .then((res: any) => 
            {
           
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.requestid,
                request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
                
            }
            }) 
            // console.log("My Approvals List",formattedTableData)
            
            setMyPendingApprovalsTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setMyPendingApprovalsCount(0);
            }
            else
            {
                setMyPendingApprovalsCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setMyPendingApprovalsTableData([]);
            }
            Failed(error.response.message);
            });
        };

        // To get other approval list
        const getMyApprovedList = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approval_approved_list`, {
                    SortColumnName: MyApprovedorderBy || '',
                    SortColumnDirection: MyApprovedorder,
                    StartIndex: MyApprovedpage + 1,
                    PageSize: MyApprovedrowsPerPage,
                    CompanyId:CompanyId,
                    UserId:UserId
                })
                .then((res: any) => 
                {
    
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.requestid,
                    request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
                    request_approve_date: item.request_approve_date ? dayjs(item.request_approve_date).format("DD-MM-YYYY") : "",
                }
                }) 
            
                
                setMyApprovedTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setMyApprovedCount(0);
                }
                else
                {
                    setMyApprovedCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setMyApprovedTableData([]);
                }
                Failed(error.response.message);
                });
            };

        //SOP-76 feedback points L2 (7)included By PCS [Pending -Hire requests- functions]
             // Sort Handler
                const handleMyPendingHireRequestApprovalsIncidentRequestSort = (event: any, property: any) =>
                    {
                    const isAsc = MyPendingHireRequestApprovalsorderBy === property && MyPendingHireRequestApprovalsorder === "asc";
                    MyPendingHireRequestApprovalsorder = isAsc ? "desc" : "asc";
                    setMyPendingHireRequestApprovalsOrder(MyPendingHireRequestApprovalsorder);
                    MyPendingHireRequestApprovalsorderBy = property;
                    setMyPendingHireRequestApprovalsOrderBy(MyPendingHireRequestApprovalsorderBy);
                    };
             // Action function of table icons
                    const MyPendingHireRequestApprovalsactionFunction = (id: string, actionText: string) => 
                    {
                        if (actionText === "Approve") {
                            handleHireRequestApproveApprovalsPageOpen(id)
                        };
                    
                    };
             // Pagination Handler of table
                    const MyPendingHireRequestApprovalshandleChangePage = (event: unknown, newPage: number) => {
                        setMyPendingHireRequestApprovalsPage(newPage);
                    };

            // Rows Per page Handler
                    const MyPendingHireRequestApprovalshandleChangeRowsPerPage = (
                    event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setMyPendingHireRequestApprovalssetRowsPerPage(+event.target.value);
                            setMyPendingHireRequestApprovalsPage(0);
                    };

         //EOF SOP-76 feedback points L2 (7) included By PCS [Pending -Hire requests- functions]

        //SOP-76 feedback points L2(7)  included By PCS [Approved -Hire requests - functions]
             // Sort Handler
             const handleMyApprovedHireRequestApprovalsIncidentRequestSort = (event: any, property: any) =>
                {
                const isAsc = MyApprovedHireRequestApprovalsorderBy === property && MyApprovedHireRequestApprovalsorder === "asc";
                MyApprovedHireRequestApprovalsorder = isAsc ? "desc" : "asc";
                setMyApprovedHireRequestApprovalsOrder(MyApprovedHireRequestApprovalsorder);
                MyApprovedHireRequestApprovalsorderBy = property;
                setMyApprovedHireRequestApprovalsOrderBy(MyApprovedHireRequestApprovalsorderBy);
                };
            // Action function of table icons
                const MyApprovedHireRequestApprovalsactionFunction = (id: string, actionText: string) => 
                {
                
                    if (actionText === "view") {
                        handleViewHireRequestOpen(id)
                    };
                };

            // Pagination Handler of table
                const MyApprovedHireRequestApprovalshandleChangePage = (event: unknown, newPage: number) => {
                    setMyApprovedHireRequestApprovalsPage(newPage);
                };

            // Rows Per page Handler
                const MyApprovedHireRequestApprovalshandleChangeRowsPerPage = (
                event: React.ChangeEvent<HTMLInputElement>
                    ) => {
                        setMyApprovedHireRequestApprovalssetRowsPerPage(+event.target.value);
                        setMyApprovedHireRequestApprovalsPage(0);
                };

         //EOF SOP-76 feedback points L2 included By PCS [Approved -Hire requests - functions]


        // Sort Handler
        const handleMyPendingApprovalsIncidentRequestSort = (event: any, property: any) =>
            {
            const isAsc = MyPendingApprovalsorderBy === property && MyPendingApprovalsorder === "asc";
            MyPendingApprovalsorder = isAsc ? "desc" : "asc";
            setMyPendingApprovalsOrder(MyPendingApprovalsorder);
            MyPendingApprovalsorderBy = property;
            setMyPendingApprovalsOrderBy(MyPendingApprovalsorderBy);
            };
        
        
        // Action function of table icons
        const MyPendingApprovalsactionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "Approve") {
                handleApproveApprovalsPageOpen(id)
            };
            if (actionText === "edit") navigate(`/edit_nationality/${id}`);
        };
    
    
        // Pagination Handler of table
        const MyPendingApprovalshandleChangePage = (event: unknown, newPage: number) => {
            setMyPendingApprovalsPage(newPage);
        };
        
        // Rows Per page Handler
        const MyPendingApprovalshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setMyPendingApprovalssetRowsPerPage(+event.target.value);
                setMyPendingApprovalsPage(0);
        };

        // Sort Handler
        const handleMyApprovedIncidentRequestSort = (event: any, property: any) =>
            {
            const isAsc = MyApprovedorderBy === property && MyApprovedorder === "asc";
            MyApprovedorder = isAsc ? "desc" : "asc";
            setMyApprovedOrder(MyApprovedorder);
            MyApprovedorderBy = property;
            setMyApprovedOrderBy(MyApprovedorderBy);
            };
        
        
        // Action function of table icons
        const MyApprovedactionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "view") {
                handleViewOpen(id)
            };
        };
    
    
        // Pagination Handler of table
        const MyApprovedhandleChangePage = (event: unknown, newPage: number) => {
            setMyApprovedPage(newPage);
        };
        
        // Rows Per page Handler
        const MyApprovedhandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setMyApprovedsetRowsPerPage(+event.target.value);
                setMyApprovedPage(0);
        };

        useEffect(() => {
            getMyPendingApprovalsList();
            getMyApprovedList();
            getHireRequestPendingApproval();//SOP-76 feedback points L2 (7) [to get pending hire requests]
            getHireRequestApprovedList();//SOP-76 feedback points L2  (7) [to get all hire requests ]


        }, [MyPendingApprovalsrowsPerPage,MyPendingApprovalspage,MyPendingApprovalsorderBy,MyPendingApprovalsorder,
            MyApprovedrowsPerPage,MyApprovedpage,MyApprovedorderBy,MyApprovedorder,
            //SOP-76 feedback points L2 (7) inlcuded By PCS
            MyPendingHireRequestApprovalsrowsPerPage,MyPendingHireRequestApprovalspage,MyPendingHireRequestApprovalsorderBy,MyPendingHireRequestApprovalsorder,
            MyApprovedHireRequestApprovalsrowsPerPage,MyApprovedHireRequestApprovalspage,MyApprovedHireRequestApprovalsorderBy,MyApprovedHireRequestApprovalsorder
           //EOF SOP-76 feedback points L2 (7) inlcuded By PCS
           
        ]); 
                

    return(
        <>
        <MainContainerWrapper headingText='My Approvals'>
        {/*//SOP-76 feedback points L2 (7)included By PCS [included heading for grid]*/}
        <Box bgcolor={'white'} p={2} mt={3}>
                <Typography variant='h3'>
                Assets
                </Typography>
        {/* //EOF SOP-76 feedback points L2 (7) included By PCS */}
                    <EnhancedTable
                    headCells={MyPendingApprovalsHeadCell}
                    rows={MyPendingApprovalstableData}
                    rowsPerPage={MyPendingApprovalsrowsPerPage}
                    page={MyPendingApprovalspage}
                    handleChangePage={MyPendingApprovalshandleChangePage}
                    handleChangeRowsPerPage={MyPendingApprovalshandleChangeRowsPerPage}
                    handleRequestSort={handleMyPendingApprovalsIncidentRequestSort}
                    order={MyPendingApprovalsorder}
                    orderBy={MyPendingApprovalsorderBy}
                    actionFunction={MyPendingApprovalsactionFunction}
                    isView={false}
                    isDelete={false}
                    isEdit={false}
                    total={MyPendingApprovalscount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />
        </Box>
   
        {/* //SOP-76 feedback points L2 (7) included By PCS [included new grid for 'Hire requests' in 'My Approvals' grid]*/}
        <Box bgcolor={'white'} p={2} mt={3}>
                <Typography variant='h3'>
                Hire Requests
                </Typography>
                    <EnhancedTable
                    headCells={MyPendingHireRequestHeadCell} 
                    rows={MyPendingHireRequestApprovalstableData}
                    rowsPerPage={MyPendingHireRequestApprovalsrowsPerPage}
                    page={MyPendingHireRequestApprovalspage}
                    handleChangePage={MyPendingHireRequestApprovalshandleChangePage}
                    handleChangeRowsPerPage={MyPendingHireRequestApprovalshandleChangeRowsPerPage}
                    handleRequestSort={handleMyPendingHireRequestApprovalsIncidentRequestSort}
                    order={MyPendingHireRequestApprovalsorder}
                    orderBy={MyPendingHireRequestApprovalsorderBy}
                    actionFunction={MyPendingHireRequestApprovalsactionFunction}
                    isView={false}
                    isDelete={false}
                    isEdit={false}
                    total={MyPendingHireRequestApprovalscount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />
        </Box>
       {/* //EOF SOP-76 feedback points L2 (7) included By PCS [included new grid for 'Hire requests' in 'My Approvals' grid]*/}

    
            <Box mt={5}>
                <Typography variant='h1'>
                    My Approved List
                </Typography>
                        {/* //SOP-76 feedback points L2 (7) modified By PCS [included heading for grid] */}
                        {/* <Box mt={3} > */}
                        <Box bgcolor={'white'} p={2} mt={3}>
                        <Typography variant='h3'>
                            Assets
                        </Typography>
                        {/* //EOF SOP-76 feedback points L2 (7) modified By PCS [included heading for grid] */}
                            <EnhancedTable
                            headCells={MyApprovedHeadCell}
                            rows={MyApprovedtableData}
                            rowsPerPage={MyApprovedrowsPerPage}
                            page={MyApprovedpage}
                            handleChangePage={MyApprovedhandleChangePage}
                            handleChangeRowsPerPage={MyApprovedhandleChangeRowsPerPage}
                            handleRequestSort={handleMyApprovedIncidentRequestSort}
                            order={MyApprovedorder}
                            orderBy={MyApprovedorderBy}
                            actionFunction={MyApprovedactionFunction}
                            isView={true}
                            isDelete={false}
                            isEdit={false}
                            total={MyApprovedcount}
                            isSearch={false}
                            HoverColor={'#E7F1FB'}
                            />
                        </Box>
                        {/* //SOP-76 feedback points L2 (7) modified By PCS [included new grid for hire requests in 'My Approved List' */}
                        <Box bgcolor={'white'} p={2} mt={3}>
                        <Typography variant='h3'>
                            Hire Requests
                        </Typography>
                            <EnhancedTable
                             headCells={MyApprovedHireRequestHeadCell} 
                             rows={MyApprovedHireRequestApprovalstableData}
                             rowsPerPage={MyApprovedHireRequestApprovalsrowsPerPage}
                             page={MyApprovedHireRequestApprovalspage}
                             handleChangePage={MyApprovedHireRequestApprovalshandleChangePage}
                             handleChangeRowsPerPage={MyApprovedHireRequestApprovalshandleChangeRowsPerPage}
                             handleRequestSort={handleMyApprovedHireRequestApprovalsIncidentRequestSort}
                             order={MyApprovedHireRequestApprovalsorder}
                             orderBy={MyApprovedHireRequestApprovalsorderBy}
                             actionFunction={MyApprovedHireRequestApprovalsactionFunction}
                             isView={true}
                             isDelete={false}
                             isEdit={false}
                             total={MyApprovedHireRequestApprovalscount}
                             isSearch={false}
                             HoverColor={'#E7F1FB'}
                            />
                        </Box>
                         {/* //EOF SOP-76 feedback points L2 (7) modified By PCS [included new grid for hire requests in 'My Approved List '*/}


            </Box>
       
            {/* Approve Approval dialog box */}
            <Box>
                <Dialog  open={MyPendingApprovalsOpen}  >
                    <ApproveApprovalsPage 
                    handleApproveApprovalsPageClose={handleApproveApprovalsPageClose}
                    getMyPendingApprovalsList={getMyPendingApprovalsList}
                    RequestId={RequestId}
                    getMyApprovedList={getMyApprovedList}
                    />
                </Dialog>
            </Box>
            {/* End of Approve Approval */}

            {/* Approve Approval dialog box */}
            <Box>
                <Dialog  open={ViewOpen}  >
                    <ViewApproveApprovalsPage 
                    handleViewClose={handleViewClose}
                    handleEditOpen={handleEditOpen}
                    RequestId={RequestId}
                    />
                </Dialog>
            </Box>
            {/* End of Approve Approval */}

            {/* Approve Approval dialog box */}
            <Box>
                <Dialog  open={EditOpen}  >
                    <EditApproveApprovalsPage 
                    handleEditClose={handleEditClose}
                    getMyPendingApprovalsList={getMyPendingApprovalsList}
                    RequestId={RequestId}
                    getMyApprovedList={getMyApprovedList}
                    />
                </Dialog>
            </Box>
            {/* End of Approve Approval */}


            {/* SOP-76 feedback points  L2 (7) included By PCS [Hire Request- Pending Approval - dialog box ] */}
                <Box>
                    <Dialog  open={MyPendingHireRequestApprovalsOpen}  
                    
                    //SOP-96 Included By PCS [to increase dialog box width]
                    fullWidth
                    maxWidth="lg" 
                    sx={{
                     '@media (max-width:600px)': {
                        maxWidth: '90%', // You can control the max width for mobile screens
                        margin: 0,
                    },
                    //EOF SOP-96 Included By PCS [to increase dialog box width]
                      }}>
                        <HireRequestApprovalsPage 
                        handleApproveApprovalsPageClose={handleApproveHireRequestApprovalsPageClose}
                        getMyPendingApprovalsList={getHireRequestPendingApproval}
                        HireRequestId={HireRequestId}
                        getMyApprovedList={getHireRequestApprovedList}
                        
                       
                        />
                    </Dialog>
                </Box>
            {/* EOF SOP-76 feedback points L2 (7) included By PCS [Hire Request- Pending Approval - dialog box ] */}


             {/* SOP-76 feedback points L2 (7) included By PCS [Hire requests - Approved - VIEW] */}
             <Box>
                <Dialog  open={ViewHireRequestOpen} 
                //SOP-96 Included By PCS [to increase dialog box width]
                fullWidth
                maxWidth="lg" 
                sx={{
                '@media (max-width:600px)': {
                    maxWidth: '90%', 
                    margin: 0,
                },}}
                //EOF SOP-96 Included By PCS [to increase dialog box width]
                 >
                    <ViewHireRequestApprovalsPage 
                    handleViewClose={handleViewHireRequestClose}
                    // handleEditOpen={handleEditHireRequestOpen}
                    // RequestId={HireRequestId}
                    HireRequestId={HireRequestId}
                    />
                </Dialog>
            </Box>
           {/* EOF SOP-76 feedback points L2 (7) included By PCS [Hire requests - Approved - VIEW] */}

           {/* SOP-76 feedback points L2 (7) included By PCS [Hire requests - Approved - EDIT] */}
            <Box>
                <Dialog  open={EditHireRequestOpen}  >
                    <EditHireRequestApprovalsPage 
                    handleEditClose={handleEditHireRequestClose}
                    getMyPendingApprovalsList={getHireRequestPendingApproval}
                    RequestId={HireRequestId}
                    getMyApprovedList={getHireRequestApprovedList}
                    />
                </Dialog>
            </Box>
         {/* EOF SOP-76 feedback points L2 (7) included By PCS [Hire requests - Approved - EDIT] */}

        </MainContainerWrapper>
        
        </>
    )
}
export default MyApprovalsPage;


// End of SOP-29