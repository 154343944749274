
// SOP-61 - Included By PCS 

import { useState,useRef,useEffect ,useCallback } from 'react'; // Import useState hook
import React from 'react';

import { EditorState, ContentState, convertToRaw,RawDraftContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'; //TO convert to html format
import htmlToDraft from 'html-to-draftjs';
import {
        Box, 
        Button,
        Grid,
        Paper,
        Dialog,
        Accordion,
        AccordionSummary,
        AccordionDetails,
        FormControl,
        FormHelperText,
       
    } from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import CryptoJS from 'crypto-js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MdContentPaste } from "react-icons/md";
import { useParams } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";

 const EditDocument =()=>{

    const { title } = useParams<{ title?: string }>(); // Ensure title is optional and can be undefined
    const navigate = useNavigate();
   
    //To save the typed  content
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [displayedContent, setDisplayedContent] = useState<string>('');

    //SOP-102 Included by PCS [to check if uploaded type is Image/Pdf]
    const [uploadedImage, setUploadedImage] =  useState(false); 
    const [uploadedPdf, setUploadedPdf] =  useState(false)
    const [DocumentType, setDocumentType] =  useState(null)
    //EOF SOP-102 Included by PCS [to check if uploaded type is Image/Pdf]    
    const [DocumentTitle, setDocumentTitle] =  useState(''); //Initialize document Title
    const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
    const VersionNumberFromSession:any = sessionStorage.getItem("VersionNumber") ;

    //SOP-102 Included by PCS [UseState declaration]
        const[UploadDocumentData,setUploadDocumentData]=useState(''); //Store Document From db
        const [file, setFile] = useState(null);
        const [fileURL, setFileURL] = useState('');
        const [fileType, setFileType] = useState('');
        const [imageSrclink, setImageSrclink] = useState<string>('');
        const [imageUrl, setImageUrl] = useState<any>(null);
        const [fileName, setFileName] = useState('');      
    
    //SOP- 102 Included by PCS [To Check extension of uploaded document[image/pdf]
        const checkDataUrlType = (dataUrl:string) =>
          {
            const extensionType = dataUrl.split(';')[0].split(':')[1];
            if (extensionType.startsWith('image/')) {
            setUploadedImage(true)
            } 
            else if (extensionType === 'application/pdf') {
            setUploadedPdf(true)
            }      
          };
    //EOF SOP- 102 Included by PCS [To Check extension of uploaded document[image/pdf]

    //To Format the content  
        const handleDisplayContent = (event:any) => {
             
                const contentState = editorState.getCurrentContent();
                const rawContent = convertToRaw(contentState);
                const htmlContent = draftToHtml(rawContent);
                setDisplayedContent(htmlContent); // Store HTML content in state
                // console.log('3',contentState)
                // console.log('4',rawContent)
                // console.log('5',htmlContent)
                CreateDocPoint(event,htmlContent)
                                            };
                // console.log('1',editorState)
                // console.log('2',displayedContent)
    //EOF To save the typed  content   

// To get document content by version and document id..

//Initializing values to get response and store to get payload values..
  const[ResponseDocumentId,setResponseDocumentId]=useState();
  const[ResponseTitleName,setResponseTitleName]=useState();
  const[ResponseCategoryId,setResponseCategoryId]=useState();
  const[ResponseAuthorId,setResponseAuthorId]=useState();
  const[ResponseCreatedBy,setResponseCreatedBy]=useState();
  const[ResponseCreatedDateTime,setResponseCreatedDateTime]=useState();


const EditDocumentById =()=>{
      const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
        const payload={
            DocumentId:DocumentIdFromSession,
            VersionNumber:VersionNumberFromSession,
            CompanyId:CompanyId
                }
     commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_edit_document_by_documentid`, payload)
        .then((res: any) => 
        { 
        
        const DocumentData = res.data.data[0]
 //To bind the repsonse  values to the payload values...
      //SOP-102 Inlcuded By PCS   [to set Uploaded document data url from DB]    
        setUploadDocumentData(DocumentData.upload_document)  
        //Check the file type of the uploaded document     
        if(DocumentData.upload_document)
        {
            checkDataUrlType(DocumentData.upload_document)
        }
        setDocumentType(DocumentData.document_type)  //to set document type[1/2 from DB result]               
      //EOF SOP-102 Inlcuded By PCS  [to set Uploaded document data url from DB]   

      setResponseDocumentId(DocumentData.documentid)
      setResponseTitleName(DocumentData.document_title_name)
      setResponseCategoryId(DocumentData.document_docpoint_categoryid)
      setResponseAuthorId(DocumentData.document_author_id)
      setResponseCreatedBy(DocumentData.createdby)
      setResponseCreatedDateTime(DocumentData.createddatetime)

      setDisplayedContent(DocumentData.docpoint_content)//SOP-102 Included by PCS
        const contentBlock = htmlToDraft(DocumentData.docpoint_content);
        if (contentBlock) {
            const { contentBlocks, entityMap } = contentBlock;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }

        }).catch((error) =>  
        {  
            console.log("error",error)
        Failed(error.response.message);
        });
 }

  // Define type for errors
    type Errors = {
        DocumentTitle?:boolean;
                };

  // Updating Document RTE  
  const CreateDocPoint =async (event:any,content:any)=>
        {
          event.preventDefault();
          let errors: Errors = {};

    //Document Title
         if (Object.keys(errors).length > 0) {
          // If there are errors, update the state with all the errors
            console.log("errors",errors)
               return;}
           const PayloadUpdateDocPoint={

                SaveUpdateKey:'update',
                CompanyId:CompanyId,
                DocumentId:ResponseDocumentId,
                SequenceNumber:null,
                TitleName:ResponseTitleName,
                VersionNumber:'',
                EffectiveFrom:null,
                CategoryId:ResponseCategoryId,
                AuthorId:ResponseAuthorId,
                isCancelled:null,
                CreatedBy:ResponseCreatedBy,
                CreatedDateTime:ResponseCreatedDateTime,
                lastModifiedBy:UserId,
                lastModifiedDate:null,
                Content:content,
               //SOP-102 Included by PCS ['Document type' & 'Upload document' is included]
                DocumentType:1, //Document Type is 1 for creating new document
                UploadDocument:'' //UploadDocument is '' for creating new document
              //EOF SOP-102 Included by PCS ['Document type' & 'Upload document' is included]
            }
        // console.log("UpdateDocPoint",PayloadUpdateDocPoint)
      
          commonService
              .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/docpoint_document_create_update_delete`, PayloadUpdateDocPoint)
              .then((res) => {
                if (res.status === 200) {
                    Success("Document Updated successfully");
                    navigate('/docpoint')
                }
                }).catch((error) => {
                  Failed("Document Updation failed ");
                    console.error("Error:", error);
                });
     
        }

// ---------Updating Image/Pdf------------
const UpdateDocument =async (event:any)=>
{
  event.preventDefault()
  // Check if the file is empty
      if (!imageSrclink) {
        Swal.fire({
          html: '<span style="font-size: 1.3rem;">Please select a file to upload</span>',
          confirmButtonText: 'OK',
          });
        return;
      }
    let errors: Errors = {};

//Document Title
     if (Object.keys(errors).length > 0) {
        // If there are errors, update the state with all the errors
        
         console.log("errors",errors)
           return;}
   const PayloadUpdateDocPoint={
            SaveUpdateKey:'update',
            CompanyId:CompanyId,
            DocumentId:ResponseDocumentId,
            SequenceNumber:null,
            TitleName:ResponseTitleName,
            VersionNumber:'',
            EffectiveFrom:null,
            CategoryId:ResponseCategoryId,
            AuthorId:ResponseAuthorId,
            isCancelled:null,
            CreatedBy:ResponseCreatedBy,
            CreatedDateTime:ResponseCreatedDateTime,
            lastModifiedBy:UserId,
            lastModifiedDate:null,
            Content:'', //Content is '' for uploading new document   
            DocumentType:2, //Document Type is 2 for uploading new document
            UploadDocument:imageSrclink       
        } 
      commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/docpoint_document_create_update_delete`, PayloadUpdateDocPoint)
          .then((res) => {
            if (res.status === 200) {
                Success("Document Updated successfully");
                navigate('/docpoint')
            }
            }).catch((error) => {
              Failed("Document Updation failed ");
                console.error("Error:", error);
            });
 
    }

//  Global data from session storage
      const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
      const GlobalData = JSON.parse(GlobalDataFromSession);
      let CompanyId = GlobalData.GlobalCompanyId
      const UserId = GlobalData.GlobalUserId;
      let EmployeeId = GlobalData.GlobalEmployeeId
      let UserName = GlobalData.GlobalUserName
  
//SOP-102 Included By PCS [ Handle file upload and preview]
const handleFileUpload = (event:any) =>
{
    const uploadedFile = event.target.files[0];   
    const maxSize = 3 * 1024 * 1024; // 5MB in bytes
    if (uploadedFile && uploadedFile.size > maxSize) {
      Swal.fire({
            html: '<span style="font-size: 1.3rem;">File size exceeds the 2MB limit. Please upload a smaller file</span>',
            confirmButtonText: 'OK',
            });
        event.target.value = null; // Reset the input to avoid submitting the large file
        return; 
      }

    if (uploadedFile) 
    {
      setFileName(uploadedFile.name);//to display file name and  remove icon 
        // Set file URL for preview
        const fileUrl = URL.createObjectURL(uploadedFile);
        setFile(uploadedFile);
        setFileURL(fileUrl);
        
        // Determine file type for conditional rendering
        if (uploadedFile.type.startsWith('image/')) 
        {
            const reader = new FileReader();
            reader.onloadend = () => 
            {
            // 'reader.result' contains the Base64 data URL of the image
            const imageDataUrl = reader.result;
            setImageSrclink(String(imageDataUrl))
            setImageUrl(imageDataUrl); // Set the state with the Base64 data URL if needed
          
            };
            reader.readAsDataURL(uploadedFile);
            setFileType('image');
        } 
      else if (uploadedFile.type === 'application/pdf') 
      {
          const reader = new FileReader();
          reader.onloadend = () => 
          {
          // 'reader.result' contains the Base64 data URL of the image
          const imageDataUrl = reader.result;
          setImageSrclink(String(imageDataUrl))
          setImageUrl(imageDataUrl); // Set the state with the Base64 data URL if needed
        
          };
          reader.readAsDataURL(uploadedFile);
          setFileType('pdf');
      }
      }

  };

  //SOP-102 Included by PCS [To Remove Attched files]
  const handleRemoveFile = () => {
    setFile(null);          // Reset the file
    setFileName("");        // Clear the file name
    setFileURL("");         // Clear the preview URL
    setFileType("");        // Clear the file type
    setImageSrclink('')
  };

   useEffect(() => {

    EditDocumentById();
    const DocumentTitleFromSession: any=sessionStorage.getItem("DocumentTitleValue")
    setDocumentTitle(DocumentTitleFromSession)}, []); 

//To display created  Date
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Day}-${Month}-${Year}`;

//SOP-102 Commented by PCS [old Design is changed]       
//     return(
//         // <MainContainerWrapper headingText={title?title :''}>
//         <MainContainerWrapper headingText={DocumentTitle}>
//         <Box>
//         <form noValidate>
//         <Box>
// {/*Main grid starts */}        
//         <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                 
// {/* grid starts */}
//         <Grid item lg={11} md={12} xs={12} >
//                     {/* Content RTE Accordian */}
//                         <Box mt={1}>
//                         <Accordion defaultExpanded sx={{borderRadius:'30px',}}>
//                                 <AccordionSummary
//                                     expandIcon={<ArrowDropDownIcon />}
//                                     aria-controls="panel1-content"
//                                     id="panel1-header"
//                                 >
//                                 <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
//                                     <MdContentPaste  height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
//                                     <Typography fontSize={'1rem'} ml={2}>Content</Typography> 
//                                 </Box>
//                                 </AccordionSummary>
//                                 <AccordionDetails sx={{marginBottom:5}}>
// </AccordionDetails>
// </Accordion>
// </Box>
// </Grid>
// {/*  grid ends */}
//         </Grid>
// {/*Main grid starts */}    
                    
//         <Box>                    
//             <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
//                     <Button
//                         sx={{
//                             background: 'white',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding:{
//                                 lg: "0 70px",
//                                 md: "0 70",
//                                 xs:'0 50px'
    
//                             },
//                             height: "3rem",
//                             boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                         }}

//                         onClick={()=>{
//                             navigate('/docpoint')
//                         }} 
//                     >
//                      Back
//                     </Button>

//                     <Button
//                         sx={{
//                             background: '#25425F',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding:{
//                                 lg: "0 70px",
//                                 md: "0 70",
//                                 xs:'0 50px'
//                             },
//                             "&:hover":{
//                                 backgroundColor:'#25425F',
//                                 color:'white',
//                             },
//                             color: 'white',
//                             height: "3rem",
//                             boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                         }}
//                         type='submit'

//             //SOP-102 Modified by PCS        
//                 onClick={(event)=>{
//                         if(DocumentType===1){
//                            handleDisplayContent(event);
//                             }
//                         else if(DocumentType===2){
//                             UpdateDocument(event)
//                             }
//                          }}                        
//             //EOF SOP-102 Modified by PCS        
//                     >
//                     Update
//                     </Button>
//             </Box>
//         </Box>

//     </Box>
//     </form>
//     </Box>
// </MainContainerWrapper>)

//SOP-102 Included by PCS [New Design]   

return (
<MainContainerWrapper headingText={DocumentTitle}>
<Box>
<form noValidate>
<Box>
{/* Main grid starts */}
{/* --Document type=1(new) */}
{DocumentType === 1 && (
<Grid container spacing={1} display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
{/* -------------First Grid Upload New Image/Pdf starts---------------  */}
{/* ------------Second grid -dispaly section starts------------- */} 
<Grid item lg={12} md={12} sm={12} xs={12}>
{/* --------Display section --------*/}
<Box>
<Accordion defaultExpanded sx={{ borderRadius: '30px' }}>
<AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
>
<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
{/* <MdContentPaste height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> */}

</Box>
</AccordionSummary>
<AccordionDetails sx={{ marginBottom: 5 }}>
{/* RTE ,Upload contents dispalyed */}
    <Box>
    {displayedContent ? (
        <Box style={{ width: '95%', marginLeft: '3%', marginTop: '1%', height: '90%' }}>
        <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorStyle={{ backgroundColor: '#f0f0f0', marginLeft: '4px', height: '725px' }}
            toolbarHidden={true}
        />
        </Box>  
    ):''}
   </Box>
{/* RTE ,Upload contents dispalyed */}
</AccordionDetails>
</Accordion>
</Box>
</Grid>

</Grid>
 )}
{/* ---Document Type=2(upload) */}
{DocumentType === 2 && (
<Grid container spacing={1} display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
{/* -------------First Grid Upload New Image/Pdf starts---------------  */}

<Grid item lg={12} md={12} sm={12} xs={12}>
<Box>
<Accordion defaultExpanded sx={{ borderRadius: '30px' }}>
<AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
>
{/*Upload Section */}
<Box display="flex" justifyContent="space-between" alignItems="flex-start">
<Typography variant="h2">Upload New Image or PDF</Typography>
</Box>
</AccordionSummary>

<AccordionDetails sx={{ marginBottom: 5 }}>
<form>
<div style={{ display: 'flex', width: '80%' }}>
<div style={{width:'35%'}}>
    <input
        type="file"
        accept="image/*,application/pdf"
        onChange={handleFileUpload}
        style={{
            display: 'none', // Hide the default file input
          }}
          id="fileUploadInput"
    />

<label 
  htmlFor="fileUploadInput" 
  style={{
    padding: '8px 20px',
    fontSize: '14px',
    backgroundColor: 'lightblue', 
    color: 'darkcyan ',
    border: '2px solid gainsboro  ',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 0.3s, border-color 0.3s',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }}
>
<ArticleIcon></ArticleIcon>
<Typography sx={{ 
    color: 'darkcyan',
    "@media (max-width: 600px)": { fontSize: "12px" },
    "@media (max-width: 768px)": { fontSize: "12px" },
    "@media (max-width: 1024px)": { fontSize: "12px" },
    "@media (min-width: 1025px) and (max-width: 1280px)": { fontSize: "16px" },
    "@media (min-width: 1281px) and (max-width: 1600px)": { fontSize: "18px" },
    "@media (min-width: 1601px) and (max-width: 1920px)": { fontSize: "19px" },
    "@media (min-width: 1921px)": { fontSize: "19px" },  
    '&:hover':{
      color:'#fff'
  } 
}}>Choose a file</Typography>
</label>
</div>

 {/* Display selected file name next to the button */}
 <div style={{ marginLeft: '0.5%',marginTop: '1%',
  fontSize: '16px', 
  color: '#333' }}>
  {fileName && <span>{fileName}</span>}
  </div>
  <div style={{ marginLeft: '0.5%',marginTop:'1%'}}>
 {/* to remove selected file */}
  {fileName && (
  <CloseIcon
    style={{ cursor: 'pointer', color: 'red'}}
    onClick={handleRemoveFile}
  />
)}
</div>
</div>
</form>

{/* Preview Section */}
<Box className="preview-container" style={{ marginTop: '20px' }}>
{fileType === 'image' && file && (
<div id="imageSection" className="image-preview">
    <img src={fileURL} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
</div>
)}

{fileType === 'pdf' && file && (
<div id="pdfSection" className="pdf-preview">
    <iframe src={fileURL} title="PDF Preview" style={{ width: '100%', height: '500px' }} />
</div>
)}
</Box>
{/* Preview Section ends */}
</AccordionDetails>
</Accordion>
</Box>
</Grid>
{/* ----------------Divider------------------ */}
 {/* <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
    <Divider style={{color:'red'}} orientation="vertical" flexItem />
</Grid> */}

{/* ------------Second grid -dispaly section starts------------- */} 
<Grid item lg={12} md={12} sm={12} xs={12}>
{/* --------Display section --------*/}
<Box>
<Accordion defaultExpanded sx={{ borderRadius: '30px' }}>
<AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
>
<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
{/* <MdContentPaste height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> */}
<Typography variant="h2">Preview of Current Document</Typography>
</Box>
</AccordionSummary>
<AccordionDetails sx={{ marginBottom: 5 }}>
{/* RTE ,Upload contents dispalyed */}
    <Box>
    {displayedContent ? (
        <Box style={{ width: '95%', marginLeft: '3%', marginTop: '1%', height: '90%' }}>
        <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorStyle={{ backgroundColor: '#f0f0f0', marginLeft: '4px', height: '725px' }}
            toolbarHidden={true}
        />
        </Box>  
    ) : UploadDocumentData && uploadedImage === true ? (
        <Box style={{ textAlign: 'center' }}>
        <img src={UploadDocumentData} alt="Uploaded Document" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
    ) : UploadDocumentData && uploadedPdf === true ? (
        <Box style={{ textAlign: 'center' }}>
        <iframe src={UploadDocumentData} title="Uploaded PDF" width="100%" height="600px"></iframe>
        </Box>
    ) : (
        <Typography>No content available</Typography>
    )}
    </Box>
{/* RTE ,Upload contents dispalyed */}
</AccordionDetails>
</Accordion>
</Box>
</Grid>

</Grid>
)}

{/* ------------Main Grid ends------------- */} 

{/*---------------- Back & Save -----------*/}
<Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
<Button
    sx={{
        background: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: { lg: "0 70px", md: "0 70", xs: '0 50px' },
        height: "3rem",
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    }}
    onClick={() => {
        navigate('/docpoint')
    }}
>
Back
</Button>
<Button
sx={{
    background: '#25425F',
    border: 'none',
    borderRadius: '5px',
    padding: { lg: "0 70px", md: "0 70", xs: '0 50px' },
    "&:hover": {
    backgroundColor: '#25425F',
    color: 'white',
    },
    color: 'white',
    height: "3rem",
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
}}
type='submit'
onClick={(event) => {
    if (DocumentType === 1) {
    handleDisplayContent(event);
    } else if (DocumentType === 2) {
    UpdateDocument(event)
    }
}}
>
Update
</Button>
</Box>
{/*---------------- Back & Save ends-----------*/}
</Box>
</form>
</Box>
</MainContainerWrapper>
  );
  
}
//SOP-102 Included by PCS [new design for edit page]
export default EditDocument;   
{/* SOP-61 - PCS */}