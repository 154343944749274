import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, Typography, useMediaQuery } from '@mui/material';
// import {AppBarBackground} from '../../../asset/images/appbarbg.png';

// project imports
import Sidebar from './Sidebar';
import { drawerWidth } from '../../../features/theme/constant';
import { SET_MENU } from '../../../features/theme/actions';
import Header from './Header';
import '../MainLayout/Sidebar/sidemenu.scss'
import LogoSection from "../MainLayout/LogoSection";
import Footer from './Footer';


// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: { theme: any, open: any}) => ({
    ...theme.typography.mainContent,
 
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor:'#E7F1FB',
        //height: `calc(100vh - ${110}px)`,
       transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        backgroundColor:'#E7F1FB',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // Handle left drawer
    const leftDrawerOpened = useSelector((state: any) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    return (    
    // SOP-101 Modified by PCS [Revamping of header]
    //  <Box sx={{display:'flex',alignItems:"center",bgcolor:'#E7F1FB'}}>   
    <Box sx={{ display: 'flex',alignItems: 'center',
             // bgcolor: '#F5F4FB', // Correct background color
            //  width:'100%',
            //  height: '100%',             
     }}> 
   {/* //EOF SOP-101 Modified by PCS [Revamping of header]*/}
    <CssBaseline />
    <AppBar 
        className='appbar-backgorund'
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
            sx={{
            bgcolor:'white',
            //SOP-101 Modified by PCS[Revamping of Header]
            // height:"90px",
                height:'10%',
            //EOF//SOP-101 Modified by PCS[Revamping of Header]
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
            boxShadow:"rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
            }}
            >
            <Toolbar>
                <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </Toolbar>
    </AppBar>

    <Sidebar window={window} drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
    
    <Main theme={theme} open={leftDrawerOpened}>
        <Outlet/>  
        <Footer />
      </Main>
   
 </Box>
);

};

export default MainLayout;
