// Included by sanjana SOP-37 to create ViewAdd ticketing Services page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
    FormControl //SOP-99 Inlcuded by PCS
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
//SOP-99 Included by PCS
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ViewTicketingServicePage = // To get handleAddClose & getPendingTicketingServicesList from catgories page
({
    handleViewClose,
    handleEditOpen,
    TicketId
}: {
    handleViewClose: (event: React.MouseEvent) => void,
    handleEditOpen: (event: React.MouseEvent) => void,
    TicketId:any
})=>{

    // use state
    const [ServiceRemarksError, setServiceRemarksError]=useState(false)
    const [ServiceRemarksValue, setServiceRemarksValue]=useState('')
    const [ServiceRemarksErrorMessage, setServiceRemarksErrorMessage]=useState('')
    const [CompletionsStsOptions, setCompletionsStsOptions] = useState<OptionType[]>([]);
    const [selectedCompletionsStsId, setSelectedCompletionsStsId] = useState<any | null>('');
    const [CompletionsStsError, setCompletionsStsError]=useState(false)
    const [CompletionsStsErrorMessage, setCompletionsStsErrorMessage]=useState('')
    const [AssetOptions, setAssetOptions] = useState<OptionType[]>([]);
    const [selectedAssetId, setSelectedAssetId] = useState<any | null>('');
    const [CompletionsSts, setCompletionsSts] = useState('')


    // SOP-70 Included By PCS [Vendor Ticket Id]  
        const [VendorTicketIdError, setVendorTicketIdError]=useState(false)
        const [VendorTicketIdValue, setVendorTicketIdValue]=useState('')
        const [VendorTicketIdErrorMessage, setVendorTicketIdErrorMessage]=useState('')
    // SOP-70 Included By PCS Vendor Ticket Id

    // SOP-80 Included By PCS [ServiceCompletionstatus]  
        const [ServiceCompletionstatus, setServiceCompletionstatus]=useState('')
    //EOF SOP-80 Included By PCS [ServiceCompletionstatus]  

     //SOP-72 Included By PCS []
     const [ClosureInformationOptions, setClosureInformationOptions] =useState<OptionType[]>([]);
     const [selectedClosureInformationId, setselectedClosureInformationId] = useState<string | null>('');
     const [ClosureInformationError, setClosureInformationError]=useState(false)
     const [ClosureInformationErrorMessage, setClosureInformationErrorMessage]=useState('')
    //EOF SOP-72 Included By PCS

    //SOP-99 Included By PCS [new fields usestate declaration]
    const [ShortDiscriptionValue, setShortDiscriptionValue] = useState(''); 
    const [DiscriptionValue, setDiscriptionValue] = useState(''); 
    const [LocationValue, setLocationValue] = useState('')  
    const [TicketIdValue, setTicketIdValue] = useState('')   
    const [TicketstartDateValue, setTicketstartDateValue] = useState<dayjs.Dayjs | null>(null);
    const [selectedIssueType, setSelectedIssueType] = useState('')   
    const [selectedIssueSubType, setSelectedIssueSubType] =  useState('')   
    const [selectedContactType, setSelectedContactType] = useState('')
    const [selectedPriorityType, setSelectedPriorityType] = useState('')
    const [RaisedbyEmployee, setRaisedbyEmployee] = useState('')   
    const [selectedImpactType, setSelectedImpactType] = useState('')   
    const [AllocatedEmployee, setAllocatedEmployee] = useState('') 
   //EOF SOP-99 Included By PCS [new fields usestate declaration]

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;

    //  Handle Allocations while typing Allocations and validations
    const handleServiceRemarksChange = (event:any) => {
        setServiceRemarksValue(event.target.value);
        const trimmedServiceRemarksValue =  event.target.value
        if( trimmedServiceRemarksValue.length !== 255 ){
            setServiceRemarksError(false);
            setServiceRemarksErrorMessage("");
        }
    };

    //SOP-70 Included by PCS
        //  Handle Vendor Ticket Id while typing   
        const handleVendorTicketIdChange = (event:any) => {
            setVendorTicketIdValue(event.target.value);
            const trimmedVendorTicketValue =  event.target.value
            if( trimmedVendorTicketValue.length > 255 ){
                setVendorTicketIdError(false);
                setVendorTicketIdErrorMessage("");
            }
        };
    //EOF SOP-70 Included by PCS

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleViewClose && typeof handleViewClose === 'function') {
            handleViewClose(event);
        }
    };

    // to render handleEditOpen function after closing dialouge box
    const EditServicesOpen= (event: React.MouseEvent) => {
        // if(handleEditOpen && typeof handleEditOpen === 'function' && CompletionsSts === "Y"){
        //     Swal.fire({
        //         html: '<span style="font-size: 1.3rem;">Ticket is already completed, You cannot edit</span>',
        //         confirmButtonText: 'OK',
        //         });
        // }
        // else if(handleEditOpen && typeof handleEditOpen === 'function'){
        //     handleEditOpen(TicketId)
        // }
        handleEditOpen(TicketId)
    };

    // CompletionsSts Dropdown API
    const GetCompletionsStsList = async () => {
        const CompletionsStsListPayload ={
            CompanyId:CompanyId,
            active:"Y"
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_completion_status_list`,CompletionsStsListPayload);
            // console.log("CompletionsSts List" ,res.data.data);
            let formattedCompletionsStsList = res.data.data.map((item:any) => {

        
             //SOP-80 Modified By PCS   
             //const { sys_completion_status_id, sys_completion_status_name } = item;
             const { short_name, sys_completion_status_name } = item;
             //EOF  SOP-80 Modified By PCS 

            return {
                name: sys_completion_status_name,
                //SOP-80 Modified By PCS  
                 //value: sys_completion_status_id,
                 value: short_name,
                 //EOF  SOP-80 Modified By PCS 
               
            };
            });
            setCompletionsStsOptions(formattedCompletionsStsList)
        } catch (err) {
            //console.log( "co",err);
        }
        };
 //Closure Information API

  // SOP-72 Included By PCS[ Closure Information Dropdown API ]
        const GetClosureInformationList = async () => {
            const payload ={
                 //SOP-97 Included By PCS
                 CompanyId: CompanyId,
                 active: "Y",
                 //EOF SOP-97 Included By PCS
            }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_closure_information_list`,payload);
            // console.log("Closure Info List" ,res.data.data);
            let formattedClosureInformationList = res.data.data.map((item:any) => {
            const { sys_ticket_closure_information_id, sys_ticket_closure_information_name } = item;
            return {
                name: sys_ticket_closure_information_name,
                value: sys_ticket_closure_information_id,
            };
            });
            setClosureInformationOptions(formattedClosureInformationList)
        } catch (err) {
            console.log( "co",err);
        }
        };
//EOF SOP-72 Included by PCS

// Asset Dropdown API
    const GetAssetList = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getAssetList`,{
                CompanyId:CompanyId,
            IsActive:"Y"
            });
            // console.log("Asset List" ,res.data.data);
            let formattedAssetList = res.data.data.map((item:any) => {
            const { assetid, assetname } = item;
            return {
                name: assetname,
                value: assetid,
            };
            });
            setAssetOptions(formattedAssetList)
        } catch (err) {
            //console.log( "co",err);
        }
        };

// To get Allocationsd Approval details as per the id 
        const GetAllocatedTicketServiceListById =()=>{

            const pl ={
                CompanyId: CompanyId,
                TicketId: TicketId,
            }
            // console.log('pl',pl) 
            commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_allocated_ticket_service_list_by_id`, {
                CompanyId: CompanyId,
                TicketId: TicketId,
                })
                .then((res: any) => 
                { 
              
                const AllocatedTicketServiceData = res.data.data[0]
                console.log("response ",AllocatedTicketServiceData)
                setServiceRemarksValue(AllocatedTicketServiceData.service_remarks)
                setCompletionsSts(AllocatedTicketServiceData.ticket_completionstatus)
                setVendorTicketIdValue(AllocatedTicketServiceData.vendor_ticket_id)//SOP-70 Included By PCS
                //SOP-80 Included By PCS
                setServiceCompletionstatus(AllocatedTicketServiceData.service_completionstatus)
                //EOF SOP-80 Included By PCS
    //SOP-99 Included By PCS [setting values for new fieldsfrom DB]
                setTicketIdValue(AllocatedTicketServiceData.ticketid)
                setTicketstartDateValue(AllocatedTicketServiceData.ticketopendatetime)
                setRaisedbyEmployee(AllocatedTicketServiceData.ticketraisedbyname)
                setSelectedImpactType(AllocatedTicketServiceData.impact)
                setSelectedPriorityType(AllocatedTicketServiceData.priority)
                setSelectedContactType(AllocatedTicketServiceData.contacttype)
                setSelectedIssueType(AllocatedTicketServiceData.categoryname)
                setSelectedIssueSubType(AllocatedTicketServiceData.subcategoryname)
                setAllocatedEmployee(AllocatedTicketServiceData.allocatedby)
                setShortDiscriptionValue(AllocatedTicketServiceData.ticket_issueshortdescription)
                setDiscriptionValue(AllocatedTicketServiceData.ticket_issuedescription)
                setLocationValue(AllocatedTicketServiceData.locationdetails)   
     //EOF SOP-99 Included By PCS  [setting values for new fieldsfrom DB]

        //SOP-80 Commented by PCS
                // let formattedEmployee = res.data.data.map((item:any) => {
                //     const { service_completionstatus, service_completionstatus_name } = item;
                //     let servicecompletionstatus;
                //     if(service_completionstatus === 'C' ){
                //         servicecompletionstatus = 2
                //     }
                //     else{
                //         servicecompletionstatus = 3
                //     }
                //     return {
                //         name: service_completionstatus_name,
                //         value: servicecompletionstatus,
                //     };
                //     });
                //     setSelectedCompletionsStsId(formattedEmployee[0].value) 
        //EOF SOP-80 Commented by PCS

                    //SOP-72 Inlcuded By PCS [closure information from resposne]
                     let formattedClosureInformation = res.data.data.map((item:any) => {
                        const { closure_information, closure_information_name } = item;
                    return {
                        name: closure_information_name,
                        value: closure_information,
                            };
                            });

                    setselectedClosureInformationId(formattedClosureInformation[0].value) 
                    //EOF SOP-72 Inlcuded By PCS

                    let formattedAsset = res.data.data.map((item:any) => {
                        const { service_relatetowhichassetid, service_relatetowhichassetname } = item;
                        return {
                            name: service_relatetowhichassetname,
                            value: service_relatetowhichassetid,
                        };
                        });
                        setSelectedAssetId(formattedAsset[0].value)         

                }).catch((error) =>  
                { 
                Failed(error.response.message);
                });
        }

    useEffect(() => {
        GetAllocatedTicketServiceListById();
        GetCompletionsStsList();
        GetAssetList();
        GetClosureInformationList();//SOP-72 Included By PCS
        
    }, []); 
 
//SOP-99 Modified by PCS [Existing view page is modified to display more information]

    // return(
    //     <Box>
    //         <Box>
    //         <Box bgcolor={'white'} borderRadius={3}
    //         sx={{
    //             width:{
    //                 lg:600,
    //                 md:600,
    //                 xs:350
    //         }}} >
    //             <Box>
    //             <Box p={3}>
    //                     <Typography variant='h1'
    //                     sx={{
    //                         fontSize:{
    //                             lg:'2rem',
    //                             md:'2rem',
    //                             xs:'1.5rem'
    //                         }
    //                     }}
    //                     >
    //                     Service Details#
    //                     </Typography>
    //                 </Box>
                    
    //                 <form noValidate>
    //                     <Box p={3}>
    //                         <Grid container >
    //                             <Grid item lg={12} md={12} xs={12} >
    //                                 <Autocomplete
    //                                     id="combo-box-demo"
    //                                     options={CompletionsStsOptions as OptionType[]}
    //                                     filterOptions={filterOptions}
    //                                     disabled
    //                                     getOptionLabel={(option: unknown) => (option as OptionType).name}
    //                                     //SOP-80 Modified By PCS
    //                                     // value={CompletionsStsOptions.find((option: OptionType) => option.value === selectedCompletionsStsId) || null}
    //                                     value={CompletionsStsOptions.find((option: OptionType) => option.value === ServiceCompletionstatus) || null}
    //                                     //EOF SOP-80 Modified By PCS
    //                                     onChange={(event, newValue) => {
    //                                         if (newValue) {
    //                                             setSelectedCompletionsStsId(newValue.value);
    //                                             setCompletionsStsError(false);
    //                                             setCompletionsStsErrorMessage("");
    //                                         } else {
    //                                             setSelectedCompletionsStsId('');
    //                                             setCompletionsStsError(true);
    //                                             setCompletionsStsErrorMessage("CompletionsSts is required");
    //                                         }
    //                                     }}
    //                                     // sx={{ width: 235, ml: 1 }}
    //                                     renderInput={(params) => 
    //                                     <TextField {...params}
    //                                     error={CompletionsStsError}
    //                                     autoFocus
    //                                     helperText={CompletionsStsErrorMessage}
    //                                     required label="Completion Status" 
    //                                     variant="standard" />}
    //                                 />
    //                             </Grid>

    //                         {/* //SOP-70 Included By PCS [to inlcude Vendor Ticket ID] */}
    //                             <Grid item lg={12} md={12} xs={12} >
    //                                         <TextField 
    //                                         label='Vendor Ticket ID'
    //                                         variant="standard"
    //                                         name='Vendor Ticket ID'
    //                                         fullWidth
    //                                         disabled
    //                                         error={VendorTicketIdError}
    //                                         onChange={handleVendorTicketIdChange}
    //                                         value={VendorTicketIdValue}
    //                                         helperText={VendorTicketIdErrorMessage}  
    //                                         />
    //                                     </Grid>
    //                         {/*//EOF SOP-70 Included By PCS [to inlcude Vendor Ticket ID] */}

    //                                    <Grid item mt={3} lg={12} md={12} xs={12} >
    //                                         <TextField 
    //                                         id="outlined-basic" 
    //                                         label="Service Remarks" 
    //                                         variant="outlined" 
    //                                         name='ServiceRemarks'
    //                                         fullWidth
    //                                         disabled
    //                                         multiline
    //                                         rows={4}
    //                                         error={ServiceRemarksError}
    //                                         onChange={handleServiceRemarksChange}
    //                                         value={ServiceRemarksValue}
    //                                         helperText={ServiceRemarksErrorMessage}                                           
    //                                         />
    //                                     </Grid>

    //                                 <Grid item lg={12} md={12} xs={12} >
    //                                 <Autocomplete
    //                                     id="combo-box-demo"
    //                                     options={AssetOptions as OptionType[]}
    //                                     filterOptions={filterOptions}
    //                                     disabled
    //                                     getOptionLabel={(option: unknown) => (option as OptionType).name}
    //                                     value={AssetOptions.find((option: OptionType) => option.value === selectedAssetId) || null}
    //                                     onChange={(event, newValue) => {
    //                                         if (newValue) {
    //                                             setSelectedAssetId(newValue.value);
    //                                         } else {
    //                                             setSelectedAssetId(null);
    //                                         }
    //                                     }}
    //                                     renderInput={(params) => 
    //                                     <TextField {...params}
    //                                     label="Asset" 
    //                                     variant="standard" />}
    //                                 />
    //                             </Grid>

    //                             {/* //SOP-72 Included By PCS [to include closure information]*/}
    //                             <Grid item mt={3} lg={12} md={12} xs={12} >
    //                                 <Autocomplete
    //                                     id="combo-box-demo"
    //                                     options={ClosureInformationOptions as OptionType[]}
    //                                     filterOptions={filterOptions}
    //                                     disabled
    //                                     getOptionLabel={(option: unknown) => (option as OptionType).name}
    //                                     value={ClosureInformationOptions.find((option: OptionType) => option.value === selectedClosureInformationId) || null}
    //                                     onChange={(event, newValue) => {
    //                                         if (newValue) {
    //                                             setselectedClosureInformationId(newValue.value);
    //                                             setClosureInformationError(false);
    //                                             setClosureInformationErrorMessage("");
    //                                         } else {
    //                                             setselectedClosureInformationId('');
    //                                             setClosureInformationError(true);
    //                                             setClosureInformationErrorMessage("Closure information is required");
    //                                         }
    //                                     }}
                                        
    //                                     renderInput={(params) => 
    //                                     <TextField {...params}
    //                                     error={ClosureInformationError}
    //                                     autoFocus
    //                                     helperText={ClosureInformationErrorMessage}
    //                                     required label="Closure Information " 
    //                                     variant="standard" />}
    //                                 />
    //                                     </Grid>
    //                        {/* //EOF SOP-72 Included By PCS */}


    //                         </Grid>
    //                     </Box>

    //                 <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
    //                             <Button
    //                                 sx={{
    //                                     background: 'white',
    //                                     border: 'none',
    //                                     borderRadius: '5px',
    //                                     padding:{
    //                                         lg: "0 70px",
    //                                         md: "0 70",
    //                                         xs:'0 50px'
                
    //                                     },
    //                                     // color: {accent},
    //                                     height: "3rem",
    //                                     boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    //                                 }}

    //                                 onClick={handleClose} 
    //                             >
    //                                 Back
    //                             </Button>

    //                             <Button
    //                                 sx={{
    //                                     background: '#25425F',
    //                                     border: 'none',
    //                                     borderRadius: '5px',
    //                                     padding:{
    //                                         lg: "0 70px",
    //                                         md: "0 70",
    //                                         xs:'0 50px'
                
    //                                     },
    //                                     "&:hover":{
    //                                         backgroundColor:'#25425F',
    //                                         color:'white',
    //                                     },
    //                                     color: 'white',
    //                                     height: "3rem",
    //                                     boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    //                                 }}
    //                                 onClick={(event)=>{
    //                                     EditServicesOpen(event);
    //                                     handleClose(event);
    //                                 }}
    //                             >
    //                                 Edit
    //                             </Button>
    //                             </Box>
    //                 </form>


    //             </Box>
    //         </Box>
    //         </Box>
        
    //     </Box>
    // )

return(
<Box>
<Box bgcolor={'white'} borderRadius={3} sx={{}} >
    <Box>
    <Box p={3}>
    <Typography variant='h1'
    sx={{
        fontSize:{
            lg:'2rem',
            md:'2rem',
            xs:'1.5rem'
        }
    }}
    >
    Service Details
    </Typography>
    </Box>
    <form noValidate>
    <Box p={3}>
    <Grid spacing={3.5} container display={'flex'} >
    {/* //NEW CONTENT */}
    {/* First Half */}
        <Grid item  lg={8} md={8} xs={12}>
        {/* First Line */}
        <Grid container spacing={3.5} display={'flex'} >
                    <Grid item lg={4} md={12} xs={12}>
                    <TextField
                        type='text'
                        variant="standard"
                        label='Number'
                        name='Number'
                        fullWidth
                        disabled
                        value={TicketIdValue}
                    /> 
                    </Grid>
                    <Grid item lg={4} md={12} xs={12} >
                            <FormControl fullWidth>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Ticket Date"
                                            value={TicketstartDateValue ? dayjs(TicketstartDateValue) : null}
                                            format="DD-MM-YYYY"
                                            disabled
                                            slotProps={{
                                                textField: {
                                                }
                                            }}
                                        />                                                        
                                    
                                </LocalizationProvider>
                            </FormControl> 
                    </Grid>
                    <Grid item lg={4} md={12} xs={12}>
                            <TextField
                            type='text'
                            variant="standard"
                            label='Raised By'
                            name='Employee'
                            fullWidth
                            disabled
                            value={RaisedbyEmployee }
                            /> 
                    </Grid>
        </Grid>
        {/* Second  Line */}
        <Grid container mt={0.1} spacing={3.5} display={'flex'} >
                        <Grid item lg={4} md={12} xs={12}>
                        <TextField
                            type='text'
                            variant="standard"
                            label='Allcoated by'
                            name='Allcoated by'
                            fullWidth
                            disabled
                            value={AllocatedEmployee}
                        /> 
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} >
                        <TextField
                            type='text'
                            variant="standard"
                            label='Impact'
                            name='Impact'
                            fullWidth
                            disabled
                            value={selectedImpactType}
                        /> 
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} >
                        <TextField
                            type='text'
                            variant="standard"
                            label='Priority'
                            name='Priority'
                            fullWidth
                            disabled
                            value={selectedPriorityType}
                        /> 
                        </Grid>
        </Grid>
        {/*Third Line */}
        <Grid container mt={0.1} spacing={3.5} display={'flex'} >
                        <Grid item lg={4} md={12} xs={12} >
                            <TextField
                            type='text'
                            variant="standard"
                            label='Contact Type'
                            name='Contacttype'
                            fullWidth
                            disabled
                            value={selectedContactType}
                            /> 
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} >
                        <TextField
                            type='text'
                            variant="standard"
                            label='Category'
                            name='Category'
                            fullWidth
                            disabled
                            value={selectedIssueType }
                            /> 
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} >                                           
                        <TextField
                            type='text'
                            variant="standard"
                            label='Sub Category'
                            name='SubCategory'
                            fullWidth
                            disabled
                            value={selectedIssueSubType}
                            /> 
                        </Grid>
        </Grid>
        {/*Fourth Line */}
            <Grid container  mt={0.1} spacing={3.5} display={'flex'} >
                            
                            <Grid item lg={12} md={8} xs={12} >
                            <TextField 
                                id="outlined-basic" 
                                label="Short Description" 
                                variant="outlined" 
                                name='ShortDescription'
                                required
                                fullWidth
                                multiline
                                rows={3}
                                value={ShortDiscriptionValue}
                                disabled
                                />
                            </Grid>
            </Grid>
        {/*Fifth  Line */}
            <Grid container mt={0.1} spacing={3.5} display={'flex'} >
                <Grid item  lg={12} md={12} xs={12} >
                    <TextField 
                        id="outlined-basic" 
                        label="Description" 
                        variant="outlined" 
                        name='Description'
                        fullWidth
                        multiline
                        rows={10}
                        value={DiscriptionValue}
                        disabled
                    />
                </Grid>
            </Grid>
        {/*Sixth  Line */}
        <Grid container spacing={3.5} display={'flex'} >
            <Grid item mt={0.1} lg={12} md={12} xs={12} >
                    <TextField 
                        label="Location" 
                        variant="standard" 
                        name='Issuediscription'
                        fullWidth
                        value={LocationValue}
                        disabled
                    />
            </Grid>
        </Grid>
</Grid>
{/* //First Half ends */}
{/* Second Half starts */}
    <Grid item lg={4} md={4} xs={12}>
                {/* //OLD CONTENT   */}
                <Grid item lg={12} md={12} xs={12} >
                    <Autocomplete
                        id="combo-box-demo"
                        options={CompletionsStsOptions as OptionType[]}
                        filterOptions={filterOptions}
                        disabled
                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                        //SOP-80 Modified By PCS
                        // value={CompletionsStsOptions.find((option: OptionType) => option.value === selectedCompletionsStsId) || null}
                        value={CompletionsStsOptions.find((option: OptionType) => option.value === ServiceCompletionstatus) || null}
                        //EOF SOP-80 Modified By PCS
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSelectedCompletionsStsId(newValue.value);
                                setCompletionsStsError(false);
                                setCompletionsStsErrorMessage("");
                            } else {
                                setSelectedCompletionsStsId('');
                                setCompletionsStsError(true);
                                setCompletionsStsErrorMessage("CompletionsSts is required");
                            }
                        }}
                        // sx={{ width: 235, ml: 1 }}
                        renderInput={(params) => 
                        <TextField {...params}
                        error={CompletionsStsError}
                        autoFocus
                        helperText={CompletionsStsErrorMessage}
                        required label="Completion Status" 
                        variant="standard" />}
                    />
                </Grid>
                <Grid mt={1} item lg={12} md={12} xs={12} >
                    <TextField 
                    label='Vendor Ticket ID'
                    variant="standard"
                    name='Vendor Ticket ID'
                    fullWidth
                    disabled
                    error={VendorTicketIdError}
                    onChange={handleVendorTicketIdChange}
                    value={VendorTicketIdValue}
                    helperText={VendorTicketIdErrorMessage}  
                    />
                </Grid>
                <Grid item mt={3} lg={12} md={12} xs={12} >
                    <TextField 
                    id="outlined-basic" 
                    label="Service Remarks" 
                    variant="outlined" 
                    name='ServiceRemarks'
                    fullWidth
                    disabled
                    multiline
                    rows={4}
                    error={ServiceRemarksError}
                    onChange={handleServiceRemarksChange}
                    value={ServiceRemarksValue}
                    helperText={ServiceRemarksErrorMessage}                                           
                    />
                </Grid>
                <Grid item mt={3}  lg={12} md={12} xs={12} >
                <Autocomplete
                    id="combo-box-demo"
                    options={AssetOptions as OptionType[]}
                    filterOptions={filterOptions}
                    disabled
                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                    value={AssetOptions.find((option: OptionType) => option.value === selectedAssetId) || null}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setSelectedAssetId(newValue.value);
                        } else {
                            setSelectedAssetId(null);
                        }
                    }}
                    renderInput={(params) => 
                    <TextField {...params}
                    label="Asset" 
                    variant="standard" />}
                />
                </Grid>
                <Grid item mt={3} lg={12} md={12} xs={12} >
                <Autocomplete
                id="combo-box-demo"
                options={ClosureInformationOptions as OptionType[]}
                filterOptions={filterOptions}
                disabled
                getOptionLabel={(option: unknown) => (option as OptionType).name}
                value={ClosureInformationOptions.find((option: OptionType) => option.value === selectedClosureInformationId) || null}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setselectedClosureInformationId(newValue.value);
                        setClosureInformationError(false);
                        setClosureInformationErrorMessage("");
                    } else {
                        setselectedClosureInformationId('');
                        setClosureInformationError(true);
                        setClosureInformationErrorMessage("Closure information is required");
                    }
                    }}
                    
                    renderInput={(params) => 
                    <TextField {...params}
                    error={ClosureInformationError}
                    autoFocus
                    helperText={ClosureInformationErrorMessage}
                    required label="Closure Information " 
                    variant="standard" />}
                />
                </Grid>
                {/* //OLD CONTENT   */}
    </Grid>
{/* //Second Half ends */}
</Grid>
</Box>
<Box mt={1} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
<Button
    sx={{
        background: 'white',
        border: 'none',
        borderRadius: '5px',
        padding:{
            lg: "0 70px",
            md: "0 70",
            xs:'0 50px' },
        height: "3rem",
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    }}
onClick={handleClose} 
>
Back
</Button>
{/* //SOP-99 Commented by PCS [Closed tickets cant be modified]*/}
{/* <Button
sx={{
    background: '#25425F',
    border: 'none',
    borderRadius: '5px',
    padding:{
        lg: "0 70px",
        md: "0 70",
        xs:'0 50px' },
    "&:hover":{
        backgroundColor:'#25425F',
        color:'white',
    },
    color: 'white',
    height: "3rem",
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
}}
onClick={(event)=>{
    EditServicesOpen(event);
    handleClose(event);
}}
>
Edit
</Button> */}
{/* //EOF SOP-99 Commented by PCS */}
</Box>

</form>
    </Box>
</Box>
</Box> )
//EOF SOP-99 Modified by PCS [Existing view is modified]
}

export default ViewTicketingServicePage;

// End of SOP-37