//SOP-101 Included By PCS[My Tickets Chart]

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import {Grid,Divider} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useEffect } from "react";
import { commonService } from "../../atic-common-helpers/helpers/common.service";

export default function MyTicketsChart() {
 
  const [itemNb, setItemNb] = React.useState(4);
  const [skipAnimation, setSkipAnimation] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const radius = isSmallScreen ? 20 : isMediumScreen ? 30 :50; 
  const chartSize = isSmallScreen ? 220 : isMediumScreen ? 240 : 260; // Responsive pie chart size

//Global data
 const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
 const GlobalData = JSON.parse(GlobalDataFromSession);

//My Tickets
const [NewTickets, setNewTickets] = useState<number>(0); 
const [PendingTickets, setPendingTickets] = useState<number>(0); 
const [CompletedTickets, setCompletedTickets] = useState<number>(0); 

const MyTicketsData = async () => 
  {
      try {      
            //Payload
            const TicketPayload = {
              CompanyId: GlobalData.GlobalCompanyId,
              EmployeeId:GlobalData.GlobalEmployeeId,
              UserId:GlobalData.GlobalUserId
            };

        //API    
        const response = await commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/homepage_tickets_chart`, TicketPayload);
          // console.log("response ticket count",response.data.data[0])
          setNewTickets(response.data.data[0].new_requests); 
          setPendingTickets(response.data.data[0].pending_requests);
          setCompletedTickets(response.data.data[0].completed_requests);
    } 
      catch (error) {
        console.error("Error fetching data:", error);
      }
  };

 const COLORS = ['#4FC0FF', '#C6CE24', '#230E89'];
 //Tickets Values
  const MyTicketsValues = [
                { label: 'New Ticket Raise', value: NewTickets },
                { label: 'Inprogress Tickets', value: PendingTickets },
                { label: 'Completed Tickets', value: CompletedTickets },
              ];

const allTicketsCount = NewTickets+PendingTickets+CompletedTickets;

// Apply colors to each pie slice
  const dataWithColors = MyTicketsValues.slice(0, itemNb).map((item, index) => ({
                ...item,
                color: COLORS[index % COLORS.length],  
              }));

const valueFormatter = (value:any) => `${value.value}`;
// --------------MyAssets------------

 //My Assets from DB
 const [TotalAssets, setTotalAssets] = useState<number>(0); //asset_count
   
 const MyAssetsData = async () => 
   {
       try {      
             //Payload
             const AssetPayload = {
               CompanyId: GlobalData.GlobalCompanyId,
               EmployeeId:GlobalData.GlobalEmployeeId,
               UserId:GlobalData.GlobalUserId
             };
 
         //API    
         const response = await commonService
           .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/homepage_myasset_count_chart`, AssetPayload);
          //  console.log("response asset count",response.data.data[0])
           setTotalAssets(response.data.data[0].asset_count); // Set the total asset count
         } 
       catch (error) {
         console.error("Error fetching data:", error);
                     }
   };
 
const COLORSMyAssets = ['#7fff00'];

//Replace the values with My Assets grid vlues 
  const MyAssetValues = [
                { label: 'Asset count', value: TotalAssets },
                
              ];

// Apply colors to each pie slice
  const dataWithColorsMyAssetRequests = MyAssetValues.slice(0, itemNb).map((item, index) => ({
                ...item,
                color: COLORSMyAssets[index % COLORSMyAssets.length],  
              }));
             
useEffect(() => 
  {
    MyAssetsData();
    MyTicketsData();
  }, []);


return (
  <>
<Box sx={{ width: '100%'}}>
<Grid  container display={'flex'}> 
{/* Pie Chart */}
 <Grid item lg={4} md={12} xs={12} sm={12}>
 <Box sx={{ position: 'relative', display: 'inline-block' }}>
<PieChart 
width={chartSize}
height={chartSize}
slotProps={{
  legend: {
    hidden: true,
  },
}}
sx={{
  "& text": {
    fontWeight: 'normal',
  },
 
}}
series={[
  {
    data: dataWithColors, // Use the data with colors
    innerRadius: radius,
    arcLabelMinAngle: 10,
    valueFormatter,
  },
]}
/>
<Box
  sx={{
    position: 'absolute',
    top: '50%',
    left: '32%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  }}
>
  <Typography
    variant="h6"
    sx={{
      fontWeight: 'bold',
      color: '#3F424D',
      '@media (max-width: 600px)': {fontSize:'10px'}, 
      '@media (max-width: 768px)': {fontSize:'11px'}, 
      '@media (max-width: 1024px)': {fontSize:'12px'}, 
      '@media (min-width: 1025px) and (max-width: 1280px)': {fontSize:'13px'}, 
      '@media (min-width: 1281px) and (max-width: 1600px)': {fontSize:'14px'},  
      '@media (min-width: 1601px) and (max-width: 1920px)':{fontSize:'15px'},  
      '@media (min-width: 1921px)': {fontSize:'16px'}, 
    }}
  >
    {allTicketsCount}
  </Typography>
  <Typography sx={{ width:'90%', wordWrap: 'break-word' ,
        '@media (max-width: 600px)': {fontSize:'6px'}, 
        '@media (max-width: 768px)': {fontSize:'6px'}, 
        '@media (max-width: 1024px)': {fontSize:'6px'}, 
        '@media (min-width: 1025px) and (max-width: 1280px)': {fontSize:'13px'}, 
        '@media (min-width: 1281px) and (max-width: 1600px)': {fontSize:'14px'},  
        '@media (min-width: 1601px) and (max-width: 1920px)':{fontSize:'15px'},  
        '@media (min-width: 1921px)': {fontSize:'16px'}, 
  }}>Total Tickets</Typography>
</Box>
</Box>
 </Grid> 
 {/* //Ticket data */}
<Grid item 
          sx={{        
        '@media (max-width: 600px)': { marginTop:'-8%'}, 
        '@media (max-width: 768px)': {  marginTop:'-8%'}, 
        '@media (max-width: 1024px)': {  marginTop:'-9%' }, 
        '@media (min-width: 1025px) and (max-width: 1280px)': { marginTop:'12%'}, 
        '@media (min-width: 1281px) and (max-width: 1600px)': { marginTop:'10%'}, 
        '@media (min-width: 1601px) and (max-width: 1920px)': { marginTop:'9%' }, 
        '@media (min-width: 1921px)': { marginTop:'5%'},
          }}
          lg={8} sm={12} md={12} xs={12} > 
        <Grid lg={12} sm={12} md={12} xs={12} >
         {/* Custom Legend */}
          {dataWithColors.map((item, index) => (
              <Box key={index} sx={{ display:'flex',alignItems:'center',marginTop:'1%'}}> 
                 <Box
                   sx={{
                    backgroundColor: item.color,
                    borderRadius: '2px',
                    marginTop:'1.5%',
                    marginLeft:'10%',
                    '@media (max-width: 600px)': { width: '10px',height:'10px' }, // Small devices like phones
                    '@media (max-width: 768px)': { width: '10px',height:'10px' }, // Tablets and smaller laptops
                    '@media (max-width: 1024px)': { width: '10px',height:'10px'  }, // Standard laptops
                    '@media (min-width: 1025px) and (max-width: 1280px)': { width: '10px',height:'10px' }, // Larger laptops (1280px or less)
                    '@media (min-width: 1281px) and (max-width: 1600px)': { width: '12px',height:'12px' }, // Standard desktop monitors
                    '@media (min-width: 1601px) and (max-width: 1920px)': { width: '14px',height:'14px'  }, // Large desktops and some monitors
                    '@media (min-width: 1921px)': { width: '16px',height:'16px' }, // Ultra-wide and large 4K monitors
                   
                  }}
                />
                <Typography variant="body2" sx={{
                   marginLeft:'2.5%', 
                   marginTop:'1%',
                   color: '#3F424D',
                  // Media Queries
    '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
    '@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
    '@media (max-width: 1024px)': { fontSize: '14px' }, // Standard laptops
    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '16px' }, // Larger laptops (1280px or less)
    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '16px' }, // Standard desktop monitors
    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '19px' }, // Large desktops and some monitors
    '@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
                                 
                   }}>
                 {item.value} - {item.label}
                </Typography>
              </Box>
            ))}
         </Grid> 
        
{/* //Asset data */}
</Grid> 
</Grid> 
</Box>

<Grid  container > 
<Grid item mb={2} lg={4} md={12} xs={12} sm={12}>
 {/* Custom Legend */}
  {dataWithColorsMyAssetRequests.map((item, index) => (
    <Box key={index} sx={{ display: 'flex'}}> 
    <Box
      sx={{
        backgroundColor: item.color,
        borderRadius: '2px',
         marginTop:'1.5%',
        marginLeft:'10%',
        '@media (max-width: 600px)': { width: '10px',height:'10px' }, // Small devices like phones
        '@media (max-width: 768px)': { width: '10px',height:'10px' }, // Tablets and smaller laptops
        '@media (max-width: 1024px)': { width: '10px',height:'10px'  }, // Standard laptops
        '@media (min-width: 1025px) and (max-width: 1280px)': { width: '10px',height:'10px' }, // Larger laptops (1280px or less)
        '@media (min-width: 1281px) and (max-width: 1600px)': { width: '12px',height:'12px' }, // Standard desktop monitors
        '@media (min-width: 1601px) and (max-width: 1920px)': { width: '14px',height:'14px'  }, // Large desktops and some monitors
        '@media (min-width: 1921px)': { width: '16px',height:'16px' }, // Ultra-wide and large 4K monitors
        
      }}
    />
    <Typography variant="body2" 
      sx={{
        marginLeft:'2.5%', 
        // marginTop:'1%',
        color: '#3F424D',
          // Media Queries
    '@media (max-width: 600px)': { fontSize: '14px' }, // Small devices like phones
    '@media (max-width: 768px)': { fontSize: '14px' }, // Tablets and smaller laptops
    '@media (max-width: 1024px)': { fontSize: '14px' }, // Standard laptops
    '@media (min-width: 1025px) and (max-width: 1280px)': { fontSize: '16px' }, // Larger laptops (1280px or less)
    '@media (min-width: 1281px) and (max-width: 1600px)': { fontSize: '16px' }, // Standard desktop monitors
    '@media (min-width: 1601px) and (max-width: 1920px)': { fontSize: '19px' }, // Large desktops and some monitors
    '@media (min-width: 1921px)': { fontSize: '24px' }, // Ultra-wide and large 4K monitors
                }}
 >
   {item.value} - {item.label}
  </Typography>
   </Box>
   ))}
</Grid>  
</Grid>
</>);}
//EOF SOP-101 Included By PCS

